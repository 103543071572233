import * as types from "../../Constants/Connection/index";
import axios from "axios";

// get user following requests
const userFollowingRequestsGet = (userFollowingRequests) => ({
  type: types.GET_FOLLOWING_REQUESTS,
  payload: userFollowingRequests,
});

// get user following requests
export const getUserFollowingRequests = () => {
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user?.token) {
      const { type, pageIndex, pageSize, sortBy = "ascending" } = {}; // Replace with your actual values

      axios
        .post(
          `${process.env.REACT_APP_IPURL}/hamburgermenu/getUserRequestReceived`,
          { type, pageIndex, pageSize, sortBy },
          { headers: { Authorization: `Bearer ${user?.token}` } }
        )
        .then((res) => {
          dispatch(userFollowingRequestsGet(res.data.data.successResult));
          //console.log("find request============>", res.data.data.successResult);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
