import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from 'react-bootstrap'

const DeleteModal = (props) => {
    return (
        <>
            {
                props.openModal !== false &&
                <Modal isOpen={props.openModal} toggle={props.openDeleteModal} className='modal-dialog-centered'>
                    <ModalHeader onClick={props.openDeleteModal} color='light-denger' className='d-flex  justify-content-center Media_header' >
                        <h3>Delete Category</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to delete category</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0 justify-content-center '>
                        <Button variant='outline-secondary' className='w-25' onClick={props.openDeleteModal}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant='danger' className='w-25' onClick={props.openDeleteModal}>
                            <span className='ml-1'>Delete</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default DeleteModal