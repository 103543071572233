import React, { useEffect, useState } from 'react'
import { Copy, CornerUpLeft, Edit3, Flag, Grid, List, MinusCircle, MoreVertical, Plus, PlusCircle, Search, Share, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction'
import {getCreatetConnectionListAction} from '../../../Services/Actions/Connection/getCreatedCategoryAction'
import Header from '../../Header' 
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import ConnectionPlaceMenu from './ConnectionPlaceMenu'
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Button, InputGroup } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { CategoriesList } from '../ListView/CategoriesList'
import { MutualFriendList } from '../HoverComponents/MutualFriendList'
import { BlockUserModal } from '../HoverComponents/BlockUserModal'
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import { MemberList } from '../ListView/MemberList'
import AddSocioMateModal from '../ConnectionModal/AddSocioMateModal'
import ConnectionEditModal from '../ConnectionModal/ConnectionEditModal'
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal'
import AddCategoryModal from '../ConnectionModal/AddCategoryModal'
import RemoveModal from '../ConnectionModal/RemoveModal'
import DeleteModal from '../ConnectionModal/DeleteModal'
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal'
import EditNewCategoryModal from './EditNewCategoryModal'
import PublicAccount from '../ConnectionModal/PublicAccount'
import CommanHeader from '../CommanHeader'

const ConnectionMenuScreen = () => {
    const [Gridview, Setgridview] = useState(false)
    const [member, setMember] = useState(false)
    // GET FOLLOWING DATA
    const { getUserConnection } = useSelector(state => state.GetUserConnectionData)

    // connectionList data
    const { connectionList} = useSelector(state =>state.connectionListData)
    const dispatch = useDispatch();

    //search
    const [searchItem, setSearchItem] = useState("");

    const filteredData = connectionList?.filter((item) =>
      item.name.toLowerCase().includes(searchItem.toLowerCase())
    );

    // ----------- Edit Categories state ----------- 
    const [editCategories, setEditCategories] = useState(false)
    const toggleEditCategories = () => {
        setEditCategories((preState) => !preState)
    }

    //  ----------- Add Users Option -----------
    const [addUserOptions, setAddUserOptions] = useState(false)
    const toggleAddUserOptions = () => {
        setAddUserOptions((preState) => !preState)
    }
    // -----------= share Modal State -----------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // ----------- Remove Followers Modal state -----------
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
        setRemoveModalOpen((prevState) => !prevState)
    }
    // ----------- Delete Categoris Modal state  -----------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }

    //  ----------- Start Add category State -----------
    const [addRelationShipOptions, setAddRelationShipOptions] = useState(false)
    const toggleAddCategory = () => {
        setAddRelationShipOptions((preState) => !preState)
    }

    //  ----------- Start ReportedOption -----------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // mouse on hover components state
    //const [mouseHover, setMouseHover] = useState(null)

    //console.log("connectionList===============>",createConnectionList)

    useEffect(() => {
        dispatch(loadAllUserConnection())
    }, [])
    // useEffect(() => {
    //     dispatch(getCreatetConnectionListAction())
    // }, [dispatch])
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Card>
                        {/* comman header calling */}
                        <CommanHeader />
                        <CardBody>
                            {/* connection tabs calling */}
                            <ConnectionPlaceMenu />
                            {member ?
                                // {/*  ----------- Members Sections ----------- */}
                                <div className="mppage-heading mt-4">
                                    <div className="group-custom-block">
                                        <div className='nav-flex-container mb-1'>
                                            <div onClick={() => setMember(!member)} className='d-flex'>
                                                <Button className='archive-button' color='success' variant="outline-primary">
                                                    <CornerUpLeft size={17} /> Back
                                                </Button>
                                            </div>
                                            <div className='sub-nav-flex size-sm-mt-4'>
                                                <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                                                    <Search color='#666666' size={16} />
                                                    <Input type='search' placeholder="  Find..." className='coonection-input-style'  />
                                                </InputGroup>
                                                <div className='list-grid-toggle size-sm-mt-4'>
                                                    <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                                        <Grid size={16} />
                                                    </Button>
                                                    <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                                        <List size={16} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        {Gridview ?
                                            <MemberList />
                                            :
                                            <Row className='connections_PeopleThisGrid'>
                                                <Col lg='4' className='w-100'>
                                                    <div className="connection-card connection-card-new cus-cur-pointer d-flex box-shadow-style justify-content-center p-4">
                                                        <div class="media media-new d-sm-flex py-2">
                                                            <div onClick={toggleAddUserOptions}>
                                                                <span className='green-text text-center'>
                                                                    <Plus size={20} /> Add SocioMate
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    filteredData && filteredData.map((request, index) => {
                                                        return <Col lg='4' className='w-100' key={request.id}>
                                                            <div key={index} className="connection-card connection-card-new content-mp-block d-flex box-shadow-style justify-content-between">
                                                                <div className="media-body d-md-block">
                                                                    <div class="media media-new d-flex align-items-start">
                                                                        <div class="user-img d-flex align-items-start popover___wrapper " >
                                                                            <img src={request.profileImage || `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`}
                                                                                className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                                                            <span class="available-stats online"></span>
                                                                            <PublicAccount request={request}/>
                                                                        </div>
                                                                        <div class="media-body d-md-block pl-1">
                                                                            <h4 className='m-0 name-style d-inline-block text-truncate'>{request.fullName || 'name'}</h4>
                                                                            <div className='d-flex'>
                                                                                <p className='user-name-style'>@{request.userName || 'userName'}  |  </p>
                                                                                <img src={india_svg} className='ml-1' alt='...' />
                                                                            </div>
                                                                            <p className='professional-style'>Professional Guitarist1</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                                            <MoreVertical size={22} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='Connection-dropdown'>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleRemoveModalOpen} ><MinusCircle size={15} className='mr-2' />Remove SocioMate</DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddCategory}>
                                                                                <PlusCircle size={15} className='mr-2' />Add To New Category
                                                                            </DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                                            <div className='border-bottom py-2 menu-hover-style'>
                                                                                <BlockUserModal
                                                                                    btnName='Block User' />
                                                                            </div>
                                                                            <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                                                <Flag size={15} className='mr-2' />Report User
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                                {
                                                    getUserConnection.length <= 0 && <h3 className='text-center connection-sub-heading'>No connection Found</h3>
                                                }
                                            </Row>
                                        }
                                    </div>
                                </div> :
                                // {/*  ----------- Categories Sections ----------- */}
                                <div className="mppage-heading mt-4" >
                                    <div className="group-custom-block">
                                        <div className='nav-flex-container'>
                                            <h3 className='connection-sub-heading'>Categories ({getUserConnection.rows?.length})</h3>
                                            <div className='sub-nav-flex size-sm-mt-4'>
                                                <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                                                    <Search color='#666666' size={16} />
                                                    <Input type='search' placeholder="  Find..." className='coonection-input-style' />
                                                </InputGroup>
                                                <div className='list-grid-toggle size-xs-mt-4 rem-mob-view'>
                                                    <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                                        <Grid size={16} />
                                                    </Button>
                                                    <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                                        <List size={16} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        {Gridview ?
                                            <CategoriesList />
                                            :
                                            <Row className='connections_PeopleThisGrid mt-3'>
                                                <Col lg='4' className='w-100'>
                                                    <div onClick={toggleEditCategories} className="connection-card cus-cur-pointer py-4 content-mp-block box-shadow-style d-flex align-items-center justify-content-center" >
                                                        <div class="media media-new d-sm-flex">
                                                            <div class="pl-1 d-flex align-items-center justify-content-center" >
                                                                <span className='text-center green-text'>
                                                                    <Plus size={20} /> Create Category
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    getUserConnection && getUserConnection.rows?.map((request) => {
                                                        return <Col lg='4' className='w-100' key={request.id} role='button' >
                                                            <div className="connection-card  content-mp-block d-flex align-items-center justify-content-between box-shadow-style">
                                                                <div class="media media-new d-sm-flex">
                                                                    <div class="media-body pl-1" >
                                                                        <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '150px' }} onClick={() => {setMember(!member);  dispatch(getCreatetConnectionListAction())}}>{request.name}</h4>
                                                                        <MutualFriendList />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-end flex-column justify-content-end'>
                                                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                                            <MoreVertical size={22} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='Connection-dropdown'>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddUserOptions}>
                                                                                <PlusCircle size={15} className='mr-2 icon-color-style' />Add SocioMates
                                                                            </DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleEditCategories}>
                                                                                <Edit3 size={15} className='mr-2 icon-color-style' />Edit Category
                                                                            </DropdownItem>
                                                                            <DropdownItem className='py-2 menu-hover-style' onClick={toggleDeleteModalOpen}>
                                                                                <Trash2 size={15} className='mr-2 icon-color-style' />Delete Category
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                                {
                                                    getUserConnection.length <= 0 && <h3 className='text-center connection-sub-heading'>No connection Found</h3>
                                                }
                                            </Row>
                                        }
                                    </div>
                                </div>
                            }
                        </CardBody>
                        <div className='d-flex justify-content-center my-3'>
                            <Stack spacing={2}>
                                <Pagination
                                    count={5}
                                    color='primary'
                                    size="large" />
                            </Stack>
                        </div>
                    </Card>
                </div>
                <RightSidebar />
            </div>
            {/* ----------- Add SocioMate Modal ----------- */}

            <AddSocioMateModal
                openModal={addUserOptions}
                addUserModal={toggleAddUserOptions}
            />

            {/* ----------- Edit Categories Modal ----------- */}

            <ConnectionEditModal
                openModal={editCategories}
                editModal={toggleEditCategories}
            />
            {/* ----------- Add Report Option ----------- */}

            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />

            {/* ----------- Add CategoryModal Status ----------- */}

            <AddCategoryModal
                openModal={addRelationShipOptions}
                cateModalOpen={toggleAddCategory}
            />

            {/* ----------- Remove SocioMate Modal ----------- */}

            <RemoveModal
                openModal={removeModalOpen}
                removeModalOpen={toggleRemoveModalOpen}
            />

            {/* ----------- Delete Categories Modal ----------- */}

            <DeleteModal
                openModal={deleteModalOpen}
                openDeleteModal={toggleDeleteModalOpen}
            />
            {/*  ----------- Start Share Modal -----------*/}

            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
            {/*  ----------- Start Edit New category Modal -----------*/}

            <EditNewCategoryModal
                OpenModal={editCategories}
                openEditModal={toggleEditCategories}
            />
        </>
    )
}

export default ConnectionMenuScreen