import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { ChevronRight, XCircle } from 'react-feather'
import { Col, Row, TabContent, TabPane } from 'reactstrap'
import { ActivityData, CelebratingData } from '../DummyArray'
import axios from 'axios'
import { loadAllFeelingCategory } from '../../../Services/Actions/Common/getFeelingCategoryAction';
import { useDispatch, useSelector } from 'react-redux';

const Activity = ({postType,val,toggle,activeTab,onSelectFeelingCategoryId, onSelectFeelingId ,setfeelingSubChildCategoryId, setCachedDataActivity,cachedDataActivity,setShowSelectedFeeling }) => {
    const [activeData,setActiveData]=useState([])
    const [activityData2,setActivityData2]=useState([])
    const [activityId,setActivityId]=useState("")
    const [currentActiveTab, setCurrentActiveTab] = useState(0)
   
    //  console.log("inside activity page")
    useEffect(()=>{
        const fetchFeelingCategories = async () => {
            // console.log("inside fecthFeelingcategory")
        try {
          const body = {};
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/admin/getFeelingCategories`,
            body,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
              },
            }
          );
            // console.log("response in activity  ",response.data.data.successResult.rows)
            const categoryId=response.data.data.successResult.rows[0].id;
            onSelectFeelingCategoryId(categoryId)

            if(!cachedDataActivity[categoryId]){
            const body2={
                categoryId:categoryId,
                searchKey:"",
                pageIndex:"",
                pageSize:""
            }
            const response2=await axios.post(`${process.env.REACT_APP_IPURL}/admin/getFeelings`,body2,{
                    headers: {
                      Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                    },
            })
            // console.log("response2  ",response2.data.data.successResult.rows);
            setCachedDataActivity((prevData) => ({ ...prevData, [categoryId]: response2.data.data.successResult.rows }));
            setActiveData(response2.data.data.successResult.rows);
            // console.log("activedata  ",activeData)
          }else{
            setActiveData(cachedDataActivity[categoryId]);
          }
        //   setFeelingCategories(response.data.data.successResult.rows);
        } catch (error) {
          console.error('Error fetching feeling categories:', error);
        }
       }
       fetchFeelingCategories()
    })
    useEffect(()=>{

    },[activityData2])

    const toggles =async( id )=> {
     
      if (currentActiveTab !== id){ setCurrentActiveTab(id)
      }   
  }
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const body = {
          searchKey: '',
          pageIndex: '',
          pageSize: '',
          ActivityId: currentActiveTab,
        };
        
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/admin/getActivityChildById`,
          body,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('sociomeeUser'))?.token
              }`,
            },
          }
        );
        
        const datarecorded = response.data.data.successResult?.rows;
        
        setActivityData2(datarecorded);
        
        setActiveTabs(true)
        
        // console.log("setActivityData2 ",activityData2)
        // console.log('Data recorded in fetchData:', datarecorded);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentActiveTab])


    const handleActivity=(id)=>{



        // console.log(postType,val)
        setShowSelectedFeeling(false)
        
        setfeelingSubChildCategoryId(id)
        onSelectFeelingId(currentActiveTab)


        if(val===0){
            postType="text";
          }else if(val===7.6){
            postType="recomendation";
          }else if(val===0.1){
            postType="media";
          }else if(val===7.1){
            postType="thought";
          }else if(val===7.3){
            postType="event"
          }
          
          if(postType==="recomendation"){
            toggle(`/modal/${7.6}`);
          }else if(postType==="thought"){
            toggle(`/modal/${7.1}`);
          }else if(postType==="text"){
            toggle(`/modal/${0}`);
          }else if(postType==='media'){
            toggle(`/modal/${0.1}`);
          }else if(postType==="text"){
            toggle(`/modal/${0}`);
          }else if(postType==="event"){
            toggle(`/modal/${7.3}`);
          }
    }


    let name = currentActiveTab === 0 ? "" : activeData.find(x => x.id == currentActiveTab).name
    const [activeTabs,setActiveTabs]=useState(false)


// const handelActivityClick = async(id)=>{
   
// }
    //==============Main_return_section=================//
    return (
        <Fragment>
            <div className='mx-3'>
                <div className="right-setting pb-3 ">
                    <div className="profile-search-blk  w-100 ">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <input type="text" className="form-control Feeling-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                    </div>
                </div>
            </div>
            <div className='Feeling_scroll_box'> 
                <div className='mx-3'>
                    <div className={`biz-about-cont ${currentActiveTab !== 0 ? "d-none" : ""}`}>
                        <ul>
                            {activeData && 
                                activeData.map((item) => (
                                    <li className={currentActiveTab === item.id ? 'Activity_setting' : "Sub_Activity"}>
                                        <div className='nave-items-bizPage  d-flex justify-content-between'
                                            active={currentActiveTab === item.id}
                                            onClick={() => {toggles(item.id)}}>
                                            <div className='d-flex align-items-center'>
                                                <img src={item.iconURL}                         alt="emoji"
                          width="40px"
                          height="40px" />
                                                <span className='ml-3'>{item.name}</span>
                                            </div>
                                            <ChevronRight size={20} />
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

            </div>
            <div className={`${currentActiveTab !== 0 ? "" : "d-none"}`} >
                <div className='UpdateActivity_blk_section mb-3 mx-3'>
                    <h3 className='mr-3 d-flex align-items-center'>{name}<span className='ml-2'><XCircle onClick={() => toggles(0)} size={17} color="#C4C4C4" /></span></h3>
                </div>
                <div className='Feeling_scroll_box'>
                    {/* <div className='mt-3 mx-3'>
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row> */}
                                    { activityData2 &&  activityData2.map((item) => (
                                        <Col sm={12} className="mb-3">
                                            <div className={currentActiveTab === item.id ? 'Feeling_Main_blk_section' : "Feeling_Main_blk_Subsection"} 
                                              onClick={()=>{handleActivity(item.ActivityChildId)}}>
                                                <div className=''>
                                                    <img src={item.iconURL}                           alt="emoji"
                          width="40px"
                          height="40px"  />
                                                </div>
                                                <h4 className='ml-3'> {item.name}</h4>
                                            </div>
                                        </Col>
                                    ))}
                                {/* </Row>
                            </TabPane> */}
                            {/* <TabPane tabId="2">
                                <Row>
                                    {CelebratingData.map((item) => (
                                        <Col sm={12} className="mb-3">
                                            <div className={currentActiveTab === item.id ? 'Feeling_Main_blk_section' : "Feeling_Main_blk_Subsection"} >
                                                <div className=''>
                                                    <img src={item.img} alt="emoji" />
                                                </div>
                                                <h4 className='ml-3'> {item.name}</h4>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </TabPane> */}

                        {/* </TabContent>
                    </div> */}
                </div>
            </div>




        </Fragment>
    )
}

export default Activity