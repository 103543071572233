import * as types from "../../Constants/Connection/index";
import axios from "axios";

export const userApproveRequestsGet = (userApproveRequests) => ({
  type: types.GET_USER_APPROVE_REQUESTS,
  payload: userApproveRequests,
});

export const getUserApproveFollowRequest = (request) => {
  return function (dispatch) {
    //console.log("requested ");
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user) {
      console.log("user found sending req=====> ", request.id);
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/hamBurgerMenu/acceptRequest`,
          {
            followedById: request.id,
            type: request.Type
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          const successResult = res.data.data.successResult;
          dispatch(userApproveRequestsGet(successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

const userDisapproveRequestsGet = (userDisapproveReq) => ({
  type: types.GET_USER_DISAPPROVE_REQUESTS,
  payload: userDisapproveReq,
});

export const getUserDispproveFollowRequest = (request) => {
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user?.token) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/hamBurgerMenu/declineRequest`,
          {
            followingRequestId: request.userId,
          },
          { headers: { Authorization: `Bearer ${user?.token}` } }
        )
        .then((res) => {
          dispatch(userDisapproveRequestsGet(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
