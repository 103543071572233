import { Checkbox } from '@mui/material'
import React, { useState } from 'react';

import { Fragment } from 'react'
import { Sliders } from 'react-feather'
import FlwrImg from '../../profile-components/Img/FlwrImg.png'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import "../biz.css"

const InviteModal = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  

    const InviteData = [
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"

        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"
        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"
        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"
        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"
        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"

        },
        {

            name: "Marvin McKinney",
            join: "dianne-russell123",
            status: "Following since 2 Days"
        },
    ]
    const [checkboxes, setCheckboxes] = useState(InviteData.map(() => false)); // Initialize with all checkboxes unchecked

    const toggleCheckbox = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);
    };

    const toggleSelectAll = () => {
        const allSelected = checkboxes.every((checkbox) => checkbox);
        const updatedCheckboxes = checkboxes.map(() => !allSelected);
        setCheckboxes(updatedCheckboxes);
    };
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} className='modal-dialog-centered invitemodal ' toggle={props.updateOpen} >
                    <div className='w-100 border-bottom'>
                        <div className=' mx-3 py-3  d-flex justify-content-between Heading_Invite_Section'>
                            <h4 >Invite People</h4>
                            <span style={{ cursor: "pointer" }} onClick={props.updateOpen}>Done</span>
                        </div>
                    </div>

                    <ModalBody >
                        <Row className="mt-2">
                            <div></div>
                            <Col md="8" className='Like_search'>
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="search" className="form-control invitrinput" name="search1" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>

                            </Col>
                            <Col md="4" className="d-flex justify-content-end  filterinvite">

                                <UncontrolledButtonDropdown >
                                    <DropdownToggle color='primary' outline className="border border-muted ml-3 ">
                                        <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filter
                                    </DropdownToggle>
                                    <DropdownMenu className="DropDown_Share_item droup ">
                                        <DropdownItem>
                                            SocioMate</DropdownItem>
                                        <DropdownItem>
                                            Following</DropdownItem>
                                        <DropdownItem>
                                            Colleagues</DropdownItem>
                                        <DropdownItem>
                                            Family</DropdownItem>
                                        <DropdownItem>
                                            Relatives</DropdownItem>
                                        <DropdownItem>
                                            Work</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md="6" className="SubHeading_Invite_Section">
                                <h4 className='invitesocio' style={{position:"absolute",top:"4px"}}>SocioMate  (839)</h4>
                            </Col>
                            <Col md="6" className="d-flex justify-content-end SubHeading_Invite_Section">
                            <p  className="inviteallsocio"style={{ cursor: 'pointer' }} onClick={toggleSelectAll}>Invite all</p>
                            </Col>
                        </Row>
                        <Row className="mt-3 scrollForNotification">
                            {
                                InviteData.map((data, index) => (
                                    <Fragment>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                                <div className="group-member-img-cont-blk">
                                                    <img src={FlwrImg} alt="img" className='img-fluid imginvite' />
                                                    <div className="member-cont-blk">
                                                        <h4 className='inviteheading'>{data.name}</h4>
                                                        <p className='text-muted'>{data.join}</p>
                                                        <div className=" Post_Count">
                                                            {data.status}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Block-btns-blk'>
                                                <Checkbox {...label} checked={checkboxes[index]} onChange={() => toggleCheckbox(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </Row>
                    </ModalBody>

                </Modal>

            }

        </Fragment>
    )
}

export default InviteModal