import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";
import { loadAllPostsByUserId } from "../SocialFeed/getAllPostsByUserIdAction";

const getAllUserPosts = (allUserPosts) => ({
  type: types.GET_ALL_USER_POSTS,
  payload: allUserPosts,
});

const postAdded = () => ({
  type: types.CREATE_POST,
});

const postDeleted = () => ({
  type: types.DELETE_POST,
});

// get all user post

export const loadAllUserPosts = (searchKey,pageIndex,pageSize,userId) => {
  return async (dispatch) => {
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;

      const user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: { Authorization: `Bearer ${user?.token}` },
      };

      if (user) {
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/post/getFeedPosts`,
          {
            searchKey: searchKey||'',
            pageIndex: pageIndex||0,
            pageSize: pageSize||10,
            lat: latitude,
            long: longitude,
            postType: "",
            userId: userId||user.id,
          },
          config
        );
          // console.log("getAllUsersPostsActions.js all the feeds here",response.data.data.successResult.rows)
          const final_response=response.data.data.successResult.rows;
          for(let i=0;i<final_response.length;i++){
            if(final_response[i].postType===""){
              final_response[i].postType="recomendation"
            }
          }

        // dispatch(getAllUserPosts(response.data.data.successResult.rows));
           dispatch(getAllUserPosts(final_response));
      }
    } catch (error) {
      console.error("Error loading user posts:", error);
    }
  };
};

// Utility function to get the current position
const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject(new Error("Geolocation is not supported by your browser."));
    }
  });
};

// add post

export const addPost = (post) => {
  if (post.alertLevelId) {
    post.postType = "alert";
  }

  const user = JSON.parse(localStorage.getItem("sociomeeUser"));

  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/createPost/`, post, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((res) => {
        // Check if the response indicates a successful post creation
        if (res?.data?.success) {
          // Dispatch the action to update state
          dispatch(postAdded());

          // Load all user posts again to reflect the new post
          dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// delete post
export const deletePost = (id) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/deletePost/`, id, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((res) => {
        console.log("delete post response :", res);
        dispatch(postDeleted(res.data));
        dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// undo delete post
export const undoDeletePost = (id) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/undoPostDelete`, id, {
        headers: { Authorization: `Bearer ${user?.token}` },
      })
      .then((res) => {
        dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
