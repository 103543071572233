import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const getUserReported = (getUserReport) => ({
    type: types.GET_USER_REPORT,
    payload: getUserReport,
});
// get all biz category
export const getActionUserReported = (request, selectedOption, remark) => {
    //console.log("here we are ================>", data, selectedOption, remark)
   
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    return function (dispatch) {
        if (user) {
           
            axios.post(`${process.env.REACT_APP_IPURL}/user/reportUser/`,
            {
             otherUserId: request.id,
             reportOptionId: selectedOption,
             remark: remark
            },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                .then((res) => {
                    
                    dispatch(getUserReported(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};
