import React, { Fragment,useEffect,useState } from "react";
import { CardBody, Input } from "reactstrap";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { useDispatch, useSelector } from "react-redux";

const Recommendation = ({ onRecommendationChange,postType,states,currState,recommendationValue,postEditValue }) => {
  console.log("recommendation post selected",postEditValue)
 
    const [recommendation, setRecommendation] = useState(
       recommendationValue
      );
      // if(postEditValue){
      //   setRecommendation(postEditValue.caption)
      // }
      states=postType
      currState=postType
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
    // Handle input change

    const handleRecommendationChange = (e) => {
        const newValue = e.target.value;
        setRecommendation(newValue);
        onRecommendationChange(newValue); 
        console.log("newValue",newValue);
      };
      // useEffect(()=>{
      //  setRecommendation(recommendationValue)
      // },[recommendationValue])
      useEffect(() => {
        // Set the initial state only when the recommendationValue changes
        if (postEditValue && postEditValue.caption) {
          setRecommendation(postEditValue.caption);
        } else if (recommendationValue) {
          setRecommendation(recommendationValue);
        }
      }, [recommendationValue, postEditValue]);
      
  //====================== Main_return_function ===================//
  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center my-2 py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            {userProfileByUserId.fullName}
          </h5>
        </div>
        <div>
          <div className="mx-4 text-center">
            <Input
              type="textarea"
              className="d-flex text-primary Recommendation_input_box positionForReccplaceholder align-items-center text-left w-100"
              value={recommendation}
              onChange={handleRecommendationChange}
              cols="15"
              rows="5"
              maxLength="320"
            />
            <p className="Post_creation_text_limt mt-1 text-end">
              Max 320 characters
            </p>
          </div>
        </div>
      </CardBody>
    </Fragment>
  );
};

export default Recommendation;
