import React from 'react'
import { Button } from 'react-bootstrap'
import { Filter, Settings } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { CountryFilters } from './HoverComponents/CountryFilters'
import './Style.css'

const CommanHeader = () => {
    return (
        <>
            <div className='nav-flex-container mx-3 my-3'>
                <div>
                    <h2 className='dark-heading-style'>My Connections</h2>
                </div>
                <div className='sub-nav-flex  size-sm-mt-4'>
                    <CountryFilters />
                    <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o mx-2'>
                        <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                            <Button variant="outline-primary">
                                <Filter size={18} className='mr-2' />
                                Filters
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu className='connection-dropdown-menu'>
                            <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                Newest first
                            </DropdownItem>
                            <DropdownItem className='py-2 menu-hover-style'>
                                Oldest first
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <NavLink to="/Setting" >
                        <Button variant="outline-primary" className='margin-small'>
                            <Settings size={18} className='mr-2' />Setting</Button>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default CommanHeader