import React, { useEffect, useState } from 'react';
import { Copy, CornerLeftDown, Flag, MoreVertical, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import india_svg from '../../../NewComponents/IMG/india_svg.svg'
import { BlockUserModal } from '../HoverComponents/BlockUserModal';
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';
import { NavLink } from 'react-router-dom';

export const PeopleListView = () => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();

    // -------------  share Modal open ------------- 
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    //  ------------- Start ReportedOption  ------------- 
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    useEffect(() => {
        dispatch(loadAllUserFollowers())
    }, [])
    return (
        <>
            {
                getUserFollowers && getUserFollowers.rows?.slice(0, 8).map((request) => {
                   
                    return <div className="connection-card box-shadow-style  content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3">
                        <div class="nav-flex-container">
                            <div class="media media-new d-sm-flex">
                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                    <img src={request.profileImage || `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`}
                                        className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                    <span class="available-stats online"></span>
                                    <PublicAccount request={request} />
                                </div>
                                <div class="media-body d-md-block pl-1">
                                    <NavLink to='/MyProfile' ><h4 className='m-0 name-style'>{request.fullName?.substring(0, 21) || 'name'}</h4></NavLink>
                                    <div className='d-flex'>
                                        <p className='user-name-style'>@{request.userName || 'userName'}  |  </p>
                                        <div className='d-flex'>
                                            <img src={india_svg} alt='...' />
                                            <span className='professional-style ml-1'>India</span>
                                        </div>
                                    </div>
                                    <MutualFriendList />
                                    <div className="people-likes matual-friend-sec d-flex justify-content-between ">
                                        {/* <p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '150px' }}>This text is a one line description</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request.totalPostCount}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request.totalFollowingCount}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request.totalFollowersCount}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className='ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center'>
                            {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                            {/* <div className='mt-4 rem-mob-view'> */}
                            <Button outline className='btn-outline-blue' color="lightblue"  >
                                <CornerLeftDown size={15} />&nbsp;<span className='font-weight-bold'>&nbsp;Withdraw</span>
                            </Button>
                            {/* </div> */}
                            <div className='d-flex align-items-end flex-column justify-content-end px-4'>
                                <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                    <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                        <MoreVertical size={22} />
                                    </DropdownToggle>
                                    <DropdownMenu className='Connection-dropdown'>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style'><CornerLeftDown size={15} className='mr-2' />Withdraw</DropdownItem>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                        <div className='border-bottom py-2 menu-hover-style'>
                                            <BlockUserModal
                                                btnName='Block User' />
                                        </div>
                                        <DropdownItem tag='a' className='py-2 menu-hover-style' onClick={toggleReport}>
                                            <Flag size={15} className='mr-2' />Report User
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* </ul> */}
                        </div>

                    </div>
                })
            }

            {/*  ------------------ Add Report Option  ------------------ */}
            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
        </>
    )
}

