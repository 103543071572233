import React, { Fragment, useState } from "react";
import { Edit2, Image, X, XCircle } from "react-feather";
import { Card } from "reactstrap";
import CircularStatic from "../CircularProgressWithLabel";
import { useEffect } from "react";

const Media = ({
  toggle,
  selectedImages,
  handleImageSelect,
  uploadedImageURLs,
  setSelectedImages,
  toggleTheFile,
  isLoading,
  userProfileByUserId,
  clearImages,mediaPost,setMediaPost,postEditValue
}) => {
  sessionStorage.setItem("images", selectedImages);
// console.log(postEditValue.mediaList,"in media section")
  // Define state to manage selected media
 
  // const [mediaPost, setMediaPost] = useState(setSelectedImages ?? []);
  console.log(selectedImages,mediaPost,"   selected images/videos")
  // useEffect(()=>{},[mediaPost])
  // Handle file input change
  const handleChange = (e) => {
    setMediaPost([...mediaPost, ...e.target.files]);
    mediaPost.filter((item,
      index) => mediaPost.indexOf(item) === index);
      
  };


  // Function to render selected media previews
  const renderMediaPreviews = () => {
    return (
      <div className="Media_Img_section">
        {postEditValue && postEditValue.mediaList.map((media)=>{
          <div className="border" style={{ borderRadius: "5px" }}>
           {media.fileType === "video" ? (
              <video width="100%" height="300" controls>
                <source
                  src={media.fileURL}
                />
              </video>
            ) : (
              <img
                src={media.fileURL}
                className="img-fluid"
              />
            )}
            </div>
        })
        }
        {mediaPost.map((media, index) => (
          <div key={index} className="border" style={{ borderRadius: "5px" }}>
            {media.type === "video/mp4" ? (
              <video width="100%" height="300" controls>
                <source
                  src={`${URL.createObjectURL(media)}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={`${URL.createObjectURL(media)}`}
                className="img-fluid"
                alt={`Media ${index}`}
              />
            )}
          </div>
        ))}

         {/* { selectedImages && selectedImages.map((media, index) => (
            <div key={index} className="border" style={{ borderRadius: "5px" }}>
            {"sdd" === "video/mp4" ? (
              <video width="100%" height="300" controls>
                <source
                  src={`${media}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={`${process.env.REACT_APP_IMAGEURL + media}`}
                className="img-fluid"
                alt={`Media ${index}`}
              />
            )}
          </div>
        ))} */}
      </div>
    );
  };

  return (
    <Fragment>
     
      <Card
        className={
          uploadedImageURLs && selectedImages && uploadedImageURLs.length < 1 && selectedImages.length < 1
            ? "Addmedia_sub_section"
            : uploadedImageURLs.length > 1
            ? "AddmediaOne_sub_section"
            : null
        }
      >
        <div
          className="Img_right_section "
          onClick={() => {
            toggle("/modal/0");
          }}
        >
          <XCircle />
        </div>
        {
         uploadedImageURLs && selectedImages && uploadedImageURLs.length < 1 && selectedImages.length < 1 ? (
            <div
              onClick={(e) => {
                toggleTheFile(e);
              }}
            >
              <div className="text-center">
                <Image />
                <h3 className="py-2"> Add Photo/ Videos</h3>
                <p>or drag and drop</p>
                {isLoading ? <CircularStatic /> : " "}
              </div>
              <input
                type="file"
                name=""
                multiple
                onChange={(e) => {
                  handleImageSelect(e);
                  handleChange(e);
                }}
                className="InputFile d-none"
                id=""
              />
            </div>
          ) : (
            renderMediaPreviews()
          ) // Use the renderMediaPreviews function to display selected media
        }
        <div
          className="Img_right_section "
          onClick={() => {setMediaPost([]);clearImages()}}
        >
          <XCircle color="#C4C4C4" />
        </div>
        <div
          className="Edit_left_section "
          onClick={() => {
            toggle("/modal/8");
          }}
        >
          <Edit2 color="#808080" size={15} />
          &nbsp;
          {mediaPost.length > 0 && mediaPost.length <= 1 ? "Edit" : "Edit All"}
        </div>
        <div
          className={
            mediaPost.length > 0 && mediaPost.length <= 1
              ? "AddMore_left_section ml-2"
              : "AddMore_left_section ml-4"
          }
          onClick={(e) => {
            toggleTheFile(e);
          }}
        >
          <input
            type="file"
            name=""
            onChange={(e) => {
              handleImageSelect(e);
              handleChange(e);
            }}
            className="InputFile d-none"
            id=""
          />
          <Image color="#808080" size={15} />
          &nbsp;Add Photo/ Videos
        </div>
        {/* Rest of your component JSX */}
      </Card>
    </Fragment>
  );
};

export default Media;

// import React, { Fragment, useState } from 'react'
// import { Edit2, Image, Plus, X, XCircle } from 'react-feather'
// import { Card } from 'reactstrap'

// import CircularStatic from '../CircularProgressWithLabel'

// const Media = ({ toggle, selectedImages, handleImageSelect,uploadedImageURLs, setSelectedImages, toggleTheFile, isLoading ,userProfileByUserId}) => {
//     sessionStorage.setItem("images", selectedImages)

// //  console.log(selectedImages)
//     //========== Main_retun_function ================//
//     return (
//         <Fragment>
//             <Card className={selectedImages.length < 1 ? "Addmedia_sub_section" : selectedImages.length > 1 ? "AddmediaOne_sub_section" : null}>

//                 <div className='Img_right_section ' onClick={() => {
//                     toggle("/modal/0");
//                 }} >
//                     <XCircle />
//                 </div>

//                 {selectedImages.length < 1 ?
//                     <div onClick={(e) => { toggleTheFile(e) }}>
//                         <div className='text-center' >
//                             <Image />
//                             <h3 className='py-2'> Add Photo/ Videos</h3>
//                             <p>or drag and drop</p>
//                             {isLoading ? <CircularStatic /> : " "}
//                         </div>
//                         <input type="file" name="" multiple onChange={(e) => handleImageSelect(e)} className='InputFile d-none' id="" />
//                     </div> :
//                     //=============== start_media_Grid_preview_section ================//
//                     <div className='Media_Img_section'>

//                         {selectedImages.map((fileURL, index) => (
//                            <div key={index} className='border' style={{ borderRadius: "5px" }}>
//                              <img src={fileURL} className="img-fluid" alt={`Image ${index}`} />
//                             </div>
//                        ))}
//                         <div className='Img_right_section ' onClick={() => setSelectedImages([])}>
//                             <XCircle color='#C4C4C4' />
//                         </div>
//                         <div className='Edit_left_section ' onClick={() => {
//                             toggle("/modal/8")
//                         }}>
//                             <Edit2 color='#808080' size={15} />&nbsp;{selectedImages.length > 0 && selectedImages.length <= 1 ? "Edit" : "Edit All"}
//                         </div>
//                         <div className={selectedImages.length > 0 && selectedImages.length <= 1 ? "AddMore_left_section ml-2" : "AddMore_left_section ml-4"}
//                             onClick={(e) => { toggleTheFile(e) }} >
//                             <input type="file" name="" onChange={(e) => handleImageSelect(e)} className='InputFile d-none' id="" />
//                             <Image color='#808080' size={15} />&nbsp;Add Photo/ Videos
//                         </div>
//                         {/*================= start_first_media_Preview ====================*/}
//                         <div className={`border ${selectedImages.length > 1 && selectedImages.length <= 2 ? "" : "d-none"}`}>
//                             {selectedImages.length > 1 ?
//                                 <div>
//                                     <img src={userProfileByUserId?.profileImage.length > 1 ? selectedImages[1] : ""} alt='ffd' className="img-fluid " />
//                                 </div>
//                                 : null}
//                         </div>
//                         {/*================= end_first_media_Preview ====================*/}

//                         {/*================= start_2nd_media_Preview ====================*/}
//                         <div className={`d-flex w-100 ${selectedImages.length > 2 && selectedImages.length <= 3 ? "" : "d-none"}`}>
//                             <div className='w-50 border' style={{ borderRadius: "5px" }}>
//                                 {selectedImages.length > 1 ?
//                                     <img src={selectedImages.length > 1 ? selectedImages[1] : ""} alt='ffd' className="img-fluid" /> : null}
//                             </div>
//                             <div className='w-50 border' style={{ borderRadius: "5px" }}>
//                                 {selectedImages.length > 2 ?
//                                     <img src={selectedImages.length > 2 ? selectedImages[2] : ""} alt='ffd' className="img-fluid" /> : null}
//                             </div>
//                         </div>
//                         {/*================= start_2nd_media_Preview ====================*/}

//                         {/*================= start_3rd_media_Preview ====================*/}
//                         <div className={`d-flex w-100 ${selectedImages.length > 3 ? "" : "d-none"}`}>
//                             <div style={{ width: "33.3%", borderRadius: "5px" }} className='border'>
//                                 {selectedImages.length > 1 ?
//                                     <img src={selectedImages.length > 1 ? selectedImages[1] : ""} alt='ffd' className="img-fluid" /> : null}
//                             </div>
//                             <div style={{ width: "33.3%", borderRadius: "5px" }} className='border'>
//                                 {selectedImages.length > 2 ?
//                                     <img src={selectedImages.length > 2 ? selectedImages[2] : ""} alt='ffd' className="img-fluid" /> : null}
//                             </div>
//                             <div style={{ width: "33.3%", borderRadius: "5px" }} className={selectedImages.length > 4 ? "Trhied_media_blk border" : null}>
//                                 {selectedImages.length > 3 ?
//                                     <img src={selectedImages.length > 3 ? selectedImages[3] : ""} alt='ffd' className="img-fluid" /> : null}
//                                 <div className='Update_plus_count'>
//                                     <span><Plus color='#ffff' />{selectedImages.length - 4}</span>
//                                 </div>
//                             </div>

//                         </div>
//                         {/*================= start_3rd_media_Preview ====================*/}
//                     </div>
//                     //=============== end_media_Grid_preview_section ================//

//                 }
//             </Card>
//         </Fragment >
//     )
// }

// export default Media
