import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { loadAllUserPosts } from "../../../Services/Actions/SocialFeed/getAllUserPostsAction";
import { Snackbar } from "@mui/material";

const FeedDeleteModal = ({ postType, openModal, DeleteModalOpen }) => {
  console.log("we are in delete modal")
  const dispatch = useDispatch();
  const [showUndoNotification, setShowUndoNotification] = useState(false);
  const [reqPostId, setReqPostId] = useState("");

  const handleDelete = async (postType) => {
    setReqPostId(postType.postId);

    try {
      const requestBody = {
        postId: [postType.postId],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/deletePost`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        dispatch(loadAllUserPosts());
        DeleteModalOpen();
        setShowUndoNotification(true);

        setTimeout(() => {
          setShowUndoNotification(false);
        }, 10000);
      } else {
        console.error(
          "Error deleting post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const undoDelete = async () => {
    try {
      const requestBody = {
        postId: reqPostId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/undoPostDelete`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json", // Set the content type
          },
        }
      );
      console.log("response====>", response);
      if (response.status === 200) {
        dispatch(loadAllUserPosts());
        setShowUndoNotification(false);
      } else {
        console.error("Can't Undo this post.", response.status);
      }
    } catch (error) {
      console.error("Error in Undo post:", error);
    }
  };

  const snackbarStyle = {
    backgroundColor: "#000000",
    color: "white",
    textAlign: "center",
    width: "200px",
    borderRadius: "4px",
  };

  const buttonStyle = {
    backgroundColor: "#81C14B",
    color: "white",
    marginLeft: "8px",
    padding: "5px",
    borderRadius: "4px",
    border: "none",
  };

  return (
    <>
      {openModal !== false && (
        <Modal
          isOpen={openModal}
          toggle={DeleteModalOpen}
          className="modal-dialog-centered"
        >
          <ModalHeader className="d-flex justify-content-center bg-light">
            <h2>Delete</h2>
          </ModalHeader>
          <ModalBody className="text-center">
            <h4 className="py-2">Do you really want to Delete this Post?</h4>
          </ModalBody>
          <ModalFooter className="d-flex border-0 justify-content-center">
            <Button variant="outline-secondary" onClick={DeleteModalOpen}>
              <span className="ml-1 w-25">Cancel</span>
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete(postType);
              }}
            >
              <span className="ml-1 w-25">Delete</span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Snackbar
        open={showUndoNotification}
        message="Post deleted. Click Undo to recover Post within 120 seconds"
        ContentProps={{
          style: snackbarStyle,
        }}
        action={
          <button
            onClick={() => {
              undoDelete();
            }}
            style={buttonStyle}
          >
            Undo
          </button>
        }
      />
    </>
  );
};

export default FeedDeleteModal;
