import axios from 'axios';
import { CREATE_POST, DELETE_POST, UPLOAD_FILE } from '../../Constants/SocialFeed';
import { sweetAlertConfirmation } from '../../../components/group-components/AllGroupsSections/SweetAlert';
import { SweetErrorAlert } from '../../../components/group-components/AllGroupsSections/SweetErrorAlert'


export const createPost = (postData, token) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_IPURL}/post/createPost`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
        },
      });

    const createdPost = response.data;
    //hadnling failresponse in user interface
    if (response.data.success === true) {
      (sweetAlertConfirmation('Post Created successfully!!'))
    }
    //fail response handle
    if (response.data.success === false && response.data.error === false) {
      const errorMessage = response.data.data.errorResult;

      // Check for "Tagging users Daily Limit Exceed"
      if (errorMessage.includes("Tagging users Daily Limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Daily limit of ${limit} tags exceeded for users.`);
      }
      // Check for "Mention Users Daily Limit Exceed"
      else if (errorMessage.includes("Mention Users Daily Limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Daily limit of ${limit} mentions exceeded.`);
      }
      // Check for "Hashtags Daily Limit Exceed"
      else if (errorMessage.includes("Hashtags Daily Limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Daily limit of ${limit} hashtags exceeded.`);
      }
      // Check for "Daily Post Schedule limit Exceed"
      else if (errorMessage.includes("Daily Post Schedule limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Daily limit of ${limit} posts exceeded.`);
      }
      // Check for "MediaList upload limit Exceed"
      else if (errorMessage.includes("MediaList upload limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Media upload limit of ${limit} per post exceeded.`);
      }
      // Check for "Events Duration Limit Exceed"
      else if (errorMessage.includes("Events Duration Limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Event duration limit of ${limit} days exceeded.`);
      }
      // Check for "Poll Duration Limit Exceed"
      else if (errorMessage.includes("Poll Duration Limit Exceed")) {
        const limitMatch = errorMessage.match(/(\d+)/);
        const limit = limitMatch ? limitMatch[0] : '';
        SweetErrorAlert(`Poll duration limit of ${limit} days exceeded.`);
      }
      // Check for "Allowed Poll options Count Exceed"
      else if (errorMessage.includes("Allowed Poll options Count Exceed")) {
        const rangeMatch = errorMessage.match(/(\d+)-(\d+)/);
        const min = rangeMatch ? rangeMatch[1] : '';
        const max = rangeMatch ? rangeMatch[2] : '';
        SweetErrorAlert(`Poll options count must be between ${min} and ${max}.`);
      }
      else {
        SweetErrorAlert(errorMessage);
      }
    }

    //dispaching the values
    dispatch({
      type: CREATE_POST,
      payload: createdPost,
    });
    // ... Any other logic ...
  } catch (error) {
    // console.log("error")
  }
};






export const deletePost = (postId) => async (dispatch) => {
  try {
    // ... Your delete post logic ...
    await axios.post(
      `${process.env.REACT_APP_IPURL}/post/deletePost`,
      postId,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
          "Content-Type": "application/json", // Set the content type
        },
      }
    );

    dispatch({
      type: DELETE_POST,
      payload: postId,
    });
    // ... Any other logic ...
  } catch (error) {
    // Handle error...
  }
};

export const uploadFile = (files, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
      files,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    let fileURLs = response.data.data.successResult;

    dispatch({
      type: UPLOAD_FILE,
      payload: fileURLs,
    });

    // Return the fileURLs array so you can access them in the calling function
    return fileURLs;
  } catch (error) {
    // Handle error...
    throw error; // Re-throw the error so it can be handled in the calling function
  }
};

