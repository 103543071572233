// import { Radio } from "@mui/material";
// import React, { Fragment } from "react";
// import { useState } from "react";
// import { ArrowLeft } from "react-feather";
// import { Col, Row } from "reactstrap";
// import { PublicDropItem } from "../DummyArray";

// const PostValidation = ({ toggle, activeTab,setprivacy }) => {
//   const [selectedValue, setSelectedValue] = useState(2);

//   const handleChange = (event, id,privacy) => {
//     setSelectedValue(id);
//     setprivacy(privacy)
//     console.warn(event.target.value, privacy);
//   };

//   let oNSportRadioId = [2, 3, 6];
//   let oNheaderId = [4, 5];

//   //============== Main_retun_function ==============//
//   return (
//     <Fragment>
//       <div className="border-bottom w-100">
//         <div className={`mx-3 py-3  FellingActivity_main_blk `}>
//           {oNheaderId.includes(selectedValue) ? (
//             <div
//               onClick={() => {
//                 setSelectedValue(2);
//               }}
//               style={{ cursor: "pointer" }}
//               className="Arrow_main_blk_section rounded-circle"
//             >
//               <ArrowLeft />
//             </div>
//           ) : (
//             <div
//               onClick={() => toggle(`/modal/${activeTab - 2}`)}
//               style={{ cursor: "pointer" }}
//               className="Arrow_main_blk_section rounded-circle"
//             >
//               <ArrowLeft />
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="Feeling_scroll_box">
//         <div
//           className={`mt-3 mx-3 ${
//             oNSportRadioId.includes(selectedValue) ? "" : "d-none"
//           }`}
//         >
//           <div className="pb-2">
//             <h3>Who can see your post?</h3>
//             <p>
//               Your post will appear in Feed, on your profile and in search
//               results. Your default audience is set to Public, but you can
//               change the audience of this specific post.
//             </p>
//           </div>
//           {/* <div className='py-2'>
//                         <p>Your default audience is set to Public, but you can change the audience of this specific post.</p>
//                     </div> */}
//           <Row className="mt-3">
//             {PublicDropItem.map((item) => (
//               <Col sm={12} className="mb-3">
//                 <div
//                   className={`Public_Creation_blk ${
//                     selectedValue === item.id
//                       ? "Post_Audience_blk"
//                       : "Post_Audience_Subblk"
//                   }`}
//                   active={selectedValue === item.id}
//                   onClick={(e) => handleChange(e, item.id,item.privacy)}
//                 >
//                   <div className="d-flex align-items-start">
//                     <div className="Public_Icon">{item.icon}</div>
//                     <div className="ml-3">
//                       <h3>{item.privacy}</h3>
//                       <p>{item.desp}</p>
//                     </div>
//                   </div>
//                   <div>
//                     <Radio
//                       checked={selectedValue === item.id}
//                       onChange={(e) => handleChange(e, item.id)}
//                       value={item.id}
//                       name="radio-buttons"
//                       className="Radio_btn_check"
//                       color="primary"
//                     />
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default PostValidation;

import { Radio } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { ArrowLeft, Globe } from "react-feather";
import { Col, Row } from "reactstrap";
import axios from "axios";

const PostValidation = ({ toggle, activeTab, setprivacy, publicDropItems }) => {
  const [selectedValue, setSelectedValue] = useState(
    "3ba6517d-db6b-46c2-a692-6b072b137194"
  );

  const handleChange = (event, id, item) => {
    setprivacy(item.name);
    setSelectedValue(item.id);
  };

  return (
    <Fragment>
      <div className="border-bottom w-100">
        <div className={`mx-3 py-3  FellingActivity_main_blk `}>
          <div className="Feeling_scroll_box">
            <div>
              <div className="pb-2">
                <h3>Who can see your post?</h3>
                <p>
                  Your post will appear in Feed, on your profile and in search
                  results. Your default audience is set to Public, but you can
                  change the audience of this specific post.
                </p>
              </div>
              <Row className="mt-3">
                {publicDropItems.map((item) => (
                  <Col sm={12} className="mb-3" key={item.id}>
                    <div
                      className={`Public_Creation_blk ${
                        selectedValue === item.id
                          ? "Post_Audience_blk"
                          : "Post_Audience_Subblk"
                      }`}
                      active={selectedValue === item.id}
                      onClick={(e) => {
                        handleChange(e, item.id, item);
                      }}
                    >
                      <div className="d-flex align-items-start">
                        <div className="Public_Icon">
                          {<Globe color=" #808080" size={16} />}
                        </div>
                        <div className="ml-3">
                          <h3 style={{ marginTop: "10px" }}>{item.name}</h3>
                        </div>
                      </div>
                      <div>
                        <Radio
                          checked={selectedValue === item.id}
                          onChange={(e) => handleChange(e, item.id, item)}
                          value={item.id}
                          name="radio-buttons"
                          className="Radio_btn_check"
                          color="primary"
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};             

export default PostValidation;
