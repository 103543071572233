
import { Fragment, useState } from 'react'
import { Check, Search } from 'react-feather'
import { Button, Modal, ModalHeader, ModalBody, CardBody, Card, InputGroup, Input } from 'reactstrap'
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import pro_mutual from '../../../NewComponents/IMG/pro_mutual.png';
import { AvatarGroupSm } from '../ConnectionPlaceMenu/AvatarGroupSm';

const ModalConfig = [
    {
        id: 1,
        modalTitle: 'Mutual Friends (67)',
        modalClass: 'modal-xs'
    }

]

export const GroupMembers = () => {
    const [modal, setModal] = useState(null)
    const toggleModal = id => {
        if (modal !== id) {
            setModal(id)
        } else {
            setModal(null)
        }
    }

    const MutualFriends = [
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        },
        {
            profile: pro_mutual,
            Name: "Angelina Joseph ",
            userName: "@angelinajoseph",
            countryFlag: india_svg

        }

    ]

    const renderModal = ModalConfig.map(item => {
        return (
            <Fragment key={item.id}>
                <div className="people-likes matual-friend-sec" onClick={() => toggleModal(item.id)} key={item.title} role="button">
                    <AvatarGroupSm />
                    <small className='text-primary font-weight-bold d-inline-block mb-0 text-primary align-items-center text-truncate align-self-center'>&nbsp;+75 sociomate are members </small>
                </div>
                <Modal
                    isOpen={modal === item.id}
                    toggle={() => toggleModal(item.id)}
                    className={'modal-dialog-centered {item.modalClass}'}
                    key={item.id}
                >
                    <ModalHeader toggle={() => toggleModal(item.id)} className='bg-light'>
                        <h3>{item.modalTitle}</h3>
                    </ModalHeader>
                    <ModalBody className='Modal_Body_Section py-1'>
                        <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
                            <Search color='#666666' size={16} />
                            <Input type='search' placeholder=" Search..." className='coonection-input-style' />
                        </InputGroup>
                        <div className='modal-scroll'>
                            {MutualFriends.map((data) => (
                                <Card className=' m-2 shadow-sm' >
                                    <CardBody>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div class="media media-new d-sm-flex">
                                                <div class="user-img align-items-top">
                                                    <img src={data.profile} className="connection-profile rounded-circle connection-profile-new" alt="shivam singh" />
                                                    <span class="available-stats online"></span>
                                                </div>
                                                <div class="media-body d-md-block pl-1">
                                                    <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '120px' }}>{data.Name}</h4>
                                                    <div className='d-flex'>
                                                        <p className='user-name-style'>{data.userName}  |  </p>
                                                        <img src={data.countryFlag} className='ml-1' alt='...' />
                                                    </div>
                                                </div>
                                            </div>
                                            <Button color='primary' outline>
                                                <Check size={15} />&nbsp;Following
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    })

    return <div className='demo-inline-spacing'>{renderModal}</div>
}
