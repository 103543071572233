import React, { useEffect } from 'react';
import { CheckCircle, ChevronRight, Copy, Flag, Info, MoreVertical, X, XCircle, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap';
import { getUserFollowingRequests } from '../../Services/Actions/UserProfile/userFollowingRequestsAction';
import decline from '../../NewComponents/IMG/Frame 2551.svg'
import accept from '../../NewComponents/IMG/Frame 2560.svg'
import india_svg from '../../NewComponents/IMG/india_svg.svg';
import { MutualFriendList } from './HoverComponents/MutualFriendList';
import BlockModal from './ConnectionModal/BlockModal';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ConnectionReportModal from './ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from './ConnectionModal/ConnectionShareModal';
import { RejectModal, SingleRejectModal } from './ConnectionModal/RejectModal';
import { AcceptModal, SingleAcceptModal } from './ConnectionModal/AcceptModal';
import PrivateAccount from './ConnectionModal/PrivateAccount';
import './Style.css'
import axios from 'axios';

const PendingRequest = () => {
    const { userFollowingRequests } = useSelector(state => state.userFollowingRequestsData)
    const [selectedRequest, setSelectedRequest] = useState(null);
    const dispatch = useDispatch();
    const [followRequest, setFollowRequest] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch user from local storage
                const user = JSON.parse(localStorage.getItem('sociomeeUser'));

                // Make the API request
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getFollowingRequests/`,
                    {},
                    { headers: { Authorization: `Bearer ${user?.token}` } }
                );
                setFollowRequest(response.data.data.successResult)
                // Handle successful response
                console.log(response.data.data.successResult, "------------------->");
            } catch (error) {
                // Handle errors
                console.error(error);
            }
        };
        fetchData();
    }, []);


    //------------------ Accept Modal open ------------------
    const [acceptModalOpen, setAcceptModalOpen] = useState(false)
    const toggleAcceptModalOpen = (request) => {
        setAcceptModalOpen((prevState) => !prevState)
        setSelectedRequest(request)
    }

    //------------------Reject Modal Open ------------------
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const toggleRejectModalOpen = (request) => {
        setRejectModalOpen((prevState) => !prevState)
        setSelectedRequest(request)
    }

    // -------------Single Accept Modal State  -------------
    const [singAcceptModalOpen, setSingAcceptModalOpen] = useState(false)
    const toggleSingAcceptModalOpen = () => {
        setSingAcceptModalOpen((prevState) => !prevState)
    }

    // -------------Single Reject Modal State  -------------
    const [singRejectModalOpen, setSingRejectModalOpen] = useState(false)
    const toggleSingRejectModalOpen = () => {
        setSingRejectModalOpen((prevState) => !prevState)


    }

    const [blockModalOpen, setBlockModalOpen] = useState(false)
    const toggleBlockModalOpen = (request) => {
        setBlockModalOpen((prevState) => !prevState)
        setSelectedRequest(request)
    }

    //------------------ share Modal open------------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // ------------------ Start ReportedOption ------------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState)
        setSelectedRequest(request)
    }

    const sizepending = followRequest.length

    return (
        <>
            <div className="mppage-heading mt-3">
                <div className="group-custom-block">
                    <div className="heading-podcast-blk">
                        <h3 className='connection-sub-heading'>Follow Requests ({sizepending})</h3>
                        {userFollowingRequests.data?.rows?.length === 0 ? null : (
                            <NavLink to='/FollowRequests'>
                                <h5 className=''>View All <ChevronRight size={20} color='#81C14B' className='ml-1' /></h5>
                            </NavLink>
                        )}
                    </div>
                    <Row className='connections_PeopleThisGrid'>
                        {followRequest && followRequest.map((request, index) => {
                            return <Col className='w-100 ' key={index}>
                                <div className="connection-card box-shadow-style  content-mp-block d-flex justify-content-between" >
                                    <div className="media-body d-md-block">
                                        <div className="media media-new d-flex align-items-start">
                                            <div className="user-img d-flex popover___wrapper align-items-start">
                                                <img src={request.profileImage || `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`}
                                                    className="rounded-circle" height={45} width={45} alt="shivam singh" />
                                                <span className="available-stats online"></span>
                                                <PrivateAccount />
                                            </div>
                                            <div className="media-body d-md-block pl-1">
                                                <NavLink to='/MyProfile' >
                                                    <h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request.fullName?.substring(0, 21) || 'name'}</h4>
                                                </NavLink>
                                                <div className='d-flex'>
                                                    <p className='user-name-style rem-mob-view-small'>@{request.userName || 'userName'}  |  </p>
                                                    <img src={india_svg} className='ml-1 rem-mob-view' alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ml-2 d-flex align-items-center justify-content-between mt-2'>
                                            <MutualFriendList />
                                        </div>
                                    </div>
                                    <div className=' flex-column justify-content-end'>
                                        <div className="d-flex align-items-center">
                                            <span role="button" className='conn-btn' onClick={() => { toggleSingAcceptModalOpen(request) }}>
                                                <img src={accept} className="img-fluid" alt="" id='accept-sign' />
                                                <UncontrolledTooltip placement='top' target='accept-sign'>
                                                    Accept
                                                </UncontrolledTooltip>
                                            </span>
                                            <span role="button" className='conn-btn mx-2' onClick={() => { toggleSingRejectModalOpen(request) }}>
                                                <img src={decline} className="img-fluid" alt="" id='reject-sign' />
                                                <UncontrolledTooltip placement='top' target='reject-sign'>
                                                    Reject
                                                </UncontrolledTooltip>
                                            </span>
                                            <UncontrolledButtonDropdown className='d-flex align-items-start flex-column justify-content-end rem-mob-view-small' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                    <MoreVertical size={22} />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleSingAcceptModalOpen(request) }}><CheckCircle size={15} className='mr-2' />Accept</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleSingRejectModalOpen(request) }}><XCircle size={15} className='mr-2' />Decline</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleBlockModalOpen(request) }}><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleReport(request) }}>
                                                        <Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style'><Info size={15} className='mr-2' />Learn more</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                        <div role="button" className='mt-3 d-flex align-items-end justify-content-end'>
                                            <Button color='flat-success' className='btn-transparent pr-0 pb-0'>
                                                <p className='align-self-end font-weight-bold text-right text-wrap'>1 Day Ago</p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row>
                    <Row>
                        <Col xl='12' className='d-flex justify-content-center'>
                            {sizepending === 0 && <p className='text-center connection-sub-heading'>No Requests Found</p>}
                        </Col>
                    </Row>
                  
                </div>
            </div>
            {/* ------------------ Start Report Modal  ------------------*/}
            <ConnectionReportModal
                request={selectedRequest}
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
            {/* -------------  Accept Modal  ------------- */}
            <AcceptModal
                request={selectedRequest}
                openModal={acceptModalOpen}
                openAcceptModal={toggleAcceptModalOpen}
            />
            {/* -------------  Reject modal Modal  ------------- */}
            <RejectModal
                request={selectedRequest}
                openModal={rejectModalOpen}
                rejectModalOpen={toggleRejectModalOpen}
            />
            {/* ------------- Single Accept Modal  ------------- */}
            <SingleAcceptModal
                //request={selectedRequest}
                openModal={singAcceptModalOpen}
                openSingleAcceptModal={toggleSingAcceptModalOpen}
            />
            {/* ------------- Single Reject modal Modal  ------------- */}
            <SingleRejectModal
                //request={selectedRequest}
                openModal={singRejectModalOpen}
                singleRejectModalOpen={toggleSingRejectModalOpen}
            />

            {/* block  */}

            <BlockModal
                request={selectedRequest}
                openModal={blockModalOpen}
                removeModalOpen={toggleBlockModalOpen}
            />
        </>
    )
}

export default PendingRequest;
