import React, { useEffect, useState } from 'react'
import { Copy, CornerLeftDown, Flag, Grid, List, MoreVertical, Search, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';
import {withDrawRequestAction} from '../../../Services/Actions/Connection/getSentRequestWithdrawAction'
import { Button, InputGroup } from 'react-bootstrap';
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import { PeopleListView } from '../ListView/PeopleListView';
import { BlockUserModal } from '../HoverComponents/BlockUserModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import PublicAccount from '../ConnectionModal/PublicAccount';
import '../Style.css'
import { NavLink } from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';

export const PeopleCard = () => {
    const { userSentRequests } = useSelector((state) => state.userSentRequestsData);
    const [searchItem, setSearchItem] = useState("")


    const filteredData = userSentRequests?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase()));
    const dispatch = useDispatch();
    const [Gridview, Setgridview] = useState(false)

    // ------------- share Modal open -------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)

    }

    // withdraw Request

    const handleRequestWithDraw = (id) =>{
        dispatch (withDrawRequestAction(id))
      }
      useEffect((id)=>{
       dispatch(withDrawRequestAction(id))
      },[])

    //  ------------- Start ReportedOption -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // block user

    const handleBlock = (id) =>{

    }

    useEffect(() => {
        dispatch(AllUserSentRequest("People"))
    }, [])

    return (<>
        <div className="mppage-heading mt-4">
            <div className="group-custom-block">
                <div className="nav-flex-container">
                    <h3 className='connection-sub-heading'>Requested People ({userSentRequests?.data?.rows?.length})</h3>
                    <div className='sub-nav-flex size-sm-mt-4'>
                        <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                            <Search color='#666666' size={16} />
                            <Input type='search' placeholder="  Find..." className='coonection-input-style' />
                        </InputGroup>
                        <div className='list-grid-toggle size-xs-mt-4 rem-mob-view'>
                            <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                <Grid size={16} />
                            </Button>
                            <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                <List size={16} />
                            </Button>
                        </div>
                    </div>
                </div>
                {Gridview ?
                    <PeopleListView />
                    :
                    <Row className='connections_PeopleThisGrid mt-3'>
                        {
                            filteredData && userSentRequests?.slice(0, 15).map((request, index) => {
                                return <Col lg='4' className='w-100' >
                                    <div key={index} className="connection-card  content-mp-block d-flex box-shadow-style justify-content-between">
                                        <div className="media-body d-md-block">
                                            <div class="media media-new d-flex align-items-start">
                                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                                    <img src={request.profileImage || `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`}
                                                        className="rounded-circle " height={45} width={45} alt="round" />
                                                    <span class="available-stats online"></span>
                                                    <PublicAccount request={request} />
                                                </div>
                                                <div class="media-body d-md-block pl-1">
                                                    <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate'>{request.fullName?.substring(0, 21) || 'name'}</h4></NavLink>
                                                    <div className='d-flex'>
                                                        <p className='user-name-style'>@{request.userName || 'userName'}  |  </p>
                                                        <img src={india_svg} className='ml-1' alt='...' />
                                                    </div>
                                                    <div className="people-likes matual-friend-sec d-flex justify-content-between my-1 ">
                                                        <p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '150px' }}>This text is a one line description</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-end flex-column'>
                                            <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                    <MoreVertical size={22} />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><CornerLeftDown size={15} className='mr-2' />Withdraw Request</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                    <div className='border-bottom py-2 menu-hover-style'>
                                                        <BlockUserModal
                                                            btnName='Block User' onClick={()=>{handleBlock(request.id)}} />
                                                    </div>
                                                    <DropdownItem className='py-2 menu-hover-style' onClick={()=>{handleRequestWithDraw(request.id)}}>
                                                        <Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <div role="button" className='mt-3 d-flex align-items-end'>
                                                <Button color='flat-success' className='btn-transparent pb-0 pr-0'>
                                                    <CornerLeftDown size={15} color='#2F80ED' />&nbsp;<span className='connection-blue-text rem-mob-view'>Withdraw</span>
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                }
                <Row>
                    <Col xl='12' className='d-flex justify-content-center'>
                        {
                            userSentRequests.length <= 0 && <h3 className='text-center connection-sub-heading'>No Followers Found</h3>
                        }
                    </Col>
                </Row>
            </div>
        </div>
        {/*  ------------------ Add Report Option  ------------------ */}
        <ConnectionReportModal
            modalOpen={reportOpen}
            reportModalOpen={toggleReport}
        />
        {/* ------------------ Start Share Modal  ------------------*/}
        <ConnectionShareModal
            openModal={shareOpen}
            shareModalOpen={toggleShareOpen}
        />
    </>)
}

