import React, { Fragment } from 'react'
import { useState } from 'react'
import { ArrowLeft, XCircle } from 'react-feather'
import Slider from 'react-slick'
import { Col, Input, Row } from 'reactstrap'
import LeftquotationIcon from '../../../NewComponents/IMG/LeftquotationIcon.svg'

const EditAllMedia = ({ toggle, selectedImages }) => {

    const AllImages = []
    
    //==============Start_Slider_settings==================//
    let bizSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1
                }
            },

            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };


    const removeFromSession = (index) => {
        // toggle('/modal/0.1')
        AllImages.splice(AllImages.indexOf(AllImages[index]), 1)
        // sessionStorage.setItem("images", AllImages)
        // sessionStorage.removeItem(AllImages)

    }
    AllImages.forEach((key) => {
        const value = sessionStorage.getItem(key);
        if (value.startsWith('data:image')) {
          sessionStorage.removeItem(key);
        }
      });
    const [filterChoosed, setFilterChoosed] = useState("")

    const FilterGrid = [
        {

            filters: 'none',
            filtersName: 'None'
        },
        {
            filters: 'sepia(0.5) saturate(1.2) hue-rotate(-10deg)',
            filtersName: 'vintage'
        },
        {
            filters: 'contrast(1.2) saturate(1.2)',
            filtersName: 'Bold'
        },
        {
            filters: 'contrast(1.1) brightness(1.1) saturate(1.2) hue-rotate(-10deg)',
            filtersName: 'Crisp'
        },
        {
            filters: 'grayscale(1) contrast(1.2) brightness(0.9) sepia(0.3)',
            filtersName: 'Noir'
        },
        {
            filters: 'contrast(1.2) saturate(1.5) hue-rotate(-20deg)',
            filtersName: 'Pop'
        },
        {
            filters: 'sepia(0.4) saturate(1.5) hue-rotate(20deg)',
            filtersName: 'Warm'
        },
        {
            filters: 'brightness(1.1) contrast(0.9) saturate(0.8) sepia(0.2)',
            filtersName: 'Pastel'
        },
        {
            filters: 'contrast(0.9) brightness(0.9) saturate(0.8) grayscale(1) sepia(0.2)',
            filtersName: 'Matte'
        },
        {
            filters: 'sepia(0.2) hue-rotate(90deg)',
            filtersName: 'Monochrome'
        },
        {
            filters: 'sepia(0.4) saturate(1.5) hue-rotate(-20deg)',
            filtersName: 'Cool'
        },
        {
            filters: 'contrast(0.9) brightness(1.1) saturate(0.9) sepia(0.3) blur(1px)',
            filtersName: 'Retro'
        },


    ]
    //============== Main_return_function ==============// 


    return (
        <Fragment>
            {/* {console.log(AllImages)} */}
            <div className='border-bottom w-100'>
                <div className={`mx-3 py-3  FellingActivity_main_blk `}>
                    <div onClick={() => toggle('/modal/0.1')} style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
                        <ArrowLeft />
                    </div>
                    <div>
                        <h3>Photos/Video</h3>
                    </div>
                    <div></div>
                </div>
            </div>
            <Row className='mx-3'>
                <Col sm={7} className="border-right py-3" >
                    <Slider {...bizSettings} className="default-space">
                        {AllImages.map((_, index) => (
                            <div className="BizPage_Card_section" >
                                <div className="BizPage_option_menu" >
                                    <XCircle color='#ffff' onClick={removeFromSession} />
                                </div>
                                <div className='w-100'>
                                    <img className='w-100' src={LeftquotationIcon} style={{ aspectRatio: "16/9", filter: filterChoosed }} />
                                </div>
                                <div className="py-4 px-3">
                                    <Input type="text" placeholder="Caption" />
                                    <p className='Post_creation_text_limt mt-1 text-end'>Max 64 characters</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Col>
                <Col sm={5} className="py-3">
                    <div className='mb-2'>
                        <h3>Filter</h3>
                    </div>
                    <div className='filter_scroll_box ' height={window.innerHeight / 420}>
                        <div className='Filter_Img_Blk'>
                            {FilterGrid.map((data) => (
                                <>
                                    <div className='FilterImages d-flex flex-column '>
                                        <div className=''>
                                            <img src={selectedImages} style={{ filter: data.filters }} onClick={() => setFilterChoosed(data.filters)} alt="filter" className='Main_filter_img' />
                                        </div>
                                        <h3 className='text-center'>{data.filtersName}</h3>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>

                </Col>
            </Row>

        </Fragment>
    )
}

export default EditAllMedia