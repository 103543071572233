import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { Slash } from 'react-feather'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getActionBlockUnblock } from '../../../Services/Actions/Connection/getBlockUnblockAction'
import { useDispatch } from 'react-redux'

const ModalConfig = [
    {
        id: 1,
        modalClass: 'modal-xs'
    }
]

export const BlockUserModal = (request) => {

    // console.log("block request===============================>",request.request.fullName);
    const [modal, setModal] = useState(null)
    const dispatch = useDispatch();

    const toggleModal = (id) => {
        if (modal !== id) {
            setModal(id);
        } else {
            setModal(null);
        }
    };

    const handleBlockUser = () => {
        // Close the modal
        toggleModal(request);

        dispatch(getActionBlockUnblock(request))
    };

    useEffect(() => {
        // If you want to dispatch something when the component mounts, you can do it here.
        // For example, if you want to fetch data, you can make an API call here.
        // dispatch(getActionBlockUnblock(request));
    }, [])

    const renderModal = ModalConfig.map(item => {
        return (
            <Fragment key={item.id}>
                <div role='button' onClick={() => toggleModal(item.id)}>
                    <Slash size={15} className='ml-3' />&nbsp;&nbsp;{request.btnName}
                </div>
                <Modal
                    isOpen={modal === item.id}
                    toggle={() => toggleModal(item.id)}
                    className={`modal-dialog-centered ${item.modalClass}`}
                    key={item.id}
                >
                    <ModalHeader className='d-flex justify-content-center bg-light'>
                        <h2>Block </h2>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h5 className='py-2'>Would you like to block  and feel secure from any future interactions? It's up to you!</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 justify-content-center w-100'>
                        <Button variant='outline-secondary' onClick={() => toggleModal(item.id)}>
                            <span className='ml-1 w-50'>Cancel</span>
                        </Button>
                        <Button className='border-0' style={{ background: '#FFA500' }} onClick={handleBlockUser}>
                            <span className='ml-1 w-50 text-white'>Block</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    })

    return <div className='demo-inline-spacing'>{renderModal}</div>
}
