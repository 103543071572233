import React, { useEffect, useState } from "react";
import {
  Copy,
  Flag,
  MinusCircle,
  MoreVertical,
  PlusCircle,
  Share,
  UserPlus,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { loadAllUserFollowers } from "../../../Services/Actions/Common/getUserFollowersAction";
import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import { MutualFriendList } from "../HoverComponents/MutualFriendList";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import AddCategoryModal from "../ConnectionModal/AddCategoryModal";
import RemoveModal from "../ConnectionModal/RemoveModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import "../Style.css";
import PublicAccount from "../ConnectionModal/PublicAccount";
import { NavLink } from "react-router-dom";

export const FollowersList = () => {
  const { getUserFollowers } = useSelector(
    (state) => state.GetUserFollowersData
  );
  const dispatch = useDispatch();
  const [selectedRequest, setSelectedRequest] = useState(null);
  useEffect(() => {
    dispatch(loadAllUserFollowers());
  }, []);

  // ----------- share Modal open -----------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };
  // ----------- Remove Followers Modal open -----------
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const toggleRemoveModalOpen = (request) => {
    setRemoveModalOpen((prevState) => !prevState);
    setSelectedRequest(request);
  };

  // follow back

  const [isFollowing, setIsFollowing] = useState(false);

  // Function to toggle follow status
  const toggleFollowBack = (id) => {
    // Assuming this function triggers the API call
    dispatch(loadAllFollowUnFollow(id))
    .then((response) => {
      // Assuming the API call was successful
      if (response.status === 'success') {
        // Update the state to indicate that the user is now following
        setIsFollowing(true);
  
        // Store the follow status in local storage
        localStorage.setItem('isFollowing', 'true');
      }
    })
    .catch((error) => {
      // Handle any API call errors
      console.error('Error:', error);
    });
  }
  
  // Load the follow status from local storage when the component mounts
  useEffect(() => {
    const storedFollowStatus = localStorage.getItem('isFollowing');
    if (storedFollowStatus === 'true') {
      setIsFollowing(true);
    }
  }, []);

  //============block modal------------------------------------
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const toggleBlockModalOpen = (request) => {
    setBlockModalOpen((prevState) => !prevState);
    setSelectedRequest(request);
  };

  const [addCategoryOptions, setAddCategoryOptions] = useState(false);
  const toggleAddCategory = () => {
    setAddCategoryOptions((preState) => !preState);
  };

  // State to track follow status for each user
  const [followStatus, setFollowStatus] = useState({});
  const toggleFollowStatus = (userId) => {
    setFollowStatus((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  const handleFollowBack = (request) => {
    dispatch(loadAllFollowUnFollow(request));
    toggleFollowStatus(request); // Update follow status on successful follow
  };

  //  ----------- Start Add Category Status -----------

  const [addcategoryOption, setAddCategoryOption] = useState(false);
  const toggleAddRelationShip = () => {
    setAddCategoryOption((preState) => !preState);
  };

  //  ----------- Start ReportedOption  -----------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
    setSelectedRequest(request);
  };

  //  ----------- MouseHover over Image state & function  -----------
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {getUserFollowers &&
        getUserFollowers.rows?.slice(0, 8).map((request) => {
          return (
            <div className="connection-card  box-shadow-style content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3">
              <div class="media media-new d-flex align-items-start">
                <div class="user-img d-flex align-items-start popover___wrapper ">
                  <img
                    src={
                      request.profileImage ||
                      `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`
                    }
                    className="rounded-circle "
                    height={45}
                    width={45}
                    alt="shivam singh"
                    onMouseEnter={handleMouseEnter}
                  />
                  <span class="available-stats online"></span>
                  {isHovered ? <PublicAccount request={request} /> : null}
                </div>
                <div className="sub-nav-flex">
                  <div class="media-body pl-1">
                    <NavLink to="/MyProfile">
                      <h4 className="m-0 name-style">
                        {request.fullName?.substring(0, 21) || "name"}
                      </h4>
                    </NavLink>
                    <div className="d-flex">
                      <p className="user-name-style">
                        @{request.userName || "userName"} |{" "}
                      </p>
                      <div className="d-flex ml-1">
                        <img src={india_svg} alt="..." />
                        <span className="professional-style ml-1">India</span>
                      </div>
                    </div>
                    {/* <h6 className='professional-style text-wrap mr-5'>I am a freelance mobile app developer who specializes in native, as well as hybrid app development.</h6> */}
                    <MutualFriendList />
                  </div>
                </div>
              </div>
              <div className="pt-2 ml-1">
                <ul
                  id="counter"
                  className="d-flex align-items-center justify-content-around "
                >
                  <li className="text-center">
                    <h3
                      className="counter-value green-text-style"
                      data-count="26335"
                    >
                      {request.totalPostCount}
                    </h3>
                    <h5 className="professional-style">Post</h5>
                  </li>
                  <li className="mx-3">
                    <hr
                      style={{ height: "30px", width: "1px", color: "#000000" }}
                    />
                  </li>
                  <li className="text-center ">
                    <h3
                      className="counter-value green-text-style"
                      data-count="546"
                    >
                      {request.totalFollowingCount}
                    </h3>
                    <h5 className="professional-style">Following</h5>
                  </li>
                  <li className="mx-3">
                    <hr
                      style={{ height: "30px", width: "1px", color: "#000000" }}
                    />
                  </li>
                  <li className="text-center">
                    <h3
                      className="counter-value green-text-style"
                      data-count="6845"
                    >
                      {request.totalFollowersCount}
                    </h3>
                    <h5 className="professional-style">Followers</h5>
                  </li>
                </ul>
              </div>
              <div className="ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center rem-mob-view">
                {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                <Button outline className="text-wrap" color="primary">
                  <UserPlus size={15} />
                  &nbsp;
                  <span
                    className="font-weight-bold"
                    onClick={() => handleFollowBack(request)}
                  >
                    {isFollowing ? "Following" : "Follow Back"}
                  </span>
                </Button>
                <div className="d-flex align-items-end flex-column justify-content-end">
                  <UncontrolledButtonDropdown
                    className="d-flex align-items-end flex-column justify-content-end px-4"
                    direction="left"
                  >
                    <DropdownToggle
                      color="light"
                      className="connection-cutomize-dropdown-toggle"
                    >
                      <MoreVertical size={22} />
                    </DropdownToggle>
                    <DropdownMenu className="Connection-dropdown">
                      <DropdownItem className="border-bottom py-2 menu-hover-style">
                        <UserPlus size={15} className="mr-2 icon-color-style" />
                        Follow Back
                      </DropdownItem>
                      <DropdownItem
                        className="border-bottom py-2 menu-hover-style"
                        onClick={() => {
                          toggleRemoveModalOpen(request);
                        }}
                      >
                        <MinusCircle
                          size={15}
                          className="mr-2 icon-color-style"
                        />
                        Remove Follower
                      </DropdownItem>
                      <DropdownItem
                        className="border-bottom py-2 menu-hover-style"
                        onClick={toggleShareOpen}
                      >
                        <Share size={15} className="mr-2 icon-color-style" />
                        Share Profile
                      </DropdownItem>
                      <DropdownItem className="border-bottom py-2 menu-hover-style">
                        <Copy size={15} className="mr-2 icon-color-style" />
                        Copy Profile URL
                      </DropdownItem>
                      <DropdownItem
                        className="border-bottom py-2 menu-hover-style"
                        onClick={() => {
                          toggleBlockModalOpen(request);
                        }}
                      >
                        <MinusCircle
                          size={15}
                          className="mr-2 icon-color-style"
                        />
                        Block
                      </DropdownItem>
                      <DropdownItem
                        className="py-2 menu-hover-style"
                        onClick={() => {
                          toggleReport(request);
                        }}
                      >
                        <Flag size={15} className="mr-2" />
                        Report User
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
                {/* </ul> */}
              </div>
            </div>
          );
        })}
      {getUserFollowers.length <= 0 && (
        <h3 className="text-center connection-sub-heading">
          No Followers Found
        </h3>
      )}
      {/*  ------------------ Add Report Option ------------------ */}

      {/*  ------------------ Add Report Option  ------------------ */}

      <ConnectionReportModal
        request={selectedRequest}
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
      />

      {/*  ------------------ Add CategoryModal Status  ------------------ */}

      <AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />

      {/*  ------------------ Remove Member Modal  ------------------ */}

      <RemoveModal
        request={selectedRequest}
        openModal={removeModalOpen}
        removeModalOpen={toggleRemoveModalOpen}
      />

      {/*------------------------- block modal----------------------- */}

      <BlockModal
        request={selectedRequest}
        openModal={blockModalOpen}
        removeModalOpen={toggleBlockModalOpen}
      />

      {/* ------------------ Start Share Modal  ------------------*/}
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
    </>
  );
};
