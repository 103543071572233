import React, { useEffect, useState } from 'react';
import { Edit3, MoreVertical, PlusCircle, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import AddUserModal from '../ConnectionModal/AddUserModal';
import DeleteModal from '../ConnectionModal/DeleteModal';
import ConnectionEditModal from '../ConnectionModal/ConnectionEditModal';
import '../Style.css'

export const CategoriesList = () => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();

    //  --------------- Edit Categories Option State --------------- 
    const [editCategories, setEditCategories] = useState(false)
    const toggleEditCategories = () => {
        setEditCategories((preState) => !preState)
    }

    //  --------------- Add Users Option State --------------- 
    const [addUserOptions, setAddUserOptions] = useState(false)
    const toggleAddUserOptions = () => {
        setAddUserOptions((preState) => !preState)
    }

    //--------------- Delete Categoris Modal State ---------------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }
    useEffect(() => {
        dispatch(loadAllUserFollowers())
    }, [])
    return (
        <>
            {
                getUserFollowers && getUserFollowers.rows?.slice(0, 8).map((request) => {
                    return <div className="connection-card box-shadow-style align-items-center content-mp-block nav-flex-container position-relative mx-2 p-4 mt-3">
                        <div className='nav-flex-container'>
                            <div>
                                <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '150px' }}>College Friends</h4>
                            </div>
                            <div className='mx-3'>
                                <MutualFriendList />
                            </div>
                            <div className="d-flex">
                                <p className='professional-style'>Creation Date</p>&nbsp;&nbsp;
                                <span className="green-text-style">31/12/2022</span>
                            </div>
                        </div>
                        <div className=' ml-sm-4 ml-xs-4'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <div className=''>
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddUserOptions}>
                                                <PlusCircle size={15} className='mr-2 icon-color-style' />Add SocioMates
                                            </DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleEditCategories}>
                                                <Edit3 size={15} className='mr-2 icon-color-style' />Edit Category
                                            </DropdownItem>
                                            <DropdownItem className='py-2 menu-hover-style' onClick={toggleDeleteModalOpen}>
                                                <Trash2 size={15} className='mr-2 icon-color-style' />Delete Category
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </ul>
                        </div>
                    </div>
                })
            }
            {
                getUserFollowers.length <= 0 && <h3 className='text-center connection-sub-heading'>No Followers Found</h3>
            }
            {/* ------------- Add Users Option Modal ------------- */}
            <AddUserModal
                openModal={addUserOptions}
                openAddUserModal={toggleAddUserOptions}
            />
            {/* ----------- Delete Categories Modal ----------- */}

            <DeleteModal
                openModal={deleteModalOpen}
                openDeleteModal={toggleDeleteModalOpen}
            />
            {/* ----------- Edit Categories Modal ----------- */}

            <ConnectionEditModal
                oepnModal={editCategories}
                editModal={toggleEditCategories}
            />
        </>
    )
}

