import React, { useEffect, useState } from "react";
import {
  Copy,
  Flag,
  Grid,
  List,
  MinusCircle,
  MoreVertical,
  PlusCircle,
  Search,
  Share,
  UserPlus,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { loadAllUserFollowers } from "../../../Services/Actions/Connection/getUserFollowersAction";
import { loadAllRemoveFollower } from "../../../Services/Actions/Connection/getRemoveFollowerAction";
import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import { getActionUserReported } from "../../../Services/Actions/Connection/getUserReportAction";
import BlockModal from "../ConnectionModal/BlockModal";
import LeftSidebar from "../../LeftSidebar";
import RightSidebar from "../../RightSidebar";
import ConnectionPlaceMenu from "./ConnectionPlaceMenu";
import { Button, InputGroup } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import { FollowersList } from "../ListView/FollowersList";
import { BlockUserModal } from "../HoverComponents/BlockUserModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import AddCategoryModal from "../ConnectionModal/AddCategoryModal";
import RemoveModal from "../ConnectionModal/RemoveModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import PublicAccount from "../ConnectionModal/PublicAccount";
import CommanHeader from "../CommanHeader";
import Header from "../../Header";
import "../Style.css";
import { NavLink } from "react-router-dom";

const FollowersScreen = () => {
  const [Gridview, Setgridview] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(15); // Number of items per page
  const [desiredPage, setDesiredPage] = useState(1);
  const { getUserFollowers } = useSelector(
    (state) => state.GetUserFollowersData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllUserFollowers());
  }, []);

  //search opearation
  const [userId, setUserId] = useState("");

  const [searchItem, setSearchItem] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);

  const filteredData = getUserFollowers?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );

  // ------------------ share Modal State ------------------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  
  

  //follow back
  const toggleFollowBack = (request) => {
    dispatch(loadAllFollowUnFollow(request));
  };

  const copyProfileURL = (userId) => {
    const profileURL = `${process.env.REACT_APP_API_URL}/user/getUserProfileById?id=${userId}`;

    navigator.clipboard
      .writeText(profileURL)
      .then(() => {
        console.log("Profile URL copied to clipboard:", profileURL);
        alert("copied profile url", profileURL);
      })
      .catch((error) => {
        console.error("Failed to copy profile URL:", error);
      });
  };

  // paigantion
  const followersPerPage = 2; // You can adjust this value as needed
  // const totalFollowers = getUserFollowers?.rows?.length;
  const totalPages = Math.ceil(getUserFollowers?.rows?.length  / followersPerPage);

const [currentPage, setCurrentPage] = useState(1);

const handlePageChange = (event, newPage) => {
  // Update pageIndex when a new page is selected
  setPageIndex(newPage - 1); // Subtract 1 to match 0-based indexing
};

  const currentPageData = filteredData?.slice(
    (currentPage - 1) * followersPerPage,
    currentPage * followersPerPage
  );

  // State to track follow status for each user
  const [followStatus, setFollowStatus] = useState({});

  const toggleFollowStatus = (userId) => {
    setFollowStatus((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  const handleFollowBack = (request) => {
    dispatch(loadAllFollowUnFollow(request));
    toggleFollowStatus(request); // Update follow status on successful follow
    
  };



 // ------------------ Remove Followers Modal State ------------------
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const toggleRemoveModalOpen = (request) => {
    setRemoveModalOpen((prevState) => !prevState)
    setSelectedRequest(request)
  }

  useEffect(() => {
    dispatch(loadAllRemoveFollower());
  }, []);

  //  ------------------= Start Add RelationShip State ------------------
  const [addCategoryOptions, setAddCategoryOptions] = useState(false);
  const toggleAddCategory = () => {
    setAddCategoryOptions((preState) => !preState);
  };

  //  ------------------ Start ReportedOption State  ------------------

  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
    setSelectedRequest(request)
  };

  //block user
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = (request) => {
    setBlockModalOpen((prevState) => !prevState)
    setSelectedRequest(request)
  }

  

  // Remove Follower
  

  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <Card>
            {/* comman header calling */}
            <CommanHeader />
            {/* <Header/> */}
            <CardBody>
              {/* connection tabs calling */}
              <ConnectionPlaceMenu />
              {/* ---------------MAIN--------------- */}
              <div className="mppage-heading mt-4">
                <div className="group-custom-block">
                  <div className="nav-flex-container">
                    <div>
                      <h3 className="connection-sub-heading">
                        Your Follower ({getUserFollowers?.rows?.length})
                      </h3>
                    </div>
                    <div className="sub-nav-flex size-sm-mt-4 mb-2">
                      <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                        <Search color="#666666" size={16} />
                        <Input
                          type="search"
                          placeholder="  Find..."
                          className="coonection-input-style"
                          value={searchItem}
                          onChange={(e) => setSearchItem(e.target.value)}
                        />
                      </InputGroup>
                      <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
                        <Button
                          className={
                            Gridview ? "grid-toggle-customize-css " : ""
                          }
                          outline
                          onClick={() => Setgridview(false)}
                        >
                          <Grid size={16} />
                        </Button>
                        <Button
                          className={
                            Gridview ? " " : "grid-toggle-customize-css"
                          }
                          outline
                          onClick={() => Setgridview(true)}
                        >
                          <List size={16} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {Gridview ? (
                    <FollowersList data={currentPageData} />
                  ) : (
                    <Row className="connections_PeopleThisGrid mt-2">
                      {filteredData &&
                        filteredData?.map((request, index) => {
                          return (
                            <Col lg="4" className="w-100">
                              <div
                                key={index}
                                className="position-relative connection-card  content-mp-block d-flex box-shadow-style justify-content-between"
                              >
                                <div className="media-body d-md-block">
                                  <div class="media media-new d-flex align-items-start">
                                    <div class="user-img d-flex align-items-start popover___wrapper ">
                                      <img
                                        src={
                                          request.profileImage ||
                                          `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`
                                        }
                                        className="rounded-circle "
                                        height={45}
                                        width={45}
                                        alt="profile"
                                      />
                                      <span class="available-stats online"></span>
                                      <PublicAccount request={request}/>
                                    </div>
                                    <div class="media-body d-md-block pl-1">
                                      <NavLink to="/MyProfile">
                                        <h4 className="m-0 text-wrap name-style d-inline-block text-truncate">
                                          {request.fullName?.substring(0, 21) ||
                                            "name"}
                                        </h4>
                                      </NavLink>
                                      <div className="d-flex my-1">
                                        <p className="user-name-style text-wrap rem-mob-view-small">
                                          @{request.userName || "userName"} |{" "}
                                        </p>
                                        <img
                                          src={india_svg}
                                          className="ml-1 rem-mob-view-small"
                                          alt="..."
                                        />
                                      </div>
                                      <p className="professional-style rem-mob-view">
                                        {request.profession || "No Profession"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end flex-column justify-content-between">
                                  <UncontrolledButtonDropdown
                                    className="d-flex align-items-start flex-column rem-mob-view-small"
                                    direction="left"
                                  >
                                    <DropdownToggle
                                      color="light"
                                      className="connection-cutomize-dropdown-toggle pb-0 pr-0"
                                    >
                                      <MoreVertical size={22} />
                                    </DropdownToggle>
                                    <DropdownMenu className="Connection-dropdown">
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() => {
                                          toggleFollowBack(request);
                                        }}
                                      >
                                        <UserPlus
                                          size={15}
                                          className="mr-2 icon-color-style"
                                        />
                                        Follow Back
                                      </DropdownItem>
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() =>
                                          toggleRemoveModalOpen(request)
                                        }
                                      >
                                        <MinusCircle
                                          size={15}
                                          className="mr-2 icon-color-style"
                                        />
                                        Remove Follower
                                      </DropdownItem>
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={toggleAddCategory}
                                      >
                                        <PlusCircle
                                          size={15}
                                          className="mr-2 icon-color-style"
                                        />
                                        Add to Category
                                      </DropdownItem>
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={toggleShareOpen}
                                      >
                                        <Share
                                          size={15}
                                          className="mr-2 icon-color-style"
                                        />
                                        Share Profile
                                      </DropdownItem>
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() =>
                                          copyProfileURL(request.div)
                                        }
                                      >
                                        <Copy
                                          size={15}
                                          className="mr-2 icon-color-style"
                                        />
                                        Copy Profile URL
                                      </DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style'  onClick={()=>{toggleBlockModalOpen(request)}}><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                      <DropdownItem
                                        className="py-2 menu-hover-style"
                                        onClick={() => {
                                          toggleReport(request);
                                        }}
                                      >
                                        <Flag size={15} className="mr-2" />
                                        Report User
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                  <div
                                    role="button"
                                    className="mt-3 mb-0 d-flex align-items-end"
                                  >
                                    <Button
                                      color={
                                        followStatus[request.id]
                                          ? "flat-primary"
                                          : "flat-success"
                                      } // Change color based on follow status
                                      className="btn-transparent p-0"
                                      onClick={() =>
                                        handleFollowBack(request)
                                      }
                                    >
                                      {followStatus[request.id] ? ( // Change button text based on follow status
                                        <>
                                          <UserPlus size={15} color="#81C14B" />
                                          &nbsp;
                                          <p className="font-weight-bold text-primary text-right">
                                            Following
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <UserPlus size={15} color="#81C14B" />
                                          &nbsp;
                                          <p className="font-weight-bold text-primary text-right">
                                            Follow Back
                                          </p>
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                  <Row>
                    <Col xl="12" className="d-flex justify-content-center">
                      {getUserFollowers?.length === 0 && (
                        <h3 className="text-center connection-sub-heading">
                          No Followers Found
                        </h3>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            {/*  ------------------ Pagination ------------------ */}
            <div className="d-flex justify-content-center my-3">
              <Stack spacing={2}>
              <Pagination
            count={totalPages}
            color="primary"
            size="large"
            page={currentPage}
            onChange={handlePageChange}
          />
              </Stack>
            </div>
          </Card>
        </div>
        <RightSidebar />
      </div>

      {/*  ------------------ Add Report Option  ------------------ */}

      <ConnectionReportModal
        request={selectedRequest}
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
      />

      {/*  ------------------ Add CategoryModal Status  ------------------ */}

      <AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />
      {/*  ------------------ Remove Member Modal  ------------------ */}

      <RemoveModal
        request={selectedRequest}
        openModal={removeModalOpen}
        removeModalOpen={toggleRemoveModalOpen}
      />
       {/*------------------------- block modal----------------------- */}

       <BlockModal
          request={selectedRequest}
          openModal={blockModalOpen}
          removeModalOpen={toggleBlockModalOpen}
        />

      {/* ------------------ Start Share Modal  ------------------*/}
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
    </>
  );
};

export default FollowersScreen;
