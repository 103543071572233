import { UilCommentMessage } from "@iconscout/react-unicons";
import TagIcon from "@mui/icons-material/Tag";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  ArrowLeft,
  ChevronDown,
  ChevronLeft,
  Edit,
  Globe,
  Image,
  MapPin,
  Smile,
  XCircle,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

import {
  createPost,
  uploadFile,
} from "../../Services/Actions/SocialFeed/createSocialpost";

import { loadProfileByUserId } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import CreateGoLive from "../golive-components/CreateGoLive";
import AlertPost from "./AllPostTypes/AlertPost";
import Articles from "./AllPostTypes/Articles";
import EventsPost from "./AllPostTypes/EventsPost";
import Poll from "./AllPostTypes/Poll";
import Recommendation from "./AllPostTypes/Recommendation";
import Throught from "./AllPostTypes/Throught";
import CircularStatic from "./CircularProgressWithLabel";
import Activity from "./Components/Activity";
import AllPostTypes from "./Components/AllPostTypes";
import CheckIn from "./Components/CheckIn";
import EditAllMedia from "./Components/EditAllMedia";
import Feeling from "./Components/Feeling";
import Hashtag from "./Components/Hashtag";
import Media from "./Components/Media";
import PostValidation from "./Components/PostValidation";
import SchedulePost from "./Components/SchedulePost";
import TagPeople from "./Components/TagPeople";
import { TextColor } from "./DummyArray";
import Emoji from "./Emoji";
import ColorPicker from "./Img/colorpicker.png";
import User from "./Img/US1.svg";
import { loadTaggedPeople } from "../../Services/Actions/Common/taggedPeopleAction";
import { loadAllTrendingHashtag } from "../../Services/Actions/Common/trandingHashtagAction";
import { loadAllUserPosts } from "../../Services/Actions/SocialFeed/getAllUserPostsAction";
import DiscaedModal from "./DiscaedModal";
import { useLocation } from "react-router-dom";

const CreationMainSection = ({
  userId:propsUsrId,
  states,
  currState,
  modal,
  toggle,
  activeTab,
  setModal,
  // toggleDiscard,
  postType,
  setPostType,
  selectedImages,
  setSelectedImages,
  feelingCategories,
  setEditModal,
  postEditValue
}) => {
  // console.log("in createsection ",  postEditValue)
  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const location = useLocation();

const userId=propsUsrId||user.id;
const global_active_tab=activeTab
const [val,setvals]=useState()
const [feelingSubChildCategoryId,setfeelingSubChildCategoryId]=useState("")
  // console.log("in creation section active tab",activeTab)
  // console.log("states in creationmainsection",states)
  // console.log("currState in creationmainsection",currState)
  const [newpostType,setnewpostType]=useState(postType)
  const [colorComment,setColorComment]=useState("")
  const [show, setShow] = useState(false);
  const [selectColorText, setSelectColorText] = useState("#000000");
  const handleChange = (value) => {
    setSelectColorText(value);
    console.warn(value);
  };
  const [textareaValue, setTextareaValue] = useState("");
  const [alertLevelData, setAlertLevelData] = useState([]);
  const [sliderValue, setSliderValue] = useState(700);
  const [selectedAlertLevelName, setSelectedAlertLevelName] = useState("Select Alert Level");
  const [pageIndex,setPageIndex]=useState(0);
  const [pageSize,setPageSize]=useState(10);
  const [searchKey,setSearchKey]=useState("")
  const [postButtonDisable, setPostButtonDisable] = useState(false)
  // const [selectedAlertLevel, setSelectedAlertLevel] = useState("");
  
  const [postPreview, setPostPreview] = useState(false);
  const [textData, setTextData] = useState((postEditValue && postEditValue.caption)?postEditValue.caption:"");
  
  const [comment, setComment] = useState(false);
  const [allowComment, setAllowComment] = useState(true);

  const Navigate = useNavigate();
  const [privacy, setprivacy] = useState("Public");
  const [isLoading, setIsLoading] = useState(false);

  const togglePrivacy = (prv) => {
    setprivacy(prv.target.value);
    toggle(`/modal/${prv.target.id}`);
    console.warn(prv.target.value);
  };
  const [mediaFile, setMediaFile] = useState([]);
  const [active, setActive] = useState("1");
  const toggleActivity = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  //state variables to hold Event data
  const [eventCategory, setEventCategory] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventCoverPhoto, setEventCoverPhoto] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventCategoryName,setEventCategoryName]=useState("")
  const [eventImage,setEventImage]=useState("")
  const [cachedDataActivity, setCachedDataActivity] = useState({});
  // State variables to hold poll data
  const [showpoll, setShowPoll] = useState(false);
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState([
    { optionText: "", sequence: 0 },
    { optionText: "", sequence: 1 },
 
    
  ]);
  // const date=new Date();
  // date.setDate(date.getDate()+7)
  // const [pollDurationStart, setPollDurationStart] = useState(new Date());
  // const [pollDurationEnd, setPollDurationEnd] = useState( date);
  const [pollDurationStart, setPollDurationStart] = useState();
  const [pollDurationEnd, setPollDurationEnd] = useState();

  //state variables to hold Image data
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);
  const [mediaPost, setMediaPost] = useState([]);

  //------------------feeling-----------------------------------------//
  const [feelingId, setFeelingId] = useState("");
  const [feelingCategoryId, setFeelingCategoryId] = useState(null);

  //   // get user profile by user id
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const { taggedPeople } = useSelector((state) => state.TaggedPeopleData);
  const { trandingHashtags } = useSelector(
    (state) => state.TrendingHashtagData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfileByUserId(userId));
    dispatch(loadAllTrendingHashtag());
    dispatch(loadTaggedPeople());
    dispatch(loadAllUserPosts(searchKey,pageIndex,pageSize));
  }, []);

  //state variables to hold Universal Tags data
  const [selectedMentionsList, setSelectedMentionList] = useState([]);
  const [selectedTagsList, sethandleSelectedTagsList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedScheduleDate, setSelectedScheduleDate] = useState("");

  //state variables to hold Universal Tags data
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [selectedAlertLevel, setSelectedAlertLevel] = useState(null);
  const [selectedAlertRange, setSelectedAlertRange] = useState(null);
  const [selectedAlertLocation, setSelectedAlertLocation] = useState(null);

  //state variables to hold Recommendation data
  const [recommendation, setRecommendation] = useState("I am seeking a recommendation for ");
  const [selectedFeeling, setSelectedFeeling] = useState([]);
  const [showSelectedFeeling, setShowSelectedFeeling] = useState(false);
  const [publicDropItems, setPublicDropItems] = useState([]);
  useEffect(()=>{
    let newValue=textData;
    newValue+=" ";

    let num=selectedTagsList.length
    for(let i=0;i<num;i++){
      newValue=newValue+" "+"#"+selectedTagsList[i] ;
    }
    // console.log(newValue)
    setTextData(newValue)
    
  },[selectedTagsList])


  useEffect(()=>{
    let newValue=recommendation;
    newValue+=" ";
    let num=selectedTagsList.length
    for(let i=0;i<num;i++){
      newValue=newValue+" "+"#"+selectedTagsList[i];
    }
    setRecommendation(newValue)
  },[selectedTagsList])


  useEffect(() => {
    const fetchPrivacyOptions = async () => {
      try {
        const requestBody = {
          searchKey: "",
          pageIndex: 0,
          pageSize: 5,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/user/getUserConnections`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
              "Content-Type": "application/json",
            },
          }
        );

        const items = response.data.data.successResult.rows;
        setPublicDropItems(items);
      } catch (error) {
        console.error("Error fetching privacy options:", error);
      }
    };

    fetchPrivacyOptions();
  }, []);



  // Callback function to update poll data
  const updatePollData = (question, options, durationStart, durationEnd) => {
    setPollQuestion(question);
    setPollOptions(options);
    setPollDurationStart(durationStart);
    setPollDurationEnd(durationEnd);
  };

  const handleImageSelect = async (event) => {
    setIsLoading(true);
    try {
      const files = Array.from(event.target.files);
      const temporaryURLs = files.map((file) => URL.createObjectURL(file));

      setUploadedImageURLs(temporaryURLs);
      // console.log("uploadedImage URLS ",uploadedImageURLs)

      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));
      formData.append("uploadFor", "postMedia");
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSelectedImages((prevImage)=>[...prevImage,response.data.data.successResult[0]]);
    } catch (error) {
      console.error("Error uploading media:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleEmojiSelect = (emoji) => {
    const updatedText = `${textData || ""}${emoji}`;
    setTextData(updatedText);
  };

  // Function to format tags with '#' and join them
  const formatTags = (tags) => {
    return tags.map((tag) => `#${tag}`).join(" ");
  };

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    setTextData(newValue);
    // Check for "@" to suggest tagging people
    if (newValue.includes("@")) {
      //show suggestion mapping this {taggedPeople}
    } else if (newValue.includes("#")) {
      //show suggestion mapping this {trandingHashtags}
    }
  };


  const hasEmptyPollOptions = pollOptions.some(option => option.optionText.trim() === "");


  const handleSubmit = async () => {
    try {
      // console.log("caption ka data  ",textData)
      navigator.geolocation.getCurrentPosition(
        async (geolocation) => {
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${geolocation.coords.latitude}&lon=${geolocation.coords.longitude}&zoom=18&addressdetails=1`;
          const res = await axios.get(apiUrl);
          const curr_address = res.data.display_name;
          const country = res.data.address.country;
          const state = res.data.address.state||res.data.address.city;
          // console.log(state)
          const city = res.data.address.county||res.data.address.city;
          // console.log(city)
          const postcode = res.data.address.postcode;

          let postData = {
            locationLAT: geolocation.coords.latitude,
            locationLONG: geolocation.coords.longitude,
            location1: country,
            location2: state,
            location3: city,
            displayLocation: selectedLocation,
            postcode: postcode,
            mentionIds: [],
            hashTags: selectedTagsList,
            taggedUserIds: selectedMentionsList,
            userConnectionListId: null,
            schedule: selectedScheduleDate,
            allowComments: allowComment,
            feelingId: feelingId,
            feelingCategoryId: feelingCategoryId,
            feelingSubChildCategoryId:feelingSubChildCategoryId
          };
          // Check the value of postType and update postData accordingly
          // console.log("in creationMainSection.js checking posttype of media ",postData)
          if (postType === "text") {
            postData = {
              ...postData,
              postType: "text",
              caption: textData,
              textColor: selectColorText,
            };
          } else if (postType === "media") {
            // console.log("caption ka data  ",textData)
            // setTextData(textData.trim())
            // console.log("a"+textData+"b")
            // console.log("in handelSubmit of post checking for selectedImages 222 ",selectedImages)
            const mediaLists=[]
            for(let i=0;i<selectedImages.length;i++){
              const array=selectedImages[i].split(".");
              const valueOfMedia=array[1];
              let typeOfMedia;
              // console.log("typeOfMededia   ",typeOfMedia)
              if(valueOfMedia==="mp4" || valueOfMedia==="mpg" || valueOfMedia==="mp2" || valueOfMedia==="mpeg" || valueOfMedia==="mpe" || valueOfMedia==="mpv" ||
              valueOfMedia==="ogg" || valueOfMedia==="m4p" || valueOfMedia==="m4v"){
                typeOfMedia="video"
              }else if(valueOfMedia==="png" || valueOfMedia==="jpg" || valueOfMedia==="jpeg" ||valueOfMedia==="gif" || valueOfMedia===".tiff"|| valueOfMedia==="psd"|| 
              valueOfMedia==="pdf" || valueOfMedia==="eps" || valueOfMedia==="ai" || valueOfMedia==="indd" || valueOfMedia==="raw"){
                typeOfMedia="image"
              }else if(valueOfMedia==="mp3"){
                typeOfMedia="audio"
              }
              const mediaListItem = {
                fileType: typeOfMedia,
                fileURL: selectedImages[i],
                caption: textData.trim(),
                sequence: 1,
              };
              mediaLists.push(mediaListItem)
            }
            
              // console.log("selected images in media post",selectedImages)
            postData = {
              ...postData,
              postType: "media",
              caption: textData.trim(),
              mediaList: mediaLists,
            };
          } else if (postType === "poll") {
            postData = {
              ...postData,
              postType: "poll",
              caption: pollQuestion,
              pollStartTime: pollDurationStart,
              pollEndTime: pollDurationEnd,
              pollOptions: pollOptions,
            };
          } else if (postType === "thought") {
            postData = {
              ...postData,
              postType: "thought",
              caption: textData,
              thoughtForeColor: selectColorTexts,
              thoughtBackColor: selectBckColor,
              thoughtText: textData,
            };
          } else if (postType === "alert") {
            postData = {
              ...postData,
              postType: "alert",
              caption: selectedAlert,
              alertLevelId: selectedAlertLevel,
              alertRangeMeter: selectedAlertRange,
            };
          } else if (postType === "event") {
            postData = {
              ...postData,
              postType: "event",
              caption: eventTitle,
              eventCoverImageURL: eventCoverPhoto,
              eventCategoryId: eventCategory,
              eventDescription: eventDescription,
              eventAddress: eventAddress,
              eventStartTime: eventStartDate,
              eventEndTime: eventEndDate,
            };
          } else if (postType === "article") {
            postData = {
              ...postData,
              postType: "article",
              caption: textData,
            };
          } else if (postType === "recomendation") {
            // console.log("I am in craatemaisection of postType please check ")
            postData = {
              ...postData,
              postType: "recomendation",
              caption: recommendation,
            };
          }
          setColorComment("")



        //dispatcher function for create post
        dispatch(createPost(postData));
          

          
          
          setModal(false);
          dispatch(loadAllUserPosts(searchKey,pageIndex,pageSize));
          setFeelingCategoryId("");
          setFeelingId("");
          setAllowComment(true);
          setComment(false);
          setTextData("");
          setSelectedImages([])
          setUploadedImageURLs([])
          setSelectedLocation("");
          sethandleSelectedTagsList([]);
          setSelectedMentionList([]);
          setSelectedScheduleDate("");
          setSelectedAlert(null);
          setSelectedAlertLevel(null);
          setSelectedAlertLocation(null);
          setSelectedAlertRange(null);
          setRecommendation("I am seeking a recommendation for");
          setvals()
          setSelectedAlertLevelName("Select Alert Level")
          setSliderValue(700)
          setAlertLevelData([])
          setTextareaValue("")
          setEventStartDate("")
          setEventEndDate("")
          setEventTitle("")
          setEventDescription("")
          setEventCategory("")
          setEventCoverPhoto("")
          setEventCategoryName("")
          setEventImage("")
          setEventAddress("")
          if(postEditValue){
            setEditModal(false)
          }
          //removing all the poll data after click
          setPollQuestion("")
          setPollDurationStart()
          setPollDurationEnd()
          setPostButtonDisable(false)
          setSelectColorTexts("#FFFFFF")
          setSelectBckColor("#91BC81")
          setPollOptions([
              { optionText: "", sequence: 0 },
              { optionText: "", sequence: 1 },])
        },
        (error) => {
          setColorComment("")
        }
      );
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const [discard, setDisCard] = useState(false);
  const toggleDiscard = () => {
    setDisCard((preState) => !preState);
    setColorComment("")
  };

  //DISCARDING ALL THE VALUES
  const toggleAllDiscard = () => {
    setTextData("");
    setSelectedImages([])
    setSelectedLocation("");
    sethandleSelectedTagsList([]);
    setSelectedMentionList([]);
    setSelectedScheduleDate("");
    setSelectedAlert(null);
    setSelectedAlertLevel(null);
    setSelectedAlertLocation(null);
    setSelectedAlertRange(null);
    setRecommendation("I am seeking a recommendation for");
    toggleDiscard(false);
    setFeelingCategoryId("");
    setFeelingId("");
    setAllowComment(true);
    setComment(false);
    setUploadedImageURLs([])
    setShowSelectedFeeling(false)
    setSelectedFeeling([])
    setvals()
    setSelectedAlertLevelName("Select Alert Level")
    setSliderValue(700)
    setAlertLevelData([])
    setTextareaValue("")
    setModal(false);
    setPostButtonDisable(false)
    if(setEditModal){
      setEditModal(false)
    }
       //removing all the poll data after click
       setPollQuestion("")
       setPollDurationStart()
       setPollDurationEnd()
       setPollOptions([
           { optionText: "", sequence: 0 },
           { optionText: "", sequence: 1 },])
       
           //removing thought post data    
      setSelectColorTexts("#FFFFFF")
      setSelectBckColor("#91BC81")
           
    

  };

  function clearSelectedImages(){
    setSelectedImages([])
    setUploadedImageURLs([])
  }

  //-------------------------------------------------------NEW CODE END-----------------------------------------------------------//
  let oNSpotElements = [
    0, 0.1, 0.2, 0.3, 7.1, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 7.1,
  ];
  let screenWithMedia = [0, 0.1, 0.3];

  // 0 to 0.3
  //  0  = modal and text
  // 0.1  = modal and media and text
  // 0.3  = modal , comment and text
  // 0.2  =
  // 7 = post type button
  // 7.1 - 7.10 for all the post type as per serial wise
  // {
  //     toggle("/modal/0.3");
  // }
  let oNSportFooters = [1, 3, 4, 5, 6, 7];

  let oNSportUniversalAction = [7.6, 7.7, 7.8];
  let ONSportPollUnviersalAction = [7.7, 7.8];
  // let OnFeelingActivitySpot[]
  let ONSportEventsUnviersalAction = [7.1,7.7, 7.3, 7.8,7.6,0.1];

  const toggleTheFile = (e) => {
    document.querySelector(".InputFile").click();
  };

  //=============== start_throught_post_state ====================//
  const [showBackGround, setShowBackGround] = useState(false);
  const [shows, setShows] = useState(false);
  const [selectColorTexts, setSelectColorTexts] = useState("#FFFFFF");
  const handleChanges = (value) => {
    setSelectColorTexts(value);
    console.warn(value);
  };
  const [selectBckColor, setSelectBckColor] = useState("#91BC81");
  const handleChangeBack = (value) => {
    setSelectBckColor(value);
    console.warn(value);
  };
  useEffect(()=>{},[activeTab])
  //=============== end_throught_post_state ====================//

  //================ end_creation_Tab_section ==================//

  //================ start_Feeling/Activity_Tab_section ==========//
  const FellingActivity = () => (
    <Fragment>
      <div className="border-bottom w-100">
        <div className={`mx-3 py-3  FellingActivity_main_blk `}>
          <div
            // onClick={() => {console.log("hello in onclick");toggle(`/modal/${activeTab - 1}`)}}
            // setps
            onClick={()=>{
              // console.log("i am in  click of feeling",newpostType,postType,states,val)
              setSelectedFeeling([])
              setShowSelectedFeeling(false)
              setFeelingId("")
              
              toggle(`/modal/${val}`)
             
            //   setnewpostType(postType)
              
            //   if(newpostType==="recomendation"){
            //   toggle(`/modal/${7.6}`);
            // }else if(newpostType==="thought"){
            //   toggle(`/modal/${7.1}`);
            // }else if(newpostType==="text"){
            //   toggle(`/modal/${0}`);
            // }
          }}
            className="Arrow_main_blk_section rounded-circle"
          >
            <ArrowLeft />
          </div>
          <div>
            <h3>How are you feeling</h3>
          </div>
          <div></div>
        </div>
      </div>
      <div className="mx-3">
        <Nav>
          <div className="d-flex gap-5">
            <NavItem>
              <NavLink
                active={active === "1"}
                onClick={() => {
                  toggleActivity("1");
                }}
                className="Member_link"
              >
                Feelings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={active === "2"}
                onClick={() => {
                  toggleActivity("2");
                }}
                className="Member_link"
              >
                Activities
              </NavLink>
            </NavItem>
          </div>
        </Nav>
      </div>
      <TabContent className="py-3" activeTab={active}>
        <TabPane tabId="1">
          <Feeling
            postEditValue={postEditValue}
            val={val}
            postType={postType}
            toggle={toggle}
            activeTab={activeTab}
            onSelectFeelingCategoryId={setFeelingCategoryId}
            onSelectFeelingId={setFeelingId}
            feelingCategories={feelingCategories}
            setSelectedFeeling={setSelectedFeeling}
            selectedFeeling={selectedFeeling}
            setShowSelectedFeeling={setShowSelectedFeeling}
            showSelectedFeeling={showSelectedFeeling}
          />
        </TabPane>
        <TabPane tabId="2">
          <Activity 
            val={val}
            postType={postType}
            setfeelingSubChildCategoryId={setfeelingSubChildCategoryId}
            toggle={toggle}
            activeTab={activeTab}
            onSelectFeelingCategoryId={setFeelingCategoryId}
            onSelectFeelingId={setFeelingId}
            feelingCategories={feelingCategories}
            setSelectedFeeling={setSelectedFeeling}
            selectedFeeling={selectedFeeling}
            setShowSelectedFeeling={setShowSelectedFeeling}
            showSelectedFeeling={showSelectedFeeling}
            cachedDataActivity={cachedDataActivity}
            setCachedDataActivity={setCachedDataActivity}
          />
        </TabPane>
      </TabContent>
    </Fragment>
  );
  //================ end_Feeling/Activity_Tab_section =============//

  //===============Main_retun_function===============//
  return (
    <Fragment>
      <DiscaedModal
        discard={discard}
        toggleDiscard={toggleDiscard}
        toggleAllDiscard={toggleAllDiscard}
      />
      {modal && (
        <Modal
          isOpen={modal}
          size={`${activeTab === 8 ? "xl" : "lg"}`}
          className="modal-dialog-centered creation_modal_blk"
        >
          <div
            onClick={() => {
              toggleDiscard();
            }}
            className="Modal_False_blk"
          >
            <XCircle color="#FFFFFF" />
          </div>

          <div
            className={`${oNSpotElements.includes(activeTab) ? "" : "d-none"}`}
          >
            {/* <ModalPostCreation /> */}
            <Card>
              <div className="border-bottom w-100">
                <div className="Creation_Header_section mx-3 py-3  FellingActivity_main_blk">
                  <div className="d-flex align-items-center">
                    <h3>Create Post</h3>
                    <Button
                      className="post_creation_btn  ml-3"
                      color="primary"
                      onClick={() => {
                        // console.log("create post clicked ")
                        toggle("/modal/7");
                        states=7;
                      }}
                    >
                      <Edit
                        id="CreatePostTooltip"
                        style={{ cursor: "pointer" }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="CreatePostTooltip"
                      >
                        Create Post
                      </UncontrolledTooltip>
                    </Button>
                  </div>
                  <div className="d-flex">
                    {/* <spam className="GoLive_section d-flex align-items-center" ><img width={20} src={LiveGif} alt="LiveGif1" />&nbsp;Go Live</spam> */}
                    <span
                      className=" d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // console.log("in privacy creation",currState)
                        let vals=postType
                        if(vals==="recomendation"){
                         setvals(7.6)
                        }else if(vals==="thought"){
                          setvals(7.1)
                        }else if(vals==="text"){
                          setvals(0);
                        }else if(vals==="media"){
                          setvals(0.1)
                        }else if(vals==="event"){
                          setvals(7.3)
                        }else if(vals==="alert"){
                          setvals(7.7)
                        }else if(vals==="poll"){
                          setvals(7.8)
                        }
                        
                        toggle("/modal/2");
                        // console.log("in privacy second one val is",val)

                      }}
                    >
                      <Globe color="#979797" size={16} />
                      &nbsp;{privacy}&nbsp;
                      <ChevronDown color="#979797" size={18} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="Creation_scroll_box">
                <div className="mx-3 mb-3 ">
                  {screenWithMedia.includes(activeTab) ? (
                    <Fragment>
                      <div
                        style={{
                          background: "#F6F6F6",
                          marginTop: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <InputGroupAddon addonType="prepend m-2">
                          <img
                            className="rounded-circle border"
                            width={45}
                            height={45}
                            src={userProfileByUserId.profileImage}
                            alt="userImg"
                          />
                          <h3
                            style={{
                              color: "#4D4D4D",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              margin: "8px",
                            }}
                          >
                            {userProfileByUserId.fullName}
                          </h3>
                        </InputGroupAddon>
                        <InputGroup className="input-group-merge">
                          <Input
                            type="textarea"
                            placeholder="What’s going on? #Hashtag... @Mention."
                            cols="15"
                            rows="3"
                            maxLength="5000"
                            value={textData}
                            onChange={handleTextChange}
                            style={{ color: selectColorText }}
                            className={
                              textData && textData.length < 60
                                ? "border-0 inputFortext inputForback actualArea"
                                : " border-0 inputFortext inputForback"
                            }
                          />

                          <Emoji onEmojiSelect={handleEmojiSelect} />
                        </InputGroup>
                        {/*============ start_Media_creation_section ==============*/}
                        <div className={`${activeTab === 0.1 ? "" : "d-none"}`}>
                          <div className="p-2 pb-2">
                            <Media
                              mediaPost={mediaPost}
                              setMediaPost={setMediaPost}
                              toggle={toggle}
                              handleImageSelect={handleImageSelect}
                              uploadedImageURLs={uploadedImageURLs}
                              selectedImages={selectedImages}
                              toggleTheFile={toggleTheFile}
                              isLoading={isLoading}
                              clearImages={clearSelectedImages}
                              postEditValue={postEditValue}
                            />
                          </div>
                        </div>
                        {/*============ end_Media_creation_section ==============*/}
                      </div>
                      <div className={`${activeTab === 0.1 ? "d-none" : ""}`}>
                        {!show ? (
                          <div className="Color_picker_actual_Blk">
                            <div
                              onClick={() => setShow(!show)}
                              style={{ cursor: "pointer" }}
                              className=" mt-3 "
                            >
                              <img src={ColorPicker} width={30} height={30} />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="d-flex justify-content-between align-items-center scrollbarnone mt-3 pb-2"
                            style={{
                              width: "calc(100% - (100% / 30px))",
                              overflowX: "auto",
                            }}
                          >
                            <div
                              onClick={() => setShow(!show)}
                              className="ColoPicker_actual_state"
                            >
                              <ChevronLeft size={30} />
                            </div>
                            {TextColor.map((color) => (
                              <div
                                className={
                                  selectColorText === color.text
                                    ? "Text_onChange_active_blk"
                                    : "Text_onChange_Unactive_blk "
                                }
                                active={selectColorText === color.text}
                              >
                                <div
                                  className="Text_onChange_section"
                                  style={{ background: color.text }}
                                  onClick={() => handleChange(color.text)}
                                ></div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ) : activeTab === 7.1 ? (
                    <Throught
                      selectColorTexts={selectColorTexts}
                      selectBckColor={selectBckColor}
                      shows={shows}
                      setShows={setShows}
                      handleChanges={handleChanges}
                      showBackGround={showBackGround}
                      setShowBackGround={setShowBackGround}
                      handleChangeBack={handleChangeBack}
                      textData={textData}
                      setTextData={setTextData}
                      postEditValue={postEditValue}
                    />
                  ) : activeTab === 7.3 ? (
                    <EventsPost
                      onEventCategoryChange={setEventCategory}
                      onEventTitleChange={setEventTitle}
                      onEventCoverPhotoChange={setEventCoverPhoto}
                      onEventDescriptionChange={setEventDescription}
                      onEventStartDateChange={setEventStartDate}
                      onEventEndDateChange={setEventEndDate}
                      onEventAddressChange={setEventAddress}
                      eventTitleMain={eventTitle}
                      eventDescriptionMain={eventDescription}
                      eventStartDateMain={eventStartDate}
                      eventEndDateMain={eventEndDate}
                      eventAddressMain={eventAddress}
                      eventCategoryMain={eventCategory}
                      setEventCategoryName={setEventCategoryName}
                      eventCategoryName={eventCategoryName}
                      eventImage={eventImage}
                      setEventImage={setEventImage}

                    />
                  ) : activeTab === 7.6 ? (
                    <Recommendation
                      currState={currState}
                      states={activeTab}
                      onRecommendationChange={setRecommendation}
                      postType={7.6}
                      recommendationValue={recommendation}
                      hashTagValue={selectedTagsList}
                      sethandleSelectedTagsList={sethandleSelectedTagsList}
                      postEditValue={postEditValue}
                    />
                  ) : activeTab === 7.7 ? (
                    <AlertPost
                      onSelectedAlert={setSelectedAlert}
                      onSelectAlertLevel={setSelectedAlertLevel}
                      selectedAlertLevels={selectedAlertLevel}
                      onSelectAlertRange={setSelectedAlertRange}
                      onSelectAlertLocation={setSelectedAlertLocation}
                      textareaValue={textareaValue}
                      setTextareaValue={setTextareaValue}
                      setAlertLevelData={setAlertLevelData}
                      alertLevelData={alertLevelData}
                      setSliderValue={setSliderValue}
                      sliderValue={sliderValue}
                      selectedAlertLevelName={selectedAlertLevelName}
                      setSelectedAlertLevelName={setSelectedAlertLevelName}
                      postEditValue={postEditValue}
                      
                    />
                  ) : activeTab === 7.8 ? (
                    <Poll
                      toggle={toggle}
                      showpoll={showpoll}
                      pollQuestion={pollQuestion}
                      options={pollOptions}
                      setOptions={setPollOptions}
                      pollDurationStart={pollDurationStart}
                      pollDurationEnd={pollDurationEnd}
                      onUpdatePollData={updatePollData}
                      postEditValue={postEditValue}
                      setPostButtonDisable = {setPostButtonDisable}
                    />
                  ) : null}
                </div>
              </div>
              {/*============== Start_creation_footer_section =================*/}
              {/* <div className="Main_creation_footer-section" > */}
              <CardFooter className="bg-white">
                <div className=" Creation_footer_section d-flex gap-2 ">
                  <Button
                    className={`NewActiviy_btn_PostBlk ${
                      ONSportEventsUnviersalAction.includes(activeTab)
                        ? "d-none"
                        : ""
                    }`}
                    onClick={() => {
                      toggle("/modal/0.1");
                    }}
                  >
                    <Image
                      id="PhotoVidoTooltip"
                      style={{ cursor: "pointer" }}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="PhotoVidoTooltip"
                    >
                      Photo/Video
                    </UncontrolledTooltip>
                  </Button>

                  {/* {!postPreview ? (
                    <Button
                      className={`NewActiviy_btn_PostBlk  ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/1");
                      }}
                    >
                      <Smile
                        id="FeelingTooltip"
                        style={{ cursor: "pointer" }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="FeelingTooltip"
                      >
                        Feeling/Activity
                      </UncontrolledTooltip>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={`Active_blk_btn btn-icon  ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/1");
                      }}
                    >
                      <Smile
                        id="FeelingTooltip"
                        style={{ cursor: "pointer" }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="FeelingTooltip"
                      >
                        Feeling/Activity
                      </UncontrolledTooltip>
                    </Button>
                  )} */}

                  {!postPreview ? (
                    <Button
                      className={`NewActiviy_btn_PostBlk  ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        // console.log("click on feeling/activity",postType)
                        // if(newpostType==="recomendation"){
                        //   toggle(`/modal/${7.6}`);
                        // }else if(newpostType==="thought"){
                        //   toggle(`/modal/${7.1}`);
                        // }else if(newpostType==="text"){
                        //   toggle(`/modal/${0}`);
                        // }
                        let val=postType
                        if(postType==="recomendation"){
                         setvals(7.6)
                        }else if(postType==="thought"){
                          setvals(7.1)
                        }else if(postType==="text"){
                          setvals(0);
                        }else if(postType==="media"){
                          setvals(0.1)
                        }else if(postType==="event"){
                          setvals(7.3)
                        }else if(postType==="alert"){
                          setvals(7.7)
                        }else if(postType==="poll"){
                          setvals(7.8)
                        }
                        toggle("/modal/1");
                        
                        // console.log("click on feeling/activity with states" ,val)

                      }}
                    >
                      <Smile
                        id="FeelingTooltip"
                        style={{
                          cursor: "pointer",
                          color: feelingId.length !== 0 ? "#81C14B" : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="FeelingTooltip"
                      >
                        Feeling/Activity
                      </UncontrolledTooltip>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={`Active_blk_btn btn-icon  ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {

                        toggle("/modal/1");
                      }}
                    >
                      <Smile
                        id="FeelingTooltip"
                        style={{
                          cursor: "pointer",
                          color: feelingId.length !== 0 ? "#81C14B" : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="FeelingTooltip"
                      >
                        Feeling/Activity
                      </UncontrolledTooltip>
                    </Button>
                  )}

                  {/* {!postPreview ? (
                    <Button
                      className={`NewActiviy_btn_PostBlk ${
                        activeTab === 7.8 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/6");
                      }}
                    >
                      <MapPin id="CheckTooltip" style={{ cursor: "pointer" }} />
                      <UncontrolledTooltip
                        placement="top"
                        target="CheckTooltip"
                      >
                        Check in
                      </UncontrolledTooltip>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={`Active_blk_btn btn-icon ${
                        activeTab === 7.8 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/6");
                      }}
                    >
                      <MapPin id="CheckTooltip" style={{ cursor: "pointer" }} />
                      <UncontrolledTooltip
                        placement="top"
                        target="CheckTooltip"
                      >
                        Check in
                      </UncontrolledTooltip>
                    </Button>
                  )} */}
                  {!postPreview ? (
                    <Button
                      className={`NewActiviy_btn_PostBlk ${
                        activeTab === 7.8 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        // console.log("here in clicked",postType)
                        setnewpostType(postType)
                        // console.log('in checkin ,',newpostType)
                        toggle("/modal/6");
                      }}
                    >
                      <MapPin
                        id="CheckTooltip"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedLocation.length !== 0
                              ? "#81C14B"
                              : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="CheckTooltip"
                      >
                        Check in
                      </UncontrolledTooltip>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={`Active_blk_btn btn-icon ${
                        activeTab === 7.8 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        setPostType(postType)
                        toggle("/modal/6");
                      }}
                    >
                      <MapPin
                        id="CheckTooltip"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedLocation.length !== 0
                              ? "#81C14B"
                              : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="CheckTooltip"
                      >
                        Check in
                      </UncontrolledTooltip>
                    </Button>
                  )}

                  {/* <div style={{ position: "relative" }}>
                    <Button
                      className={`NewActiviy_btn_PostBlk ${
                        activeTab === 7.7 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/5");
                      }}
                    >
                      <WatchLaterOutlinedIcon
                        id="PostTooltip"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedScheduleDate.length !== 0
                              ? "#81C14B"
                              : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip placement="top" target="PostTooltip">
                        Schedule Your Post
                      </UncontrolledTooltip>
                    </Button> */}

                    {/* <Button
                      className={`NewActiviy_btn_PostBlk ${
                        activeTab === 7.7 ? "d-none" : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/5");
                      }}
                    >
                      <WatchLaterOutlinedIcon id="PostTooltip" />
                      <UncontrolledTooltip placement="top" target="PostTooltip">
                        Schedule Your Post
                      </UncontrolledTooltip>
                    </Button> */}
                    {/* {postPreview ? (
                      <div className="Schedule_Post_Preview">12</div>
                    ) : null}
                  </div> */}

                  <div style={{ position: "relative" }}>
                    <Button
                      className={`NewActiviy_btn_PostBlk pr-0 ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        setnewpostType(postType)
                        toggle("/modal/3");
                      }}
                    >
                      <svg
                        id="PplTooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`addpost-option-btn pr-1 ${
                          selectedMentionsList.length !== 0 ? "filled-icon" : ""
                        }`}
                        width="25"
                        height="21"
                        viewBox="0 0 25 21"
                      >
                        <path
                          d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z"
                          fill={
                            selectedMentionsList.length !== 0
                              ? "#81C14B"
                              : "#A6A6A6"
                          }
                        />
                      </svg>
                      <UncontrolledTooltip placement="top" target="PplTooltip">
                        Mention people
                      </UncontrolledTooltip>
                    </Button>

                    {/* <Button
                      className={`NewActiviy_btn_PostBlk pr-0 ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/3");
                      }}
                    >
                      <svg
                        id="PplTooltip"
                        fill="#A6A6A6"
                        xmlns="http://www.w3.org/2000/svg"
                        className="addpost-option-btn pr-1"
                        width="25"
                        height="21"
                        viewBox="0 0 25 21"
                      >
                        <path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" />
                      </svg>
                      <UncontrolledTooltip placement="top" target="PplTooltip">
                        Tag people
                      </UncontrolledTooltip>
                    </Button> */}

                    {postPreview ? (
                      <div className="Schedule_Post_Preview">7</div>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      className={`NewActiviy_btn_PostBlk ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        setnewpostType(postType)
                        toggle("/modal/4");
                      }}
                    >
                      <TagIcon
                        id="HashTooltip"
                        style={{
                          cursor: "pointer",
                          fill:
                            selectedTagsList.length !== 0
                              ? "#81C14B"
                              : "#A6A6A6",
                        }}
                      />
                      <UncontrolledTooltip placement="top" target="HashTooltip">
                        Hashtag
                      </UncontrolledTooltip>
                    </Button>
                    {/* <Button
                      className={`NewActiviy_btn_PostBlk ${
                        ONSportPollUnviersalAction.includes(activeTab)
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        toggle("/modal/4");
                      }}
                    >
                      <TagIcon id="HashTooltip" />
                      <UncontrolledTooltip placement="top" target="HashTooltip">
                        Hashtag
                      </UncontrolledTooltip>
                    </Button> */}
                    {postPreview ? (
                      <div className="Schedule_Post_Preview">3</div>
                    ) : null}
                  </div>

                  {/* <Button
                    className={`NewActiviy_btn_PostBlk ${
                      oNSportUniversalAction.includes(activeTab) ? "d-none" : ""
                    }`}
                    onClick={() => {
                      setComment(!comment);
                    }}
                  >
                    <UilCommentMessage
                      id="CmntTooltip"
                      style={{
                        cursor: "pointer",
                        color: comment ? "#81C14B" : "##A6A6A6",
                      }}
                    />
                    <UncontrolledTooltip placement="top" target="CmntTooltip">
                      Comment
                    </UncontrolledTooltip>
                  </Button> */}

                    {postType !== "poll" && (
                      <Button
                        style={{ color: colorComment }}
                        className={`NewActiviy_btn_PostBlk ${oNSportUniversalAction.includes(activeTab) ? "" : ""}`}
                        onClick={() => {
                          setComment(!comment);
                          setColorComment(colorComment === "" ? "#81c14b" : "");
                        }}
                      >
                        <UilCommentMessage id="CmntTooltip" />
                        <UncontrolledTooltip placement="top" target="CmntTooltip">
                          Comments
                        </UncontrolledTooltip>
                      </Button>
                    )}

                </div>
              </CardFooter>
              {comment ? (
                <Fragment>
                  <CardFooter className="bg-white ">
                    <div className="d-flex justify-content-between align-items-center Creation_footer_section">
                      <h3>Allow Comment</h3>
                      <div className=" d-flex">
                        <div className="mr-2 BizSetting_msg_toggle py-1"  >
                          On
                        </div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() => {
                              
                              setAllowComment(!allowComment);
                              
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                        <div className="ml-2 BizSetting_msg_toggle py-1">
                          Off
                        </div>
                      </div>
                    </div>
                  </CardFooter>
                </Fragment>
              ) : null}
              {/*============== End_creation_footer_section =================*/}
            </Card>
          </div>
          
          
          
          
          
          <div className={`${activeTab === 1 ? "" : "d-none"}`}>
            <FellingActivity />
          </div>
          <div className={`${activeTab === 7 ? "" : "d-none"}`}>
            <AllPostTypes
              toggle={toggle}
              activeTab={activeTab}
              Navigate={Navigate}
            />
          </div>
          <div className={`${activeTab === 2 ? "" : "d-none"}`}>
            <PostValidation
              postType={newpostType}
              toggle={toggle}
              activeTab={activeTab}
              setprivacy={setprivacy}
              publicDropItems={publicDropItems}
            />
          </div>
          <div className={`${activeTab === 3 ? "" : "d-none"}`}>
            <TagPeople
              postType={newpostType}
              activeTab={activeTab}
              toggle={toggle}
              onSelectedMentionChange={setSelectedMentionList}
              postEditValue={postEditValue}
            />
          </div>
          <div className={`${activeTab === 4 ? "" : "d-none"}`}>
            <Hashtag
              postType={newpostType}
              activeTab={activeTab}
              toggle={toggle}
              onSelectedTagsChange={sethandleSelectedTagsList}
              postEditValue={postEditValue}
            />
          </div>
          <div className={`${activeTab === 5 ? "" : "d-none"}`}>
            <SchedulePost
              postType={newpostType}
              activeTab={activeTab}
              toggle={toggle}
              onScheduledPostDate={setSelectedScheduleDate}
            />
          </div>
          <div className={`${activeTab === 6 ? "" : "d-none"}`}>
            <CheckIn
              postType={newpostType}
              activeTab={activeTab}
              toggle={toggle}
              onLocationSelect={setSelectedLocation}
              postEditValue={postEditValue}
            />
          </div>
          <div className={`${activeTab === 8 ? "" : "d-none"}`}>
            <EditAllMedia
              activeTab={activeTab}
              toggle={toggle}
              selectedImages={selectedImages}
            />
          </div>





          {/*==================== Start_Footer_section =====================*/}

          <div
            className={`${oNSportFooters.includes(activeTab) ? "d-none" : ""}`}
          >
            <ModalFooter className="w-100">
              <div
                className={`w-100 ${
                  oNSpotElements.includes(activeTab) ? "" : "d-none"
                }`}
              >
                <Button
                  onClick={handleSubmit}
                  className="w-100"
                  color="primary"
                  
                  disabled={
                    (postType === "text" && (!textData || textData.length <= 1)) ||
                    (postType === "media" && ((!textData || textData.length <= 1) && (!selectedImages || selectedImages.length < 1))) ||
                    (postType === "poll" && (hasEmptyPollOptions || !pollQuestion || !pollDurationStart || !pollDurationEnd || postButtonDisable))
                  }
                >
                  Post
                </Button>
              </div>
              <div
                className={`w-100 d-flex justify-content-end ${
                  activeTab === 2 ? "" : "d-none"
                }`}
              >
                <Button onClick={toggleDiscard} outline className="w-25">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="w-25 ml-2"
                  onClick={() =>{toggle(`/modal/${val}`)}}
                >
                  Save
                </Button>
              </div>
              <div
                className={`w-100 d-flex justify-content-end ${
                  activeTab === 8 ? "" : "d-none"
                }`}
              >
                <Button color="primary" className="w-25 ml-2">
                  Done
                </Button>
              </div>
            </ModalFooter>
          </div>
          {/*==================== End_Footer_section =====================*/}
        </Modal>
      )}
    </Fragment>
  );
};

export default CreationMainSection;
