import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { GroupCard } from './GroupCard';
import { PeopleCard } from './PeopleCard';
import { AllRequestedUser } from './AllRequestedUser';
import '../Style.css';
import { useDispatch } from 'react-redux';
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';


export const FollowReqestTab = (props) => {
  const [active, setActive] = useState('1');
  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const dispatch = useDispatch();

  const handleAllSentRequest = (type) => {
    if (type === 'group') {
      // Dispatch an action for handling group requests
    } else if (type === 'people') {
      // Dispatch an action for handling people requests
    }
  };

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={active === '1'}
            onClick={() => {
              handleAllSentRequest('all'); // Send 'all' as type parameter
              toggle('1');
            }}
          >
            <h4> All (23) </h4>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '2'}
            onClick={() => {
              handleAllSentRequest('people'); // Send 'people' as type parameter
              toggle('2');
            }}
          >
            <h4> People (325) </h4>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '3'}
            onClick={() => {
              handleAllSentRequest('group'); // Send 'group' as type parameter
              toggle('3');
            }}
          >
            <h4> Group (65) </h4>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className='py-50' activeTab={active}>
        <TabPane tabId='1'>
          <AllRequestedUser type={props.type} />
        </TabPane>
        <TabPane tabId='2'>
          <PeopleCard />
        </TabPane>
        <TabPane tabId='3'>
          <GroupCard />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};
