import React, { useEffect, useState } from "react";
import {
  Check,
  Copy,
  Flag,
  Grid,
  List,
  MinusCircle,
  MoreVertical,
  PlusCircle,
  Search,
  Share,
  UserMinus,
  X,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { getActionBlockUnblock } from "../../../Services/Actions/Connection/getBlockUnblockAction";
import { loadAllUserSciomate } from "../../../Services/Actions/Connection/getSociomateAction";
import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import Header from "../../Header";
import LeftSidebar from "../../LeftSidebar";
import RightSidebar from "../../RightSidebar";
import ConnectionPlaceMenu from "./ConnectionPlaceMenu";
import { Button, InputGroup } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import { BlockUserModal } from "../HoverComponents/BlockUserModal";
import { SocioMateList } from "../ListView/SocioMateList";
import { NavLink } from "react-router-dom";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import RemoveModal from "../ConnectionModal/RemoveModal";
import AddCategoryModal from "../ConnectionModal/AddCategoryModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import UnfollowModal from "../ConnectionModal/UnfollowModal";
import PublicAccount from "../ConnectionModal/PublicAccount";
import CommanHeader from "../CommanHeader";
import BlockModal from "../ConnectionModal/BlockModal";
import "../Style.css";

const SocioMateScreens = () => {
  //const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
  const dispatch = useDispatch();

  const { getUserSociomate } = useSelector((state) => state.userSociomateData);
  const [selectedRequest, setSelectedRequest] = useState(null);

  //search opration
  const [searchItem, setSearchItem] = useState("");

  const filteredData = getUserSociomate?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );

  const [Gridview, Setgridview] = useState(false);

  // ------------- share Modal open -------------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };



  // ------------- Unfollow Modal State -------------

  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
  const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState)
    setSelectedRequest(request);
  }

  // ------------- Remove Followers Modal open -------------
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const toggleRemoveModalOpen = (request) => {
    setRemoveModalOpen((prevState) => !prevState)
    setSelectedRequest(request)
  }


  //  ------------- Start Add Category Status -------------
  const [addCategoryOptions, setAddCategoryOptions] = useState(false);
  const toggleAddCategory = () => {
    setAddCategoryOptions((preState) => !preState);
  };
  //  ------------- Start ReportedOption  -------------
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState)
    setSelectedRequest(request)

  }

  //get user block
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = (request) => {
    setBlockModalOpen((prevState) => !prevState)
    setSelectedRequest(request)
  }



  // copy ptofileUrl

  const copyProfileURL = (userId) => {
    const profileURL = `${process.env.REACT_APP_API_URL}/user/getUserProfileById?id=${userId}`;

    navigator.clipboard
      .writeText(profileURL)
      .then(() => {
        console.log("Profile URL copied to clipboard:", profileURL);
        alert("copied profile url", profileURL);
      })
      .catch((error) => {
        console.error("Failed to copy profile URL:", error);
      });
  };

  //pagination

  const followersPerPage = 15; // You can adjust this value as needed
  const totalFollowers = getUserSociomate?.rows?.length;
  const totalPages = Math.ceil(totalFollowers / followersPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const currentPageData = filteredData?.slice(
    (currentPage - 1) * followersPerPage,
    currentPage * followersPerPage
  );

  // mouse on hover components state
  const [mouseHover, setMouseHover] = useState(null);

  useEffect(() => {
    dispatch(loadAllUserSciomate());
  }, []);

  //console.log("here is your sociomate====>",getUserSociomate)

  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <Card>
            {/* Comman header calling */}
            <CommanHeader />
            <CardBody>
              {/* Connection tabs calling */}
              <ConnectionPlaceMenu />
              {/* MAIN */}
              <div className="mppage-heading mt-4">
                <div className="group-custom-block">
                  <div className="nav-flex-container">
                    <div>
                      <h3 className="connection-sub-heading">
                        SocioMate ({getUserSociomate?.rows?.length})
                      </h3>
                    </div>
                    <div className="sub-nav-flex size-sm-mt-4 mb-2">
                      <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                        <Search color="#666666" size={16} />
                        <Input
                          type="search"
                          placeholder="  Find..."
                          className="coonection-input-style"
                          value={searchItem}
                          onChange={(e) => setSearchItem(e.target.value)}
                        />
                      </InputGroup>
                      <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
                        <Button
                          className={
                            Gridview ? "grid-toggle-customize-css " : ""
                          }
                          outline
                          onClick={() => Setgridview(false)}
                        >
                          <Grid size={16} />
                        </Button>
                        <Button
                          className={
                            Gridview ? " " : "grid-toggle-customize-css"
                          }
                          outline
                          onClick={() => Setgridview(true)}
                        >
                          <List size={16} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {Gridview ? (
                    <SocioMateList />
                  ) : (
                    <Row className="connections_PeopleThisGrid mt-2">
                      {filteredData &&
                        filteredData?.slice(0, 15).map((request, index) => {
                          return (
                            <Col lg="4" className="w-100">
                              <div
                                key={index}
                                className="connection-card  box-shadow-style content-mp-block d-flex justify-content-between"
                              >
                                <div className="media-body d-md-block">
                                  <div class="media media-new d-flex align-items-start">
                                    <div class="user-img d-flex align-items-start popover___wrapper ">
                                      <img
                                        src={
                                          request.profileImage ||
                                          `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`
                                        }
                                        className="rounded-circle "
                                        height={45}
                                        width={45}
                                        alt="shivam singh"
                                      />
                                      <span class="available-stats online"></span>
                                      <PublicAccount request={request} />
                                    </div>
                                    <div class="media-body d-md-block pl-1">
                                      <NavLink to="/MyProfile">
                                        <h4 className="m-0 name-style d-inline-block text-truncate text-wrap">
                                          {request.fullName?.substring(0, 21) ||
                                            "name"}
                                        </h4>
                                      </NavLink>
                                      <div className="d-flex">
                                        <p className="user-name-style rem-mob-view-small">
                                          {request.userName} |{" "}
                                        </p>
                                        <span>
                                          <img
                                            src={request.countryData[0].iconURL}
                                            className="ml-1 rem-mob-view-small"
                                            alt="..."
                                            width={19}
                                            height={13}
                                          />
                                        </span>
                                      </div>
                                      <h6 className="professional-style my-1 rem-mob-view-small">
                                        {request.profession || "No Profession"}
                                      </h6>
                                      <NavLink
                                        to="/ConnectionMenuScreen"
                                        className="d-flex justify-content-between"
                                      >
                                        <span className="green-text-style">
                                          My colleagues
                                        </span>
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end flex-column">
                                  <UncontrolledButtonDropdown
                                    className="d-flex align-items-start flex-column justify-content-end pr-0 "
                                    direction="left"
                                  >
                                    <DropdownToggle
                                      color="light"
                                      className="connection-cutomize-dropdown-toggle pb-0 pr-0"
                                    >
                                      <MoreVertical
                                        size={22}
                                        className="pr-0"
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu className='Connection-dropdown'>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleUnfollowModal(request) }}><UserMinus size={15} className='mr-2' />Unfollow</DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleRemoveModalOpen(request) }} ><MinusCircle size={15} className='mr-2' />Remove Follower</DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddCategory}><PlusCircle size={15} className='mr-2' />Add to Category</DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                      <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={() => { toggleBlockModalOpen(request) }}><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                      <DropdownItem className='py-2 menu-hover-style' onClick={() => { toggleReport(request) }}>
                                        <Flag size={15} className='mr-2' />Report User
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                  <div
                                    role="button"
                                    className="mt-4 d-flex align-items-end"
                                    onClick={() =>
                                      toggleUnfollowModal(request)
                                    }
                                  >
                                    <Button
                                      color="flat-success"
                                      className="btn-transparent mt-3 pb-0 pr-0"
                                    >
                                      <Check size={15} color="#666666" />
                                      &nbsp;{" "}
                                      <p className="font-weight-bold text-right">
                                        Following
                                      </p>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                  <Row>
                    <Col xl="12" className="d-flex justify-content-center">
                      {getUserSociomate.length <= 0 && (
                        <h3 className="text-center connection-sub-heading">
                          No Sociomate Found
                        </h3>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            {/*  ------------------ Pagination ------------------ */}
            <div className="d-flex justify-content-center my-3">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  color="primary"
                  size="large"
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </Card>
        </div>
        <RightSidebar />
      </div>
      {/*  ------------------ Add Report Option  ------------------ */}
      <ConnectionReportModal
        request={selectedRequest}
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
      />
      {/*   ----------- Add CategoryModal Status ----------- */}
      <AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />
      {/* ----------- Remove SocioMate Modal ----------- */}
      <RemoveModal
        request={selectedRequest}
        openModal={removeModalOpen}
        removeModalOpen={toggleRemoveModalOpen}
      />
      {/*------------------------- block modal----------------------- */}

      <BlockModal
        request={selectedRequest}
        openModal={blockModalOpen}
        removeModalOpen={toggleBlockModalOpen}
      />
      {/* ----------- Start Share Modal -----------*/}
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
      {/* ------------- Unfollow Modal Modal -------------*/}
      <UnfollowModal
        request={selectedRequest}
        openModal={unfollowModalOpen}
        unfollowModalOpen={toggleUnfollowModal}
      />
    </>
  );
};
export default SocioMateScreens;

