import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { loadAllFeelingCategory } from "../../../Services/Actions/Common/getFeelingCategoryAction";
import { loadAllFeeling } from "../../../Services/Actions/Common/getFeelingAction";
import { useDispatch, useSelector } from "react-redux";

const Feeling = ({postType,val, activeTab,toggle,onSelectFeelingCategoryId, onSelectFeelingId , feelingCategories,selectedFeeling, setSelectedFeeling,showSelectedFeeling, setShowSelectedFeeling,postEditValue }) => {
  console.log(postEditValue," in feelijng")
  // const [currType,setCurrType]=useState(postType)
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [feelingCategoryId, setFeelingCategoryId] = useState("");
  // const [selectedFeeling, setSelectedFeeling] = useState([]);
  const [feelingId, setFeelingId] = useState("");
  // const [showSelectedFeeling, setShowSelectedFeeling] = useState(false);

  // const toggle = (tab) => {
  //   if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  // };
  useEffect(()=>{

  },[showSelectedFeeling ,selectedFeeling])

  const dispatch = useDispatch();
  const { feeling } = useSelector((state) => state.FeelingData);


  const handleFeelingClick = async (feelingId) => {
  
    try {
      const body = {
        categoryId:"3a911f87-cc05-4ce4-a979-4f92d80a50f5",
        searchKey: "",
        pageIndex: "",
        pageSize: "",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/getFeelings`,
        body,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
          },
        }
      );
      console.log("checking feeling  ",response.data.data.successResult.rows)
     if(response.success===true){
      console.log(true," true occured")
     }
      // console.log(showSelectedFeeling)
      // setSelectedFeeling((prev)=>[prev,response.data.data.successResult.rows])
      setSelectedFeeling(response.data.data.successResult.rows);
      setShowSelectedFeeling(true);
      onSelectFeelingCategoryId(feelingId)
      console.log("checking feeling  ",showSelectedFeeling,selectedFeeling)
    } catch (error) {
      console.error("Error fetching feeling:", error);
    }
  };
  
  const handleFeeling=(id)=>{

    console.log(postType,val)
    setShowSelectedFeeling(false)
    onSelectFeelingId(id)

    if(val===0){
      postType="text";
    }else if(val===7.6){
      postType="recomendation";
    }else if(val===0.1){
      postType="media";
    }else if(val===7.1){
      postType="thought";
    }else if(val===7.3){
      postType="event"
    }
    
    if(postType==="recomendation"){
      toggle(`/modal/${7.6}`);
    }else if(postType==="thought"){
      toggle(`/modal/${7.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==='media'){
      toggle(`/modal/${0.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==="event"){
      toggle(`/modal/${7.3}`);
    }
    // setFeelingId(id)
  }



  return (
    <Fragment>
      {/* Conditionally render the Feeling categories or the selected feeling */}
      {showSelectedFeeling===false ? (
        
        <div className="Feeling_scroll_box">
          <div className="mx-3">
            <Row>
              {feelingCategories?.length > 0 &&
                feelingCategories.map((item) => (
                  <Col md={4} sm={6} className="mb-3" key={item.id}>
                    <div
                      className={
                        currentActiveTab === item.id
                          ? "Feeling_Main_blk_section"
                          : "Feeling_Main_blk_Subsection"
                      }
                      onClick={() => {
                        console.log(item);
                        handleFeelingClick(item.id);
                      }}
                    >
                      <div className="">
                        <img
                          src={item.iconURL}
                          alt="emoji"
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <h4 className="ml-3">{item.name}</h4>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      ) : (
        // Render the SelectedFeeling component when a feeling is selected
        <div className="Feeling_scroll_box">
          <div className="mx-3">
            <Row>
              {selectedFeeling.length > 0 &&
                selectedFeeling.map((item) => (
                  <Col md={4} sm={6} className="mb-3" key={item.id}>
                    <div
                      className="Feeling_Main_blk_Subsection"
                      onClick={() => {
                        console.log(postType,"postType in feelings")
                        // postType="media"
                        console.log(item);
                      handleFeeling(item.id);
                      }}
                    >
                      <div className="">
                        <img
                          src={item.iconURL}
                          alt="emoji"
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <h4 className="ml-3">{item.name}</h4>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Feeling;


// import React, { useState, useEffect } from 'react';
// import { Fragment } from 'react';
// import { Col, Row } from 'reactstrap';
// import axios from 'axios';
// import { loadAllFeelingCategory } from '../../../Services/Actions/Common/getFeelingCategoryAction';
// import { useDispatch, useSelector } from 'react-redux';

// const Feeling = ({ onSelectFeeling }) => {
//   const [currentActiveTab, setCurrentActiveTab] = useState('1');
//   const [feelingCategories, setFeelingCategories] = useState([]);
//   const [selectedFeeling, setSelectedFeeling] = useState(null);

//   const toggle = (tab) => {
//     if (currentActiveTab !== tab) setCurrentActiveTab(tab);
//   };

//   // Function to fetch feeling categories
//   // const fetchFeelingCategories = async () => {
//   //   try {
//   //     const body = {};
//   //     const response = await axios.post(
//   //       `${process.env.REACT_APP_IPURL}/admin/getFeelingCategories`,
//   //       body,
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
//   //         },
//   //       }
//   //     );

//   //     setFeelingCategories(response.data.data.successResult.rows);
//   //   } catch (error) {
//   //     console.error('Error fetching feeling categories:', error);
//   //   }
//   //  };

//   // Use useEffect to fetch feeling categories when the component mounts
//   const dispatch = useDispatch();
//   const { feelingCategory } = useSelector((state) => state.FeelingCategoryData);

//   useEffect(() => {
//     dispatch(loadAllFeelingCategory());
//     setFeelingCategories(feelingCategory);
//   }, [dispatch]);

//   // Handle feeling click
//   const handleFeelingClick =  async(feelingId) => {
//         try {
//             const body = {
//                 categoryId: feelingId,
//                 searchKey: "",
//                 pageIndex: "",
//                 pageSize: ""
//               };

//           const response = await axios.post(
//             `${process.env.REACT_APP_IPURL}/admin/getFeelings`,
//             body,
//             {
//               headers: {
//                 Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
//               },
//             }
//           );
//           console.log(response.data.data.successResult.rows);
//           setSelectedFeeling(response.data.data.successResult.rows);
//         } catch (error) {
//           console.error('Error fetching feeling categories:', error);
//         }
//       };

//   return (
//     <Fragment>
//       {/* ... your search input code ... */}
//       <div className='Feeling_scroll_box'>
//         <div className='mx-3'>
//           <Row>
//             {feelingCategories.length > 0 &&
//               feelingCategories.map((item) => (
//                 <Col md={4} sm={6} className="mb-3" key={item.id}>
//                   <div
//                     className={currentActiveTab === item.id ? 'Feeling_Main_blk_section' : 'Feeling_Main_blk_Subsection'}
//                     onClick={() => {handleFeelingClick(item.id); console.log(item.id)}}
//                   >
//                     <div className=''>
//                       <img src={item.iconURL} alt="emoji" width="50px" height="50px" />
//                     </div>
//                     <h4 className='ml-3'>{item.name}</h4>
//                   </div>
//                 </Col>
//               ))}
//           </Row>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default Feeling;
