import Happy from './Img/happy.svg'
import Loved from './Img/loved.svg'
import Sad from './Img/sad.svg'
import Excited from './Img/excited.svg'
import Celeb from './Img/celebration.svg'
import Eat from './Img/eat.svg'
import Drink from './Img/drink.svg'
import Watch from './Img/watch.svg'
import Frnd from './Img/frndship.svg'
import Brth from './Img/birthday.svg'
import Spcl from './Img/spcl.svg'
import Chrms from './Img/chrms.svg'
import { Globe, Lock, User, Users } from 'react-feather'
import PenIcon from "../../NewComponents/IMG/PenIcon.svg"
import Throught from '../../NewComponents/IMG/ThroughtIcon.svg'
import GoLive from '../../NewComponents/IMG/GoLiveIcon.svg'
import Podcast from '../../NewComponents/IMG/PodcastIconS.svg'
import Articles from '../../NewComponents/IMG/ArticleIcon.svg'
import Recommendation from '../../NewComponents/IMG/RecommendationIcon.svg'
import Alert from '../../NewComponents/IMG/ThreatIcon.svg'
import Poll from '../../NewComponents/IMG/BPollIcon.svg'
import Sell from '../../NewComponents/IMG/SellIcon.svg'
import Event from '../../NewComponents/IMG/EventIcon.svg'
import UserImg from '../../NewComponents/IMG/img.svg'
import usr1 from './Img/usr1.svg'
import usr2 from './Img/usr2.svg'
import usr3 from './Img/usr3.svg'

export const PublicDropItem = [
    {
        id: 2,
        privacy: "Public",
        desp: "Anyone on or off Sociomee",
        icon: <Globe color=' #808080' size={16} />,
    },
    {
        id: 3,
        privacy: "Friends",
        desp: "Your Friends on Sociomee",
        icon: <Users color=' #808080' size={16} />,
    },
    {
        id: 4,
        privacy: "Friends Except",
        desp: "Don't show to some Sociomee Friends",
        icon: <Users color=' #808080' size={16} />,
    },
    {
        id: 5,
        privacy: "Specific Friends",
        desp: "Only show to some Sociomate",
        icon: <User color=' #808080' size={16} />,
    },
    {
        id: 6,
        privacy: "Only Me",
        icon: <Lock color=' #808080' size={16} />,
    }
]
export const FellingData = [
    {
        id: "1",
        img: Sad,
        name: "Happy"
    },
    {
        id: "2",
        img: Loved,
        name: "Loved"
    },
    {
        id: "3",
        img: Happy,
        name: "Sad"
    },
    {
        id: "4",
        img: Excited,
        name: "Excited"
    },
    {
        id: "5",
        img: Sad,
        name: "Happy"
    },
    {
        id: "6",
        img: Loved,
        name: "Loved"
    },
    {
        id: "7",
        img: Happy,
        name: "Sad"
    },
    {
        id: "8",
        img: Excited,
        name: "Excited"
    },
    {
        id: "9",
        img: Sad,
        name: "Happy"
    },
    {
        id: "10",
        img: Loved,
        name: "Loved"
    },
    {
        id: "11",
        img: Happy,
        name: "Sad"
    },
    {
        id: "12",
        img: Excited,
        name: "Excited"
    },
    {
        id: "13",
        img: Sad,
        name: "Happy"
    },
    {
        id: "14",
        img: Loved,
        name: "Loved"
    },
    {
        id: "15",
        img: Happy,
        name: "Sad"
    },
    {
        id: "16",
        img: Excited,
        name: "Excited"
    }

]

export const ActivityData = [
    {
        id: "1",
        name: "Celebrating",
        img: Celeb
    },
    {
        id: "2",
        name: "Eating",
        img: Eat
    },
    {
        id: "3",
        name: "Drinking",
        img: Drink
    },
    {
        id: "4",
        name: "Watching",
        img: Watch
    },
]

export const CelebratingData = [
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Birthday",
        img: Brth
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Your special day",
        img: Spcl
    },
    {
        name: "Christmas",
        img: Chrms
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Birthday",
        img: Brth
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Your special day",
        img: Spcl
    },
    {
        name: "Christmas",
        img: Chrms
    },
]

export const AllPostTypeData = [
    {
        id: '0',
        img: PenIcon,
        postType: 'Text',
        tab: true
    },
    {
        id: '7.1',
        img: Throught,
        postType: 'Thought',
        tab: true

    },
    {
        id: '7.2',
        img: GoLive,
        postType: 'GoLive',
        tab: false
    },
    {
        id: '7.3',
        img: Event,
        postType: 'Event',
        tab: true
    },
    {
        id: '7.4',
        img: Podcast,
        postType: 'Podcast',
        tab: false
    },
    {
        id: '7.5',
        img: Articles,
        postType: 'Articles',
        tab: false
    },
    {
        id: '7.6',
        img: Recommendation,
        postType: 'Recommendation',
        tab: true
    },
    {
        id: '7.7',
        img: Alert,
        postType: 'Alert',
        tab: true
    },
    {
        id: '7.8',
        img: Poll,
        postType: 'Poll',
        tab: true
    },
    {
        id: '7.9',
        img: Sell,
        postType: 'Sell',
        tab: false
    },
]

export const TagSuggestData = [
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    }
]


export const HashTagData = [
    {
        hashName: "#NewDay",
        usedPpl: "used in 254 post"
    },
    {
        hashName: "#NewMe",
        usedPpl: "used in 250 post"
    },
    {
        hashName: "#LoveMe",
        usedPpl: "used in 112 post"
    },
    {
        hashName: "#EarthQuake",
        usedPpl: "used in 242 post"
    },
    {
        hashName: "#HateYou",
        usedPpl: "used in 214 post"
    },
    {
        hashName: "#DodoMee",
        usedPpl: "used in 444 post"
    },
    {
        hashName: "#NewDay",
        usedPpl: "used in 254 post"
    },
    {
        hashName: "#NewMe",
        usedPpl: "used in 250 post"
    },
    {
        hashName: "#LoveMe",
        usedPpl: "used in 112 post"
    },
    {
        hashName: "#EarthQuake",
        usedPpl: "used in 242 post"
    },
    {
        hashName: "#HateYou",
        usedPpl: "used in 214 post"
    },
    {
        hashName: "#DodoMee",
        usedPpl: "used in 444 post"
    }
]

export const CheckInData = [
    {
        location: "8502 Preston Rd. Inglewood, Maine 98380"
    },
    {
        location: "2715 Ash Dr. San Jose, South Dakota 83475"
    },
    {
        location: "2972 Westheimer Rd. Santa Ana, Illinois 85486 "
    },
    {
        location: "4140 Parker Rd. Allentown, New Mexico 31134"
    },
    {
        location: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        location: "3891 Ranchview Dr. Richardson, California 62639"
    },
    {
        location: "8502 Preston Rd. Inglewood, Maine 98380"
    },
    {
        location: "2715 Ash Dr. San Jose, South Dakota 83475"
    },
    {
        location: "2972 Westheimer Rd. Santa Ana, Illinois 85486 "
    },
    {
        location: "4140 Parker Rd. Allentown, New Mexico 31134"
    },
    {
        location: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        location: "3891 Ranchview Dr. Richardson, California 62639"
    }
]

export const ExceptFrndData = [

    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    }

]


export const TextColor = [
    {
        id: '1',
        text: '#FB6E6E'
    },
    {
        id: '2',
        text: '#A8FFAC'
    },
    {
        id: '3',
        text: '#7DC1FF'
    },
    {
        id: '4',
        text: '#A49494'
    },
    {
        id: '5',
        text: '#FD98FF'
    },
    {
        id: '6',
        text: '#F8DF88'
    },
    {
        id: '7',
        text: '#60715C'
    },
    {
        id: '8',
        text: '#353535'
    },
    {
        id: '9',
        text: '#FFFFFF'
    },
    {
        id: '10',
        text: '#FFA800'
    },
    {
        id: '11',
        text: '#622727'
    },
    {
        id: '12',
        text: '#91BC81'
    },
    {
        id: '13',
        text: '#50466C'
    },

]