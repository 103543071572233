import * as types from "../../Constants/Connection/index";
import axios from "axios";

const getAllUserFollowUnfollow = (getUserFollowUnfollow) => ({
  type: types.GET_FOLLOW_UNFOLLOW,
  payload: getUserFollowUnfollow,
});
// get all biz category
export const loadAllFollowUnFollow = (request) => {
  
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
          {
            followingToId: request.id,
            isFollowed: true,
            isPrivate: request.isPrivate, //true or false according to user account
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          //console.log("follower=====>", res.data.data.successResult);
          dispatch(getAllUserFollowUnfollow(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const loadUnfollow = ( request ) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
          {
            followingToId: request.id,
            isFollowed: false,
            isPrivate: request.isPrivate, //true or false according to user account
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          console.log("follower=====>", res.data.data.successResult);
          dispatch(getAllUserFollowUnfollow(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
