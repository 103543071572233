import React from 'react'
import { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loadProfileByUserId } from '../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { useEffect } from 'react';
import { Check } from 'react-feather';
import { Button } from 'reactstrap';

const ProfileCard = () => {

    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const { userProfileByUserId } = useSelector(state => state.getUserProfileByUserIdData);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadProfileByUserId(user.id));
    }, [])

    //=================== Main_return_function =========================//
    return (
        <Fragment>
            <div className="profile-box profileCard_box section-b-space groupBottom-custom-block">
                <div className="profile-content pt-1">
                    <Link to="/MyMedia" className="image-section">
                        <div className="profile-img">
                            <div>
                                <img src={userProfileByUserId.profileImage}
                                    className="img-fluid bg-img" alt={userProfileByUserId?.fullName} />
                            </div>
                            <span className="stats">
                                {/* <img src="/assets/images/verified-1.png"
                                    className="img-fluid" alt="verified" /> */}
                                <Check strokeWidth={4} size={14} />
                            </span>
                        </div>
                    </Link>
                    <div className="profile-detail text-center">
                        <h2 style={{ lineHeight: '21px', fontWeight: "500" }} className=''>{userProfileByUserId.fullName}</h2>
                        <div className='pt-2'>
                            {/* <h4 >Student</h4> */}
                            <h5 style={{ lineHeight: '18px', fontWeight: "500", fontSize: "14px" }} className='text-muted'>{userProfileByUserId.userName}</h5>
                            <p style={{ lineHeight: '18px', fontWeight: "500", fontSize: "14px" }} className='text-primary'>{userProfileByUserId.homeAddress || "Lucknow" }</p>
                        </div>
                        <div className="counter-stats mt-3">
                            <ul id="counter">
                                <li className='profileForCount'>
                                    <h3 className="mb-0" data-count="546">{userProfileByUserId.followingCount}</h3>
                                    <h5>Following</h5>
                                </li>
                                <li className='profileForCount'>
                                    <h3 className="mb-0" data-count="26335">{userProfileByUserId.totalPostCount}</h3>
                                    <h5>Posts</h5>
                                </li>
                                <li className='profileForCount'>
                                    <h3 className="mb-0" data-count="6845">{userProfileByUserId.followersCount}</h3>
                                    <h5>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <Link to={`/MyProfile?userId=${user.id}&type=creater`}>
                        <div className='d-flex justify-content-center mb-2'>
                            <button className="BizBtn_Fill_section  btn btn-primary">View Profile</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ProfileCard
