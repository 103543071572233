import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getUserDispproveFollowRequest } from '../../../Services/Actions/Connection/getUserApproveDisapprove';
import { useDispatch } from 'react-redux';

export const RejectModal = ({request, openModal, rejectModalOpen }) => {

    const dispatch = useDispatch();

    const handleReject =(request) =>{
        dispatch(getUserDispproveFollowRequest(request))
        rejectModalOpen();
        setTimeout(() => {
            window.location.reload();
          }, 2000);
      }
    return (
        <>
            {
                openModal !== false &&
                <Modal isOpen={openModal} toggle={rejectModalOpen} className='modal-dialog-centered'>
                    <ModalHeader className='d-flex justify-content-center bg-light'>
                        <h2>Reject Request</h2>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to reject</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 justify-content-center'>
                        <Button variant='outline-secondary' onClick={rejectModalOpen} >
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant="danger"  onClick={()=>{handleReject(request)}}>
                            <span className='ml-1'>Reject</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}


export const SingleRejectModal = (props) => {
    return (

        <>
            {
                props.openModal !== false &&
                <Modal isOpen={props.openModal} toggle={props.singleRejectModalOpen} className='modal-dialog-centered'>
                    <ModalHeader className='d-flex justify-content-center bg-light'>
                        <h2>Reject All Request</h2>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to reject all</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 justify-content-center'>
                        <Button variant='outline-secondary' onClick={props.singleRejectModalOpen} >
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant="danger" onClick={props.singleRejectModalOpen}>
                            <span className='ml-1'>Reject</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}