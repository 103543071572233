import React, { useEffect, useState } from 'react';
import { Copy, CornerLeftDown, Flag, MoreVertical, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import group_profile from '../../../NewComponents/IMG/group_profile.png'
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import { BlockUserModal } from '../HoverComponents/BlockUserModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import '../Style.css'
import { GroupMembers } from '../HoverComponents/GroupMembers';
import PublicAccount from '../ConnectionModal/PublicAccount';
import { NavLink } from 'react-router-dom';
    
export const GroupListView = () => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllUserFollowers())
    }, [])

    // ------------- Share Modal State -------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    //  -------------  Start Reported Option State ------------- 
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    return (
        <>
            {
                getUserFollowers && getUserFollowers.rows?.slice(0, 8).map((request) => {
                    return <div className="connection-card  box-shadow-style content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3">
                        <div className="d-md-block">
                            <div class="media media-new d-flex align-items-start">
                                <div class="user-img rounded-circle popover___wrapper">
                                    <img src={group_profile} className=" connection-profile rounded-circle connection-profile-new" alt="shivam singh" />
                                    <span class="available-stats online"></span>
                                    <PublicAccount request={request} />
                                </div>
                                <div class="d-md-block pl-1">
                                <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate'>{request.fullName?.substring(0, 21) || 'name'}</h4></NavLink>
                                    <p className='user-name-style'>Group category</p>
                                    <GroupMembers />
                                    <div className='d-flex justify-content-between'>
                                        {/* <p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '150px' }}>This text is a one line description</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">12</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">456</h3>
                                    <h5 className='professional-style'>Members </h5>
                                </li>
                            </ul>
                        </div>
                        <div className='ml-sm-4 ml-xs-4 d-flex justify-content-center align-items-center'>
                            {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                            <Button outline className='btn-outline-blue' color="lightblue"  >
                                <CornerLeftDown size={15} />&nbsp;<span className='font-weight-bold'>&nbsp;Withdraw</span>
                            </Button>
                                <div className='d-flex align-items-end flex-column justify-content-end px-4'>
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'><CornerLeftDown size={15} className='mr-2' />Withdraw</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                            <div className='border-bottom py-2 menu-hover-style'>
                                                <BlockUserModal
                                                    btnName='Block Group' />
                                            </div>
                                            <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                <Flag size={15} className='mr-2' />Report Group
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            {/* </ul> */}
                        </div>
                       
                    </div>
                })
            }
            {
                getUserFollowers.length <= 0 && <h3 className='text-center connection-sub-heading'>No Followers Found</h3>
            }

            {/*  ------------------ Add Report Option ------------------ */}
            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------------------ Start Share Modal ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
        </>
    )
}

