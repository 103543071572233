import React, { useEffect, useState } from 'react';
import { Copy, Flag, MinusCircle, MoreVertical, PlusCircle, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import india_svg from '../../../NewComponents/IMG/india_svg.svg'
import { BlockUserModal } from '../HoverComponents/BlockUserModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import AddCategoryModal from '../ConnectionModal/AddCategoryModal';
import RemoveModal from '../ConnectionModal/RemoveModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';

export const MemberList = () => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllUserFollowers())
    }, [])

    // ------------- share Modal open ------------- 
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // ------------- Remove Followers Modal open ------------- 
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
        setRemoveModalOpen((prevState) => !prevState)
    }

    //  -------------  Start Add RelationShip Status ------------- 
    const [addRelationShipOptions, setAddRelationShipOptions] = useState(false)
    const toggleAddRelationShip = () => {
        setAddRelationShipOptions((preState) => !preState)
    }

    //  -------------  Start ReportedOption  ------------- 
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    return (
        <>
            {
                getUserFollowers && getUserFollowers.rows?.slice(0, 8).map((request) => {
                    return <div className="connection-card  box-shadow-style content-mp-block  nav-flex-container position-relative mx-2 p-2 mt-3 ">
                        <div className="nav-flex-container">
                            <div class="user-img d-flex align-items-start popover___wrapper " >
                                <img src={request.profileImage || `${process.env.REACT_APP_IMAGEURL}/userProfileDp/LhXHReJcWmnBW.jpg`}
                                    className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                <span class="available-stats online"></span>
                                <PublicAccount request={request} />
                            </div>
                            <div className="media-body pl-1">
                                <h4 className='m-0 name-style' >{request.fullName || 'name'}</h4>
                                <h6 className='professional-style d-inline-block text-truncate' style={{ width: '120px' }}>I am a freelance mobile app developer who specializes in native, as well as hybrid app development.</h6>
                                <div className='d-flex'>
                                    <p className='user-name-style'>@{request.userName || 'userName'}  |  </p>
                                    <div className='d-flex ml-1'>
                                        <img src={india_svg} alt='...' />
                                        <span className='professional-style ml-1'>India</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">23</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">12</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">456</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className='pt-2 ml-sm-4 ml-xs-4'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '>
                                <div className="d-flex align-items-center">
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleRemoveModalOpen} ><MinusCircle size={15} className='mr-2' />Remove SocioMate</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddRelationShip}><PlusCircle size={15} className='mr-2 icon-color-style' />Add To Category</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                <Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                            <div className='border-bottom py-2 menu-hover-style'>
                                                <BlockUserModal
                                                    btnName='Block User' />
                                            </div>
                                            <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                <Flag size={15} className='mr-2' />Report User
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </ul>
                        </div>

                    </div>
                })
            }
            {/*  ------------------ Add Report Option  ------------------ */}

            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />

            {/*  ------------------ Add CategoryModal Status  ------------------ */}

            <AddCategoryModal
                openModal={addRelationShipOptions}
                cateModalOpen={addRelationShipOptions}
            />

            {/*  ------------------ Remove SocioMate Modal  ------------------ */}

            <RemoveModal
                openModal={removeModalOpen}
                removeModalOpen={toggleRemoveModalOpen}
            />

            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
        </>
    )
}

