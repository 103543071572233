import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap'

const ConnectionEditModal = (props) => {
  return (
    <>
      {
        props.openModal !== false &&
        <Modal isOpen={props.openModal} toggle={props.editModal} className='modal-dialog-centered'>
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLongTitle">Edit Category Name</h3>
            <div role="button" onClick={props.editModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-dark close-btn"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
          </div>
          <ModalBody className='mt-2'>
            <form className="theme-form">
              <div className="form-group">
                <input type="text" className="form-control" name="connectionName" placeholder="Category Name " />
                <Button className='mt-3 w-100' onClick={props.editModal}>Save</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      }
    </>
  )
}

export default ConnectionEditModal