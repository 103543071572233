import React from 'react'
import { Card, CardBody } from 'reactstrap'
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import profile from '../../../NewComponents/IMG/profile_conn.png'
import private_icon from '../../../NewComponents/IMG/bag_private.svg'
import { Button } from 'react-bootstrap';

const PrivateAccount = () => {
    return (
        <>
            <Card className='popover____content' >
                <div className="d-flex  align-items-center border-bottom p-2">
                    <img src={profile} alt='...' height='60px' width='60px' />
                    <div className='ml-3'>
                        <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '120px' }}>Rohan kumar singh</h4>
                        <div className='d-flex'>
                            <p className='user-name-style'>Rohan kumar  |  </p>
                            <img src={india_svg} className='ml-1' alt='...' />
                        </div>
                    </div>
                </div>
                <CardBody>
                    <div className='py-1 border-bottom'>
                        <ul id="counter" className='d-flex justify-content-around '>
                            <li className='text-center'>
                                <h3 className="counter-value green-text-style" data-count="546">23</h3>
                                <h5 className='professional-style'>Following</h5>
                            </li>
                            <li className='text-center mx-3'>
                                <h3 className="counter-value green-text-style" data-count="26335">12</h3>
                                <h5 className='professional-style'>Post</h5>
                            </li>
                            <li className='text-center'>
                                <h3 className="counter-value green-text-style" data-count="6845">456</h3>
                                <h5 className='professional-style'>Followers</h5>
                            </li>
                        </ul>
                    </div>
                    <div lg='12' className='text-center'>
                        <img src={private_icon} alt='' className='m-1 img-fluid' />
                        <h3 className='py-2'>The Account is Private</h3>
                        <p>Follow this account to see their photos and videos</p>
                    </div>
                    <div className='w-100 mt-4'>
                        <Button variant='primary' className='w-100'>
                            <span>Follow</span>
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </>

    )
}

export default PrivateAccount