import React from "react";
import { Fragment, useEffect } from "react";
import LiveGif from "../../NewComponents/IMG/LiveGif.gif";
import {
  Button,
  Card,
  CardFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
} from "reactstrap";
import { ChevronDown, Edit, Globe, Image, Smile } from "react-feather";
import { useState } from "react";
import CreationMainSection from "./CreationMainSection";
import DiscaedModal from "./DiscaedModal";
import User from "./Img/US1.svg";
import { loadProfileByUserId } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRef } from "react";

const PostCreation = ({userId,feelingCategory, modal, setModal,selectedImages,setSelectedImages}) => {
  const [privacy, setprivacy] = useState("Public");
  const [currStatus,setCurrStatus]=useState()
  const togglePrivacy = (prv) => {
    setprivacy(prv.target.value);
    toggle(`/modal/${prv.target.id}`);
    console.warn(prv.target.value);
  };
  const [currState,setCurrState]=useState(0)
 
  const [path, setPath] = useState("/modal/");
  const [postTypeindex, setPostTypeindex] = useState();
  const [selectedPostType, setSelectedPostType] = useState("/modal/");
  // const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1000);
  const [postType, setPostType] = useState();
  const states=activeTab

  const toggle = (path) => {

    console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
    if (path.indexOf("/modal/" > -1)) {
      if (modal) {
        setActiveTab(parseFloat(path.replace("/modal/", "")));
    
        setPath(path);
       
        const index = parseFloat(path.replace("/modal/", ""));
      console.log("in toggle function",index)
        const postType =
          index === 0.1
            ? "media"
            : index === 1
            ? "feeling"
            : index === 7.1
            ? "thought"
            : index === 7.3
            ? "event"
            : index === 7.6
            ? "recomendation"
            : index === 7.7
            ? "alert"
            : index === 7.8
            ? "poll"
            : "text";
        setPostType(postType);
       
      } else {
      // console.log("in toglle mode elese sections")
      const index = parseFloat(path.replace("/modal/", ""));
      if(index===0.1){
        const ptype="media";
        setPostType(ptype)
      }
      
      
        setActiveTab(parseFloat(path.replace("/modal/", "")));
        setModal(true);
        setPath(path);
      
      }
    } else {

      setModal(false);
    }
  };

  // const toggle = (ipath) => {

  //   console.log("Modal is :");

  //   const addons = [1,6,5,3,4];
  //   const typeOfModal = ipath.replace("/modal/", "");
  //   if(addons.includes(parseInt(typeOfModal))){
  //     console.log("Addon" + typeOfModal);
  //   }else{
  //     if(typeOfModal != 99){
  //     console.log("Real post" + typeOfModal );
  //     setPath(ipath);
  //     setPostTypeindex(parseFloat(typeOfModal));
  //     }
  //   }
  //   const index = parseFloat(ipath.replace("/modal/", ""));
  //   if (ipath.indexOf("/modal/" > -1)) {
  //     if (modal === true) {
  //       console.log("Inside true");
  //       if (index === 99) {
  //         const selectedPath = path.replace("/modal/", "");
  //         console.log("setting active tab : "+ selectedPath);
  //         setActiveTab(parseFloat(selectedPath));
  //       } else {
  //         setActiveTab(parseFloat(ipath.replace("/modal/", "")));
  //         //setPath(ipath);
  //       }
  //       //setPath(ipath);
  //     } else {
  //       console.log("Inside false");
  //       setActiveTab(parseFloat(ipath.replace("/modal/", "")));
  //       setModal(true);
  //       setPath(ipath);
  //     }
  //     console.log(index);

  //     const postType =
  //     postTypeindex === 0.1
  //         ? "media"
  //         : postTypeindex === 1
  //         ? "feeling"
  //         : postTypeindex === 7.1
  //         ? "thought"
  //         : postTypeindex === 7.3
  //         ? "event"
  //         : postTypeindex === 7.6
  //         ? "recomendation"
  //         : postTypeindex === 7.7
  //         ? "alert"
  //         : postTypeindex === 7.8
  //         ? "poll"
  //         : "text";
  //     setPostType(postType);
  //   } else {
  //     console.log("close called");
  //     setModal(false);
  //   }
  // };

  const [discard, setDisCard] = useState(false);
  const toggleDiscard = () => {
    setDisCard((preState) => !preState);
  };
  const toggleAllDiscard = () => {
    setModal(false);
    toggleDiscard(false);

  };

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfileByUserId(userId));
    // console.log(userProfileByUserId.fullName);
  }, []);
  //================ Main_retun_function ===========================//

  return (
    <Fragment>
      <Card className="Card_Main_blk_section">
        <div className="Creation_main_blk">
          <div className="Creation_Header_section">
            <h3>Create Post </h3>
            <div className="d-flex">
              <Link
                to="/GoLive"
                className="GoLive_section d-flex align-items-center  "
              >
                <img width={20} src={LiveGif} alt="LiveGif1" />
                &nbsp;Go Live
              </Link>
              <span
                className="ml-3 d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const ptype=postType
                  toggle("/modal/2");
                  if(ptype==="recomendation"){
                    setCurrStatus(7.6)
                  }else if(ptype==="thought"){
                    setCurrStatus(7.1)
                  }else if(ptype==="text"){
                    setCurrStatus(0)
                  }else if(ptype==="media"){
                    setCurrStatus(0.1)
                  }else if(ptype==="event"){
                    setCurrState(7.3)
                  }
                  
                 setCurrState(currStatus)
                 console.log("in clicked event of privacy state,status ",setCurrState,currStatus)
                }}
              >
                <Globe color="#979797" size={16} />
                &nbsp;{privacy}&nbsp;
                <ChevronDown  color="#979797" size={18} />
              </span>
            </div>
          </div>
          <div
            style={{
              background: "#F6F6F6",
              marginTop: "15px",
              borderRadius: "5px",
            }}
          >
            <InputGroup className="input-group-merge">
              <InputGroupAddon addonType="prepend m-2">
                <img
                  className="rounded-circle border"
                  width={45}
                  height={45}
                  src={userProfileByUserId.profileImage}
                  alt="userImg"
                />
              </InputGroupAddon>
              <Input
                type="textarea"
                placeholder="What’s going on? #Hashtag... @Mention."
                cols="5"
                rows="3"
                readOnly
                onClick={() => {
                  toggle("/modal/0");
                }}
                maxLength={"5000"}
                // style={{ color: props.color }}
                className="border-0 inputFortext inputForback"
              />
              {/* <img
                                src="/assets/images/pen-solid-2.png"
                                width={15} height={15}
                                className="m-2"
                                alt="pen"
                            /> */}
            </InputGroup>
            <p></p>
          </div>
        </div>
        <CardFooter className="bg-white">
          <div className="d-flex  gap-2">
            <button
              className="NewActiviy_btn_PostBlk"
              onClick={() => {
                setModal(true);
                console.log(modal)
                toggle("/modal/0.1");
              }}
            >
              <Image size={22} style={{ cursor: "pointer" }} />
              <span className="px-1">Media</span>
            </button>
            <button
              className="NewActiviy_btn_PostBlk"
              onClick={() => {
                toggle("/modal/1");
                
              }}
            >
              <Smile size={22} />
              <span className="px-1">Feeling/activity</span>
            </button>
            <button
              className="NewEdit_btn_blk"
              onClick={() => {
                toggle("/modal/7");
              }}
            >
              <Edit id="CreatePostTooltip" style={{ cursor: "pointer" }} />
              <UncontrolledTooltip placement="top" target="CreatePostTooltip">
                Create Post
              </UncontrolledTooltip>
            </button>
          </div>
        </CardFooter>
      </Card>
      {/*============= Start_modal_section =================*/}
      <CreationMainSection
        userId={userId}
        states={states}
        currState={currState}
        setModal={setModal}
        modal={modal}
        setSelectedImages={setSelectedImages}
        selectedImages={selectedImages}
        path={path}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        postType={postType}
        setPostType={setPostType}
        toggle={toggle}
        toggleDiscard={toggleDiscard}
        feelingCategories={feelingCategory}
      />
      <DiscaedModal
        discard={discard}
        toggleDiscard={toggleDiscard}
        toggleAllDiscard={toggleAllDiscard}
      />
    </Fragment>
  );
};

export default PostCreation;
