import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const getUserReportedOption = (getUserReportOption) => ({
    type: types.GET_USER_REPORT_OPTION,
    payload: getUserReportOption,
});
// get all biz category
export const getActionUserReportedOption = () => {
   
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    return function (dispatch) {
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getReportOptions`,
            {
             type:"user"
            },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                .then((res) => {
                    
                    dispatch(getUserReportedOption(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};
