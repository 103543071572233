import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { Checkbox } from "@mui/material";
import UserImg from "../../NewComponents/IMG/img.svg";
import { UilCommentMessage } from "@iconscout/react-unicons";
import {
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
  CardFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { ArrowLeft,XCircle } from 'react-feather'
import { Link } from "react-router-dom";
import { Circle, Filter } from "react-feather";
import { loadAllUserSciomate } from "../../Services/Actions/Connection/getSociomateAction";
import { loadAllConnections } from "../../Services/Actions/SocialFeed/getConnectionListAction";
import { sweetAlertConfirmation } from "../group-components/AllGroupsSections/SweetAlert";

import PostCard from "../Social-feed/Components/PostCard";


const ShareToModal = ({
  forwardOpen,
  postType,
  setPostType,
  likePost,
  unlikePost,
  handleProfileView,
  toggleForward,
  handleSave,
  handleUnsave,
  handleAllComments,
  commentDataArray,
  handleCommentLike,
  EmoteAvatar,
  handleHidePost,
  handleNotificationOff,
  handleNotificationOn,
  handleCommentOn,
  handleCommentOff,
  handleBlockUnblock,
  setShareCommentView,
  shareCommentView
}) => {
  const [postName, setPostNmae] = useState("")
  // console.log(postType, "  @@ postType")
  const user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const dispatch = useDispatch();
  const { getUserSociomate } = useSelector((state) => state.userSociomateData);
  const { allConnections } = useSelector((state) => state.getAllConnectionsData);
  const [searchQuery, setSearchQuery] = useState("")
  // const [allPeople, setAllPeople] = useState(allConnections);
  const [reSharePostDisplay,setReSharePostDisplay]=useState(false)
  useEffect(()=>{},[reSharePostDisplay])
  const [check, setCheck] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [reshareFullName, setReshareFullName] = useState(null);
  const [reshareProfileImage, setReshareProfileImage] = useState(null);
  const [resharePostType, setResharePostType] = useState(null);
  const [reshareCaption, setReshareCaption] = useState(null);
  const [allowComment, setAllowComment] = useState(true);
  const [colorComment,setColorComment]=useState("")
  const [comment, setComment] = useState(false);

  useEffect(() => {
    handleFullname(postType);
  }, []);
  const handleFullname = (postType) => {
    if (postType && postType.reSharedPost && postType.reSharedPost.length > 0) {
      postType.reSharedPost.forEach((reSharedPost) => {
        setReshareFullName(reSharedPost.fullName);
        setReshareProfileImage(reSharedPost.profileImageThumb);
        setReshareCaption(reSharedPost.caption);
      });
    } else {
      console.warn("postType is undefined or doesn't have reSharedPost",);
    }
  };
  // useEffect(() => {
  //   dispatch(loadAllUserSciomate());
  // }, []);
  // useEffect(() => {
  //   dispatch(loadAllConnections(searchQuery));
  //   setAllPeople(allConnections)
  // }, [searchQuery]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const handleCheck = (id, item) => {
    // Check if the item is already selected
    const isSelected = check.includes(id);

    if (isSelected) {
      // If selected, remove the item from the check and selectedList
      setCheck(prevCheck => prevCheck.filter(x => x !== id));
      setSelectedList(prevSelectedList => prevSelectedList.filter(selectedItem => selectedItem.id !== id));
    } else {
      // If not selected, add the item to check and selectedList
      setCheck(prevCheck => [...prevCheck, id]);
      setSelectedList(prevSelectedList => [...prevSelectedList, item]);
    }
    console.log("selected people list in share ", selectedList)
  };
  const handelSharePost=async(postId)=>{
    try{
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/addShare`,
        {
          postId:postId,

        },
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
            },
        }
    )
    console.log(response)
    }catch(err){
      console.log(err)
    }
  }



  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  // console.log("userDetails ",userProfileByUserId)

 

  const Sharedto = [
    {
      name: "Your Wall Feed",
    },
    // {
    //   name: "Your Story",
    // },
  ];

  async function handelShare(selectedList) {
    setReSharePostDisplay(true);
    forwardOpen=false
 setShareCommentView(false)

    // console.log("here is selected list and props  ", selectedList, props)
    // let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    // props.toggleForward;
    const body = {
      userId: user.id,
      otherUserId: selectedList,
      chatHeadType: "",
      messageData: {
        messageType: postType?.postType,
        messageText: postType?.caption,
        messageJson: "",
        isForwarded: "",
        replyMessageId: ""
      }

    }
    try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
      await axios.post(
        `${process.env.REACT_APP_IPURL}/messenger/sendUserMessage`,
        body,
        {
          headers: { Authorization: `Bearer ${user?.token}` }
        })
        
    } catch (error) {
      
    }

  }

  //=======>> getUserBusinessPages and Groups <<===========
  const [userGroupData,setUserGroupData]=useState([])
  const [userBizData,setUserBizData]=useState([])
  const [bizAndGroupPageIndex,setBizAndGroupPageIndex] = useState(0)
  const [peopleListData,setPeopleListData]=useState([]);
  const [peopleListPageIndex,setPeopleListPageIndex]=useState(0)
  const [searchKeyValue,setSearchKeyValue]=useState(null)

 const getUserBizAndGroupsData = async (peopleListPageIndex) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      let body = {
        userId: user?.id,
        pageIndex: peopleListPageIndex || 0,
        pageSize: 10,
        searchKey :""
     
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getUserBizPgesAndGroupsListing`,
        body,
        config
      );
      // console.log(
      //   "api response userBizandGroupData list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      let userBizandGroupData = response.data?.data?.successResult;
     return userBizandGroupData;
    } catch (error) {
      console.log(error);
    }
  };

  //=======>> set debounce here for serachkey <<=======
  const [searchKey, setSearchKey] = useState("");
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setSearchKeyValue(value); 
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  };
      //calling debouce value after a certain time
   useDebounce(searchKey, 300);
    //set value in use state of the input field
  const handleSearchChange = (e) => {
    setSearchKey(e.target.value); 
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(prev => prev.filter(id => id !== userId));
    } else {
      setSelectedUsers(prev => [...prev, userId]);
    }
  };

  const [selectedGroups, setSelectedGroups] = useState([]);
  const handleGroupcheck = (e,groupId) => {
    if (selectedGroups.includes(groupId)) {
      setSelectedGroups(prev => prev.filter(id => id !== groupId));
    } else {
      setSelectedGroups(prev => [...prev, groupId]);
    }
  };

  useEffect(()=>{
    const fetchData =async()=>{
      const getUserBizGroupData= await getUserBizAndGroupsData(bizAndGroupPageIndex)
      if(getUserBizGroupData){
        setUserGroupData(prevData => [...prevData, ...getUserBizGroupData?.groupData])
        setUserBizData(prevData => [...prevData, ...getUserBizGroupData?.bizPageData])
      }
    }
    if(forwardOpen){ 
      fetchData(); //api will hit when toggle will open
    }
  },[forwardOpen,bizAndGroupPageIndex])


  //=====================>>>>>>>>>> following follower sociomate <<<<<<<<<<<<<<<=======================================

  const [currList,setCurrList]=useState(0);
  const [error,setError]=useState('');
  const [searchKeyFollower,setSearchKeyFollower]=useState('');
  const [pageIndexFollower,setPageIndexFollower]=useState(0);
  useEffect(() => {
    if(currList===0){
    const fetchData = async () => {
        try {
            
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                    { searchKey:searchKeyFollower, pageIndex:pageIndexFollower, pageSize:10, sortBy:"userName" , countryIds:[] },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setPeopleListData(response.data.data.successResult.rows);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    fetchData();
  }else if(currList===1){
    const fetchData = async () => {
      try {
          if (user) {
              const response = await axios.post(
                  `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                  { searchKey:searchKeyFollower, pageIndex:pageIndexFollower, pageSize:10, sortBy:"userName" , countryIds:[] },
                  { headers: { Authorization: `Bearer ${user.token}` } }
              );
              setPeopleListData(response.data.data.successResult.rows);
          } else {
              setError('no user found');
          }
      } catch (error) {
          setError(error.message);
      }
  };
  fetchData();
  }else if(currList===2){
    const fetchData = async () => {
      try {
          if (user) {
              const response = await axios.post(
                  `${process.env.REACT_APP_IPURL}/hamburgermenu/getFriendUserList`,
                  { searchKey:searchKeyFollower, pageIndex:pageIndexFollower, pageSize:10, sortBy:"userName" , countryIds:[] },
                  { headers: { Authorization: `Bearer ${user.token}` } }
              );
              setPeopleListData(response.data.data.successResult.rows);
          } else {
              setError('no user found');
          }
      } catch (error) {
          setError(error.message);
      }
  };
  fetchData();
  }
}, [searchKeyFollower,pageIndexFollower,currList]);

  // =====>>> handling api with scroll  of the group and biz page <<======
   const handleScrollBizAndGroup = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setBizAndGroupPageIndex(previousValue => previousValue + 1);
    }
  };

  //=======>> checkbox selected bizPage  <<========
    const [selectedBizPages, setSelectedBizPages] = useState([]);
    const handleBizPages = (e,bizPageId) => {
      if (selectedBizPages.includes(bizPageId)) {
        setSelectedBizPages(prev => prev.filter(id => id !== bizPageId));
      } else {
        setSelectedBizPages(prev => [...prev, bizPageId]);
      }
    };

    const [isShareToWallFeed,setIsShareToWallFeed] = useState(false)
    const handleWallfeed=(e)=>{
      setIsShareToWallFeed(prev => !prev);

    }

    const handleShareClick = () => {
     console.log("something")
    };

    // =====>>> handling api with scroll  of the people list <<======
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
        setPeopleListPageIndex(previousValue => previousValue + 1);
    }
  };

  // console.log("postType===================================", typeof postType?.postType)
  const groupAllowList=['text', 'media', 'thought', 'event', 'articl','poll']

  const bizPageAllowList=['text', 'media', 'article', 'event', 'sell']

  const isGroupAllowed = groupAllowList.includes(postType?.postType);
  const isBizPageAllowed = bizPageAllowList.includes(postType?.postType);


  return (
    <div>
      {forwardOpen !== false && (
        <Modal
          isOpen={forwardOpen}
          toggle={toggleForward}
          className="modal-dialog-centered"
        >
          <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
            <h2>Share to </h2>
            <Button onClick={handleShareClick} color="primary">
              Share
            </Button>
          </div>
          <ModalBody onScroll={handleScroll}>
            <div className='pb-2'>
              <Accordion defaultActiveKey="0">
                <AccordionItem eventKey='1'>
                  <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                  <AccordionBody className='p-0'>
                    <Accordion defaultActiveKey="0">
                      <AccordionItem eventKey='1'>
                        <AccordionHeader>Feed</AccordionHeader>
                        <AccordionBody>
                          {Sharedto.map((data) => (
                            <div className='d-flex justify-content-between align-items-center my-2 Report_Section' key={data.name}>
                              <h4>{data?.name}</h4>
                              <Checkbox onChange={handleWallfeed} />
                            </div>
                          ))}
                        </AccordionBody>
                      </AccordionItem>
                      {isBizPageAllowed && (
                          <AccordionItem eventKey='2'>
                            <AccordionHeader>Your Business Pages</AccordionHeader>
                            <AccordionBody>
                              <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }} onScroll={handleScrollBizAndGroup}>
                                {userBizData?.length > 0 && userBizData?.map((bizPage, index) => (
                                  <div
                                    key={index}
                                    className="group-member-img-cont-blk d-flex align-items-center justify-content-between"
                                    style={{ padding: '10px', borderBottom: '1px solid #e0e0e0' }}
                                  >
                                    <div className="ForWard_Img_categories d-flex align-items-center">
                                      <img
                                        src={bizPage?.coverUrl}
                                        alt="img"
                                        className="img-fluid rounded-circle"
                                        style={{ width: '50px', height: '50px' }}
                                      />
                                      <div className="ml-1">
                                        <h4>{bizPage?.name}</h4>
                                      </div>
                                    </div>
                                    <Checkbox onChange={(e) => handleBizPages(e, bizPage?.id)} />
                                  </div>
                                ))}
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        {isGroupAllowed && (
                          <AccordionItem eventKey='3'>
                            <AccordionHeader>Your Groups</AccordionHeader>
                            <AccordionBody>
                              <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }} onScroll={handleScrollBizAndGroup}>
                                {userGroupData.length > 0 && userGroupData.map((group, index) => (
                                  <div
                                    key={index}
                                    className="group-member-img-cont-blk d-flex align-items-center justify-content-between"
                                    style={{ padding: '10px', borderBottom: '1px solid #e0e0e0' }}
                                  >
                                    <div className="ForWard_Img_categories d-flex align-items-center">
                                      <img
                                        src={group?.coverUrl}
                                        alt="img"
                                        className="img-fluid rounded-circle"
                                        style={{ width: '50px', height: '50px' }}
                                      />
                                      <div className="ml-1">
                                        <h4>{group?.name}</h4>
                                      </div>
                                    </div>
                                    <Checkbox onChange={(e) => handleGroupcheck(e, group?.id)} />
                                  </div>
                                ))}
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                    </Accordion>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="search-block-podcast mt-2">
              <div className="input-search-blk">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" onChange={(e)=>handleSearchChange(e) } />
              </div>
            </div>
            <div className='mt-3 Categories_subHeading_section'>
              <h4>Connections</h4>
              {/* ==============commneted filter button =========== */}
              <UncontrolledButtonDropdown>
                <DropdownToggle color='primary' outline>
                  <Filter size={20} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag='a' onClick={(e)=>{e.preventDefault(); setCurrList(0)}}> Followers </DropdownItem>
                  <DropdownItem tag='a' onClick={(e)=>{e.preventDefault(); setCurrList(1)}}> Followings </DropdownItem>
                  <DropdownItem tag='a' onClick={(e)=>{e.preventDefault(); setCurrList(2)}}> Sociomates </DropdownItem>


                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className='mt-3'>
              {peopleListData?.length > 0 && peopleListData?.map((data) => (
                <div className="group-detail-page-blk" key={data.id}>
                  <div className="MyEvents-memberslist-block">
                    <div className="group-member-img-cont-blk">
                      <div className='ForWard_Img_categories'>
                        <img src={data?.profileImage}
                          // onError={(e) => e.target.src = `${process.env.DUMMY_PROFILE}`}
                          className='img-fluid rounded-circle' />
                        <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                      </div>
                      <div className="member-cont-blk">
                        <h4>{data?.fullName}</h4>
                        <p>{data?.fullName}</p>
                      </div>
                    </div>
                    <Checkbox onChange={() => handleCheckboxChange(data?.id)}/>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
      {
        reSharePostDisplay &&(
        <Modal
          isOpen={forwardOpen}
          toggle={toggleForward}
          className="modal-dialog-centered"
        >
          <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
            <h2>Share Post </h2>
            <div style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
                        <XCircle 
                        onClick={()=>{setShareCommentView(true);setReSharePostDisplay(false)}} 
                         />
                    </div>
          </div>
          <ModalBody>
          <div className="d-flex d-flex  align-items-start py-2 mr-2 align-items-start py-2 mr-2 ">
            <img
              src={userProfileByUserId.
                profileImage
                }
              className="rounded-circle border"
              width={40}
              height={40}
              alt="UserImage"
            />
            <h4  style={{    marginTop: "11px",
    marginLeft: "13px"}}>{userProfileByUserId.
                fullName
                }</h4>
            {/* //-------------------------------------------------------------------------------------------------// */}
          </div>
          <textarea
          placeholder="Whats going on? #Hashtag...@Mention"
          style={{width:" 97%",
              marginLeft: "3px",
              height: "94px",
              marginTop: "13px",
              borderColor:"#81c14b",
              borderRadius:"4px",
              borderWidth:"2px",
              borderStyle: 'solid'}}/>
            <div className="pb-2">
            </div>
            <div className="mt-3 Categories_subHeading_section ">
            <div className="body_share" style={{    width: "100%"}}>
            <PostCard
            postType={postType}
            setPostType={setPostType}
            likePost={likePost}
            unlikePost={unlikePost}
            handleProfileView={handleProfileView}
            toggleForward={toggleForward}
            handleSave={handleSave}
            handleUnsave={handleUnsave}
            handleAllComments={handleAllComments}
            commentDataArray={commentDataArray}
            handleCommentLike={handleCommentLike}
            EmoteAvatar={EmoteAvatar}
            handleHidePost={handleHidePost}
            handleNotificationOff={handleNotificationOff}
            handleNotificationOn={handleNotificationOn}
            handleCommentOn={handleCommentOn}
            handleCommentOff={handleCommentOff}
            handleBlockUnblock={handleBlockUnblock}
            setShareCommentView={setShareCommentView}
            shareCommentView={shareCommentView}
          
          /></div>
            </div>
            <div className="d-flex align-items-center justify-content-between p-3 border-bottom ">
            <h4>Add to your post</h4>
            <Button 
                  style={{color:`${colorComment}`}}
                    className={`NewActiviy_btn_PostBlk `}
                    onClick={() => {
                 
                      setComment(!comment);
                      if(colorComment===""){
                        setColorComment("#81c14b")
                      }else{
                        setColorComment("")
                      }
                     
                     
                    }}
                  >
                    <UilCommentMessage id="CmntTooltip" />
                    <UncontrolledTooltip placement="top" target="CmntTooltip">
                      Comments
                    </UncontrolledTooltip>
                  </Button>
          </div>
          {comment ? (
                <Fragment>
                  <CardFooter className="bg-white ">
                    <div className="d-flex justify-content-between align-items-center Creation_footer_section">
                      <h3>Allow Comment</h3>
                      <div className=" d-flex">
                        <div className="mr-2 BizSetting_msg_toggle py-1"  >
                          On
                        </div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() => {
                              
                              setAllowComment(!allowComment);
                              
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                        <div className="ml-2 BizSetting_msg_toggle py-1">
                          Off
                        </div>
                      </div>
                    </div>
                  </CardFooter>
                </Fragment>
              ) : null}
          </ModalBody>
          <Button 
          onClick={()=>handelSharePost(postType.postId)}
          style={{    
            marginBottom: "20px",
            marginLeft: "17px",
            width: "92%",
            height: "32px",
            color:"white",
            backgroundColor:"#81c14b",
            paddingBottom:"25px",
}}>Post</Button>
        </Modal>
        )
      }
    </div>
  );
};

export default ShareToModal;
