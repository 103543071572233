import React, { Fragment, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import pollIcon from "../../../NewComponents/IMG/pollIcon.svg";
import { Button, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";



// Utility function to get the start of today
const getStartOfToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day
  return today;
};

// Utility function to get the difference in days between two dates
const getDifferenceInDays = (start, end) => {
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) ;
};

const Poll = ({
  showpoll,
  pollQuestion,
  options,
  setOptions,
  pollDurationStart,
  pollDurationEnd,
  onUpdatePollData,
  postEditValue,
  setPostButtonDisable
}) => {
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const dispatch = useDispatch();
  const PollMaxDays = 10;
  const maxPollOptions = 6;
  const [startDate, setStartDate] = useState(pollDurationStart || getStartOfToday());
  const [endDate, setEndDate] = useState(pollDurationEnd || null);
  const [error, setError] = useState(""); // State to manage error messages

  useEffect(() => {
    dispatch(loadProfileByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (postEditValue && postEditValue.caption) {
      const arr = postEditValue.pollOptions.map(item => ({
        optionText: item.optionText,
        sequence: item.sequence
      }));
      onUpdatePollData(postEditValue.caption, arr, postEditValue.pollStartTime, postEditValue.pollEndTime);
    }
  }, [postEditValue, onUpdatePollData]);

  const handleRemoveFields = (id_check) => {
    const newOptions = options.filter(option => option !== id_check);
    setOptions(newOptions);
    onUpdatePollData(pollQuestion, newOptions, startDate, endDate);
  };

  const handleStartDateChange = (date) => {
    if (!date) {
      setStartDate(null);
      setError("");
      onUpdatePollData(pollQuestion, options, null, endDate);
      return;
    }

    const formattedDate = new Date(date);
    formattedDate.setHours(0, 0, 0, 0);

    const utcDate = new Date(Date.UTC(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate()));

    if (endDate && utcDate > endDate) {
      setError("Start date cannot be after the end date.");
      setPostButtonDisable(true);
    } else {
      setStartDate(utcDate);
      setError(""); // Clear error if validation passes
      setPostButtonDisable(false);

      if (endDate && getDifferenceInDays(utcDate, endDate) > PollMaxDays) {
        setError(`Poll duration cannot exceed ${PollMaxDays} days.`);
        setPostButtonDisable(true);
      } else {
        onUpdatePollData(pollQuestion, options, utcDate, endDate);
      }
    }
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setEndDate(null);
      setError("");
      onUpdatePollData(pollQuestion, options, startDate, null);
      return;
    }

    const formattedDate = new Date(date);
    formattedDate.setHours(0, 0, 0, 0);

    const utcDate = new Date(Date.UTC(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate()));

    if (startDate && utcDate < startDate) {
      setError("End date cannot be before the start date.");
      setPostButtonDisable(true);
    } else {
      setEndDate(utcDate);
      setError(""); // Clear error if validation passes
      setPostButtonDisable(false);

      if (startDate && getDifferenceInDays(startDate, utcDate) > PollMaxDays) {
        setError(`Poll duration cannot exceed ${PollMaxDays} days.`);
        setPostButtonDisable(true);
      } else {
        onUpdatePollData(pollQuestion, options, startDate, utcDate);
      }
    }
  };

  const handleClearDate = (type) => {
    if (type === "start") {
      setStartDate(null);
    } else if (type === "end") {
      setEndDate(null);
    }
    setError(""); // Clear the error message when the value is cleared
    onUpdatePollData(pollQuestion, options, type === "start" ? null : startDate, type === "end" ? null : endDate);
  };

  const handleAddOption = () => {
    if (options.length < maxPollOptions) {
      const len = options.length;
      setOptions([...options, { optionText: "", sequence: len }]);
    } else {
      setError(`You can only add up to ${maxPollOptions} options.`);
    }
  };

  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center my-2 py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
            alt="Profile"
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            {userProfileByUserId.fullName}
          </h5>
        </div>
        <div className="d-flex">
          <img src={pollIcon} className="mr-1" alt="poll" />
          <h4>Poll Question</h4>
        </div>
        <Form className="my-2">
          <FormGroup>
            <Input
              type="text"
              placeholder="Write your poll question here..."
              value={pollQuestion}
              onChange={(e) =>
                onUpdatePollData(
                  e.target.value,
                  options,
                  startDate,
                  endDate
                )
              }
            />
          </FormGroup>
          <div className="pl-2" style={{ position: "relative" }}>
            {options.map((option, index) => (
              <FormGroup key={index}>
                <Label tag="h4" className="my-1" for={`helperText${index}`}>
                  Option {index + 1}
                </Label>
                <Input
                  type="text"
                  id={`helperText${index}`}
                  placeholder={`Add ${index + 1}st Poll option`}
                  value={option.optionText}
                  onChange={(e) => {
                    const updatedOptions = [...options];
                    updatedOptions[index].optionText = e.target.value;
                    onUpdatePollData(
                      pollQuestion,
                      updatedOptions,
                      startDate,
                      endDate
                    );
                  }}
                />
                {options.length > 2 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveFields(option);
                    }}
                    className="delete ml-2"
                    style={{
                      position: "absolute",
                      left: "640px",
                      marginTop: "-30px",
                      border: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                      color: "#81c14b"
                    }}
                  >
                    ✕
                  </button>
                )}
              </FormGroup>
            ))}
            {showpoll && options.length < maxPollOptions && (
              <FormGroup>
                <Label
                  tag="h4"
                  className="my-1"
                  for={`helperText${options.length}`}
                >
                  Option {options.length + 1}
                </Label>
                <Input
                  type="text"
                  id={`helperText${options.length}`}
                  placeholder={`Add ${options.length + 1} Poll option`}
                />
              </FormGroup>
            )}
          </div>
        </Form>
        <div className="text-right">
          <Button
            className="text-primary"
            onClick={handleAddOption}
            color="flat"
            disabled={options.length >= 6} // Disable button if maximum options are reached
          >
            <h4>
              {!showpoll ? (
                "Add More Options"
              ) : (
                <span style={{ color: "red" }}>Remove</span>
              )}
            </h4>
          </Button>
        </div>
        <div>
          <h4>Poll Duration</h4>
          <p className="text-muted my-4">
            This poll will be automatically disabled after the selected time
            duration
          </p>
        </div>
        <Row>
          <Col>
            <div className="d-flex">
              <DateTimePicker
                className="form-control"
                placeholder="dd/MM/yyyy"
                value={startDate}
                onChange={handleStartDateChange}
                format="dd/MM/yyyy"
                showTimeSelect={false}
                minDate={getStartOfToday()} // Prevent selecting dates before today
              />
              {startDate && (
                <button
                  onClick={() => handleClearDate("start")}
                  className="delete"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    color: "#81c14b",
                    marginLeft: "-30px",
                    marginTop: "5px",
                    cursor: "pointer"
                  }}
                >
                  ✕
                </button>
              )}
            </div>
          </Col>
          <Col>
            <div className="d-flex">
              <DateTimePicker
                className="form-control"
                placeholder="dd/MM/yyyy"
                value={endDate}
                onChange={handleEndDateChange}
                format="dd/MM/yyyy"
                showTimeSelect={false}
                minDate={startDate || getStartOfToday()} // Prevent selecting dates before the start date or today
              />
              {endDate && (
                <button
                  onClick={() => handleClearDate("end")}
                  className="delete"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    color: "#81c14b",
                    marginLeft: "-30px",
                    marginTop: "5px",
                    cursor: "pointer"
                  }}
                >
                  ✕
                </button>
              )}
            </div>
          </Col>
        </Row>
        {error && (
          <div className="text-danger my-2">
            <p>{error}</p>
          </div>
        )}
      </CardBody>
    </Fragment>
  );
};

export default Poll;
