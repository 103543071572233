
import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const createConnectionList = (connectionList) => ({
    type: types.CREATE_ALL_CONNECTION_LIST,
    payload: connectionList,
});
// get all biz category
export const getCreatetConnectionListAction = (  ) => {
    //console.log("categoryName============>",categoryName)
    return function (dispatch) {
        const user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllConnectionList `, {
                    searchKey:"",
                    pageIndex:"",
                    pageSize:10,
                    sortBy:"fullName",
                    filteredBy:"All"
            
            }, {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then((res) => {
                //console.log("sociomate=======", res.data.data.successResult);
                dispatch(createConnectionList(res.data.data.successResult.data.rows))
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };
};