import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { Check, Search } from "react-feather";
import pro_mutual from "../../../NewComponents/IMG/pro_mutual.png";
import { Button } from "react-bootstrap";
import {Card,CardBody,Col,Input,InputGroup,Modal,ModalBody,Row} from "reactstrap";
import { useEffect } from "react";
import { loadAllUserSciomate } from "../../../Services/Actions/Connection/getSociomateAction";
import { useDispatch, useSelector } from "react-redux";

const AddSocioMateModal = (props) => {
  const dispatch = useDispatch();

  const { getUserSociomate } = useSelector((state) => state.userSociomateData);
  const [searchItem, setSearchItem] = useState("");
  const filteredData = getUserSociomate?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkedAll, setCheckedAll] = useState(true);
  const [checked, setChecked] = useState(false);

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  return (
    <>
      {props.openModal !== false && (
        <Modal isOpen={props.openModal} className="modal-dialog-centered">
          <ModalBody className="Modal_Body_Section">
            <Row
              toggle={props.addUserModal}
              className="border-bottom d-flex align-items-center justify-content-between"
            >
              <Col xl="6">
                <h3>Add SocioMate</h3>
              </Col>
              <Col xl="6" className="d-flex justify-content-end mb-2">
                <Button variant="outline-primary" onClick={props.addUserModal}>
                  Save
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={props.addUserModal}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
              <Search color="#666666" size={16} />
              <Input
                type="search"
                placeholder=" Search...1"
                className="coonection-input-style"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </InputGroup>
            <div className="d-flex align-items-center">
              <Checkbox
                className="Check_Input_section"
                onChange={(event) => selectAll(event.target.checked)}
                checked={checkedAll}
              />
              <h5 className="ml-2">Select All</h5>
            </div>
            <div className="modal-scroll">
              {filteredData.map((data, index) => (
                <Card className="m-2 shadow-sm">
                  <CardBody className="d-flex justify-content-between align-items-center">
                    <div key={index} className="d-flex align-items-center">
                      <div className="mr-3">
                        <Checkbox
                          {...label}
                          name={index}
                          onChange={() => toggleCheck(index)}
                          checked={checked[index]}
                          className="Check_Input_section"
                        />
                      </div>
                      <div className="d-flex">
                        <div>
                          <img
                            src={data.profileImage}
                            className="img-fluid rounded-circle rem-mob-view-small"
                            height="40px"
                            width="40px"
                            alt=""
                          />
                        </div>
                        <div className="ml-2 text-wrap">
                          <h4 className="text-wrap">{data.fullName}</h4>
                          <p className="text-wrap">{data.userName}</p>
                        </div>
                      </div>
                    </div>
                    {/* <Button variant='outline-primary' size="small" className='rem-mob-view' >
                                            <Check size={15} />&nbsp;Following
                                        </Button> */}
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default AddSocioMateModal;
