import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "react-bootstrap";
import { getActionUserReported } from "../../../Services/Actions/Connection/getUserReportAction";
import { getActionUserReportedOption } from "../../../Services/Actions/Connection/getReportOptionAction";
import { useDispatch, useSelector } from "react-redux";

const ConnectionReportModal = ({request, modalOpen, reportModalOpen}) => {
    
  const [selectedValue, setSelectedValue] = React.useState("1");

  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [remark, setRemark] = useState("");
  const { getUserReportOption } = useSelector(
    (state) => state.userReportOptionData
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const reportSubmit = () =>{
    
    dispatch(getActionUserReported(request, selectedOption, remark))
    reportModalOpen();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
   

  useEffect(() => {
    dispatch(getActionUserReportedOption());
  },[dispatch]);

 
  //  ------------- Start Reported Option  -------------

  return (
    <>
      {modalOpen !== false && (
        <Modal
          isOpen={modalOpen}
          toggle={reportModalOpen}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={reportModalOpen}>
            <h2>Report</h2>
          </ModalHeader>
          <ModalBody className="Modal_Body_Section">
            <div>
              {/* <h2>Please select a problem</h2> */}
              <p className="pb-1">
                Choose the option that best describes your connection status to
                report it
              </p>
            </div>
            {getUserReportOption?.map((data) => (
              <div
                key={data.id}
                className="d-flex justify-content-between mt-4 Report_Section"
              >
                <div>
                  <h4>{data.reportText}</h4>
                </div><br/><br/>
                <div>
                  <Input name="radio1" type="radio"  onClick={()=>{setSelectedOption(data.id)}} />
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mt-3">
              <Input
                id="ReportName"
                name="ReportName"
                placeholder="Write a comment..."
                type="text"
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button
              variant="primary"
              className="w-25"
              outlined
              onClick={()=>{reportSubmit(selectedOption, remark)}}
            >
              Submit
            </Button>
            <Button
              variant="outline-secondary"
              className="w-25"
              onClick={reportModalOpen}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ConnectionReportModal;
