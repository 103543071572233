import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Input } from "reactstrap";
import LeftquotationIcon from "../../../NewComponents/IMG/LeftquotationIcon.svg";
import RightquotationIcon from "../../../NewComponents/IMG/RightquotationIcon.svg";
import { ChevronLeft } from "react-feather";
import { TextColor } from "../DummyArray";
import ColorPicker from "../Img/colorpicker.png";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { useDispatch, useSelector } from "react-redux";

const Throught = ({
  selectColorTexts,
  selectBckColor,
  shows,
  setShows,
  handleChanges,
  handleChangeBack,
  showBackGround,
  setShowBackGround,
  textData,
  setTextData,
  postType,
}) => {
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfileByUserId());
    if (postType && postType?.caption) {
      setTextData(postType?.caption);
    }
  }, [dispatch, postType, textData]);


  //checking both color not same
  useEffect(() => {
    if (selectColorTexts === selectBckColor) {
      setErrorMessage("Text and background colors can't be the same");
    } else {
      setErrorMessage("");
    }
  }, [selectColorTexts, selectBckColor]);


  const handleTextColorChange = (color) => {
    if (color !== selectBckColor) {
      handleChanges(color);
    } else {
      setErrorMessage("Text and background colors can't be the same");
    }
  };


  const handleBackgroundColorChange = (color) => {
    if (color !== selectColorTexts) {
      handleChangeBack(color);
    } else {
      setErrorMessage("Text and background colors can't be the same");
    }
  };




  // <<<<<<<<<============================================================================>>>>>>>>>>>>>>>>>
  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
            alt="profileImage"
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            {userProfileByUserId.fullName}
          </h5>
        </div>
        <div style={{ position: 'relative' }}>
          <h3 style={{ color: "#FF007A" }} className="py-2">
            #thoughtOfTheDay
          </h3>
          <div>
            <img src={LeftquotationIcon} alt="icon" />
            <div className="mx-4 text-center">
              <Input
                type="textarea"
                className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                placeholder="Express your thoughts..."
                cols="15"
                rows="5"
                value={textData}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setTextData(e.target.value);
                  }
                }}
                style={{
                  backgroundColor: selectBckColor,
                  color: selectColorTexts,
                  fontSize: "24px",
                }}
              />
              <p style={{textAlign: "right",marginTop: "10px", color: "#FF007A",  }}>Max 200 characters  </p>
               {errorMessage && (
                <p style={{color: "#f24147", fontSize: "12px", textAlign: "center", marginTop: "5px",}}>{errorMessage}</p>
              )}
            </div>
            <div className="text-right">
              <img src={RightquotationIcon} alt="icon" />
            </div>
          </div>
          <h3>Text Color</h3>
          {!shows ? (
            <>
              <div
                onClick={() => setShows(!shows)}
                className="mt-1"
                style={{ cursor: "pointer" }}
              >
                <img src={ColorPicker} width={30} height={30} />
              </div>
            </>
          ) : (
            <div className="d-flex gap-3 align-items-center mt-2">
              <div
                onClick={() => setShows(!shows)}
                className="ColoPicker_actual_state"
              >
                <ChevronLeft size={30} />
              </div>
              {TextColor.map((color) => (
                <div
                  key={color.text}
                  className={
                    selectColorTexts === color.text
                      ? "Text_onChange_active_blk"
                      : "Text_onChange_Unactive_blk"
                  }
                  active={selectColorTexts === color.text}
                >
                  <div
                    className="Text_onChange_section"
                    style={{ background: color.text }}
                    onClick={() => handleTextColorChange(color.text)}
                  ></div>
                </div>
              ))}
            </div>
          )}
          <h3 className="mt-3">Background Color</h3>
          {!showBackGround ? (
            <>
              <div
                onClick={() => setShowBackGround(!showBackGround)}
                className="mt-1"
                style={{ cursor: "pointer" }}
              >
                <img src={ColorPicker} width={30} height={30} />
              </div>
            </>
          ) : (
            <div className="d-flex gap-3 align-items-center mt-2">
              <div
                onClick={() => setShowBackGround(!showBackGround)}
                className="ColoPicker_actual_state"
              >
                <ChevronLeft size={30} />
              </div>
              {TextColor.map((color) => (
                <div
                  key={color.text}
                  className={
                    selectBckColor === color.text
                      ? "Text_onChange_active_blk"
                      : "Text_onChange_Unactive_blk"
                  }
                  active={selectBckColor === color.text}
                >
                  <div
                    className="Text_onChange_section"
                    style={{ background: color.text }}
                    onClick={() => handleBackgroundColorChange(color.text)}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </CardBody>
    </Fragment>
  );
};

export default Throught;
