import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ConnectionPlaceMenu from './ConnectionPlaceMenu/ConnectionPlaceMenu';
import Followers from './Followers';
import FollowingScreen from './FollowingScreen';
import PendingRequest from './PendingRequest';
import SociomateScreen from './SociomateScreen';
import CommanHeader from './CommanHeader';
import './Style.css'

const Connection = () => {
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Card>
                        {/* comman header calling */}
                        <CommanHeader />
                        <CardBody>
                            <ConnectionPlaceMenu />
                            <PendingRequest />
                            <SociomateScreen />
                            <FollowingScreen />
                            <Followers />
                        </CardBody>
                    </Card>
                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default Connection