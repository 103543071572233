import React from 'react'
import { Fragment } from 'react'

export const AvatarGroupSm = () => {

    const avatardata = [
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },

        {
            img: 'assets/images/story-4.jpg'
        },
    ]

    return (
        <Fragment>
            <ul className="matual-friend-blk">
                {
                    avatardata.map((item, index) => {
                        return (
                            <li key={index} className="popover-cls d-flex" data-bs-toggle="popover" data-placement="right"
                                data-name="sufiya elizaaaaaaa" data-img={item.img}>
                                <img src={item.img} className="img-fluid bg-img" style={{ border: '2px solid #FFFFFF' }} alt="..." />
                            </li>
                        )
                    })
                }
            </ul>
        </Fragment>
    )
}
